import React from "react";
interface ButtonProps {
  text: string;
  bgColor: string;
  hoverBgColor: string;
  activeBgColor: string;
  textColor: string;
  onClick?: () => void;
  type?: "submit" | "button";
  className?: string;
}

export const renderButton = ({
  text,
  bgColor,
  hoverBgColor,
  activeBgColor,
  textColor,
  onClick,
  className,
  type,
}: ButtonProps) => (
  <button
    className={`transition-all rounded-lg px-3 lg:px-5 py-2 lg:py-3 text-sm lg:text-base ${bgColor} ${hoverBgColor} ${activeBgColor} ${textColor} transition-colors font-semibold ${className}`}
    onClick={onClick}
    type={type}
  >
    {text}
  </button>
);
