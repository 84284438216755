import React, { useEffect } from "react";
import { ReactComponent as IcUser } from "../../asset/icon/ic_user.svg";
import { ReactComponent as IcPassword } from "../../asset/icon/ic_password.svg";
import { useForm } from "react-hook-form";
import BasicButton from "../../component/basicButton";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../component/input";
import logo from "../../asset/logo.png";
import { useMutation } from "@tanstack/react-query";
import { CommonDataApi } from "../../api/CommonDataApi";
import { IJoinData } from "./join";
import { LoginApi } from "../../api/LoginApi";
import { ROLE } from "../../libs/constants";
export interface ILoginData {
  userId: string;
  password: string;
}

export interface ILoginRes {
  auth: string;
  projectId: number;
}
const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<ILoginData>();

  const mutation = useMutation<string | ILoginRes, string, ILoginData>({
    mutationFn: (param: ILoginData) => LoginApi.login(param),
    onSuccess: (data: any) => {
      if (data && data !== "error") {
        if (data.auth === ROLE.USER) {
          if (data.projectId > 0) {
            navigate("/home");
          } else {
            navigate("/participation");
          }
        } else {
          if (data.projectId > 0) {
            navigate("/admin/home");
          } else {
            navigate("/admin/project");
          }
        }
        // console.log("data", data);
      } else {
        setError("userId", {
          message: "아이디 또는 비밀번호가 일치하지 않습니다.",
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const watchId = watch("userId");
  const watchPassword = watch("password");
  const isButtonDisabled = !watchId || !watchPassword;

  const onValid = (data: ILoginData) => {
    mutation.mutate(data);

    // if (data.userId === "bluesignal" && data.password === "12345") {
    //   navigate("/home");
    // }
    // if (data.userId === "admin" && data.password === "12345") {
    //   navigate("/admin/home");
    // }
    // setError("userId", {
    //   message: "아이디 또는 비밀번호를 잘못 입력했습니다.",
    // });
  };
  return (
    <div className="w-full max-w-xl mx-auto">
      <form onSubmit={handleSubmit(onValid)} className="w-full mt-16 px-8">
        <div className="flex flex-col justify-center items-center space-y-1">
          {/*<h1 className="text-3xl font-extrabold">Shared Safety</h1>*/}
          <Link
            to={"https://bluesignal.ai/"}
            className="text-3xl font-extrabold"
          >
            Bluesignal AI
          </Link>
          {/*<div className="flex items-center justify-center mr-6 text-gray-500">*/}
          {/*  <img src={logo} alt="logo" className="aspect-video h-8" />*/}
          {/*  <h1 className="text-xl font-semibold text-gray-700">Bluesignal</h1>*/}
          {/*</div>*/}
          <h3 className="text-7xl font-extrabold ml-3 text-blue-600">UNIS.</h3>
          <h3 className="ml-3 text-black">실시간 위험 정보 공유 플랫폼</h3>
        </div>

        <div className="w-full h-32 mt-16">
          <Input
            required={true}
            type={"text"}
            icon={<IcUser />}
            placeholder={"아이디"}
            className="h-1/2 rounded-t-xl"
            register={register("userId", { required: true })}
          />
          <Input
            required={true}
            type={"password"}
            icon={<IcPassword />}
            placeholder={"비밀번호"}
            className="h-1/2 rounded-b-xl border-t-0"
            register={register("password", { required: true })}
          />
        </div>
        {errors?.userId?.message && (
          <p className="text-rose-500 text-center mt-5">
            {errors.userId.message}
          </p>
        )}
        <BasicButton
          text={"로그인"}
          disabled={isButtonDisabled}
          size={"lg"}
          type={"submit"}
        />
        <div className="grid grid-cols-3 divide-x divide-gray-300 mt-5 text-gray-400 w-11/12 mx-auto *:text-center">
          <div>
            <Link to={"/"}>아이디 찾기</Link>
          </div>
          <div className="text-blue-500 font-semibold">
            <Link to={"/join"}>회원가입</Link>
          </div>
          <div>
            <Link to={"/"}>비밀번호 찾기</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
