import React, { useEffect, useState } from "react";
import Layout from "../../component/admin/layout";
import Tag from "../../component/tag";
import TaskBox from "../../component/taskBox";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IcDnager } from "../../asset/icon/ic_danger.svg";
import { ReactComponent as IcInspectation } from "../../asset/icon/ic_inspection.svg";
import { ReactComponent as IcPatrol } from "../asset/icon/ic_patrol.svg";
import Calendar from "../../utils/Calendar";
import { calendarFns } from "../../utils/calendarFns";
import Weather from "../../component/weather/weather";
import { useQuery } from "@tanstack/react-query";
import { AdminDataApi } from "../../api/AdminDataApi";
import { getCookie } from "../../libs/cookies";
import { ITaskData } from "./task/register";
import {
  PROJECT_STATE,
  REPORT_STATE,
  REPORT_TYPE,
  USER_STATUS,
} from "../../libs/constants";
import { format } from "date-fns";
import { safetyTypeIcon } from "../../libs/safetyTypeIcon";
import {
  taskBoxBg,
  taskIconColor,
  taskStateBoxBg,
  taskStateColor,
} from "../../libs/utils";
import { IReportData } from "../user/report/report";

interface IProjectInfo {
  id: number;
  name: string;
  address: string;
  startDt: string;
  endDt: string;
  state: string;
  remainingPeriod: string;
}

interface IUserInfo {
  userId: number;
  userName: string;
  userPhone: string;
  state: string;
}

const Home = () => {
  const navigate = useNavigate();
  const projectId = getCookie("projectId");
  const [projects, setProjects] = useState<IProjectInfo>();
  const [users, setUsers] = useState<IUserInfo[]>();
  const [tasks, setTasks] = useState<ITaskData[]>();
  const [pendingReports, setPendingReports] = useState<IReportData[]>();
  const [refusalReports, setRefusalReports] = useState<IReportData[]>();
  const [pendingCnt, setPendingCnt] = useState(0);
  const [refusalCnt, setRefusalCnt] = useState(0);

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ["getDashboard"],
    queryFn: () => AdminDataApi.getDashboard(projectId || ""),
  });
  useEffect(() => {
    if (isSuccess && data && data.code === "OK") {
      console.log(data.data);
      setProjects(data.data.project);
      setUsers(data.data.users);
      setUsers((prev) =>
        prev?.map((user) => ({
          ...user,
          state: USER_STATUS.ATTENDANCE,
        })),
      );
      setTasks(data?.data?.tasks);
      setPendingReports(data?.data?.reports?.pending);
      setRefusalReports(data?.data?.reports?.refusal);
      setPendingCnt(data?.data?.reports?.pending.length);
      setRefusalCnt(data?.data?.reports?.refusal.length);
    }
  }, [data]);
  useEffect(() => {
    console.log("users", users);
  }, [users]);

  const handleClickInnerBox = (
    event: React.MouseEvent,
    id: number,
    type: string,
    reportId?: number,
    reportType?: string,
  ) => {
    event.stopPropagation();
    if (type === "report") {
      navigate(`/admin/${type}/detail`, {
        state: {
          taskId: id,
          reportId: reportId,
          reportType: reportType,
          improved: true,
        },
      });
    } else {
      navigate(`/admin/${type}/${id}`);
    }
  };
  const today = new Date();

  const handleClickTask = (id: number) => {
    navigate("/task", {
      state: {
        id,
      },
    });
  };
  const handleClickBox = (url: string) => {
    navigate(url);
  };
  return (
    <Layout
      canGoBack={true}
      hasTopBar={true}
      hasTabBar={true}
      title={"관리자 홈"}
    >
      <div className="md:grid grid-cols-2 mt-4 lg:mt-12 md:gap-4">
        <div
          onClick={(event) =>
            handleClickInnerBox(event, projects?.id || 0, "project")
          }
          className="adminBox_clickable"
        >
          <div className="text-xl md:text-2xl font-semibold col-span-2 lg:border-b pb-2 flex justify-between items-center">
            {projects?.name || "로딩중"}
            <div className="text-base flex item-center h-8">
              {projects?.state === PROJECT_STATE.PRESTART && (
                <Tag color={"yellow"} title={"시작전"} />
              )}
              {projects?.state === PROJECT_STATE.PROCESSING && (
                <Tag color={"green"} title={"진행중"} />
              )}
              {projects?.state === PROJECT_STATE.STOP && (
                <Tag color={"red"} title={"중단"} />
              )}
              {projects?.state === PROJECT_STATE.COMPLETED && (
                <Tag color={"gray"} title={"완료"} />
              )}
            </div>
          </div>
          <div className="md:hidden flex justify-between text-xs">
            <span>{projects?.address}</span>
            <span>
              {format(projects?.startDt || new Date(), "yy. MM. dd.")}~
              {format(projects?.endDt || new Date(), "yy. MM. dd.")}
            </span>
          </div>

          <div className="hidden lg:flex  items-center">
            {projects?.address}
          </div>
          <div className="hidden lg:flex items-center justify-end text-xs">
            {format(projects?.startDt || new Date(), "yy. MM. dd.")} ~
            {format(projects?.endDt || new Date(), "yy. MM. dd.")} (
            {projects?.remainingPeriod}일 남음)
          </div>
        </div>
        <div
          onClick={() => handleClickBox("/admin/member")}
          className="adminBox_clickable"
        >
          <div className="adminBox_title">안전 감시단 현황</div>
          <div className="flex items-center overflow-x-auto overflow-y-hidden space-x-9 col-span-2 py-2">
            <>
              {users?.map((user, index) => {
                return (
                  <div
                    key={`user_${index}`}
                    className="text-center min-w-fit hover:scale-105 transition-transform"
                    // onClick={(event) => handleClickInnerBox(event, 1, "member")}
                  >
                    <p>{user.userName}</p>
                    <div className="text-xs mt-1">
                      {user?.state === USER_STATUS.ATTENDANCE && (
                        <Tag color={"green"} title={"출근"} />
                      )}
                      {user?.state === USER_STATUS.LATE && (
                        <Tag color={"yellow"} title={"지각"} />
                      )}
                      {user?.state === USER_STATUS.LEAVE && (
                        <Tag color={"red"} title={"연차"} />
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          </div>
        </div>
        <div
          onClick={() => handleClickBox("/admin/task")}
          className="adminBox_clickable h-52"
        >
          <div className="adminBox_title !border-b-0 ">
            {calendarFns.formatToday(today)} 업무
          </div>
          <div className="col-span-2 space-y-2 lg:space-y-3 overflow-y-auto w-full top-10 h-32">
            {tasks?.map((task, index) => (
              <TaskBox
                key={`task_${index}`}
                icon={safetyTypeIcon(task.safetyType)}
                onClick={() => handleClickTask(task.taskId)}
                type={"task"}
                content={{
                  title: task.taskName || "",
                  writer: task.coworkerName,
                  location: task.location,
                  startTime: task.startTime.slice(0, 5),
                  endTime: task.endTime.slice(0, 5),
                }}
                boxClassName={taskStateBoxBg(task.state)}
                iconClassName={`text-${taskStateColor(task.state)}-500`}
              />
            ))}
          </div>
        </div>
        <div className="adminBox h-52 flex-col">
          <div className="adminBox_title !border-b-0">날씨</div>
          <Weather mini={true} />
        </div>
        <div
          onClick={() => handleClickBox("/admin/report")}
          className="adminBox_clickable space-y-3 md:space-y-0 md:col-span-2"
        >
          <div className="adminBox_title !border-b-0">보고서 현황</div>
          <div className="rounded-lg bg-white mr-3">
            <div className="text-center font-semibold py-2 text-lg">
              승인 대기 ({pendingCnt}건)
            </div>
            <div className="py-3 h-52 overflow-y-auto space-y-2">
              {pendingReports && pendingReports.length > 0 ? (
                pendingReports?.map((report, index) => (
                  <TaskBox
                    onClick={(event) =>
                      handleClickInnerBox(
                        event,
                        report?.taskId || 0,
                        "report",
                        report?.reportId,
                        report?.reportType,
                      )
                    }
                    type={"report"}
                    content={{
                      title: report.name || "로딩중",
                      location: report.location || "로딩중",
                      writer: report.authorName || "로딩중",
                      regDate:
                        format(
                          report.createdAt || new Date(),
                          "yyyy년 MM월 dd일",
                        ) || "로딩중",
                    }}
                    boxClassName={
                      taskStateBoxBg(report.state || REPORT_STATE.PENDING) || ""
                    }
                  />
                ))
              ) : (
                <p className="text-center">제출된 보고서가 없습니다</p>
              )}

              {/*<TaskBox*/}
              {/*  // onClick={(event) => handleClickInnerBox(event, 1, "report")}*/}
              {/*  type={"report"}*/}
              {/*  content={{*/}
              {/*    title: "갱폼설치 안전망설치",*/}
              {/*    location: "B21구역 14F",*/}
              {/*    writer: "양지윤",*/}
              {/*    regDate: "2024년 01월 29일",*/}
              {/*  }}*/}
              {/*  boxClassName={*/}
              {/*    "bg-red-200/35 hover:bg-red-200/25 active:bg-red-200/30"*/}
              {/*  }*/}
              {/*/>*/}
              {/*<TaskBox*/}
              {/*  // onClick={(event) => handleClickInnerBox(event, 1, "report")}*/}
              {/*  type={"report"}*/}
              {/*  content={{*/}
              {/*    title: "갱폼설치 안전망설치",*/}
              {/*    location: "B21구역 14F",*/}
              {/*    writer: "양지윤",*/}
              {/*    regDate: "2024년 01월 29일",*/}
              {/*  }}*/}
              {/*  boxClassName={*/}
              {/*    "bg-yellow-200/35 hover:bg-yellow-200/25 active:bg-yellow-200/30"*/}
              {/*  }*/}
              {/*/>*/}
              {/*<TaskBox*/}
              {/*  // onClick={(event) => handleClickInnerBox(event, 1, "report")}*/}
              {/*  type={"report"}*/}
              {/*  content={{*/}
              {/*    title: "갱폼설치 안전망설치",*/}
              {/*    location: "B21구역 14F",*/}
              {/*    writer: "양지윤",*/}
              {/*    regDate: "2024년 01월 29일",*/}
              {/*  }}*/}
              {/*  boxClassName={*/}
              {/*    "bg-green-200/35 hover:bg-green-200/25 active:bg-green-200/30"*/}
              {/*  }*/}
              {/*/>*/}
            </div>
          </div>
          <div className="rounded-lg bg-white">
            <div className="text-center font-semibold py-2 text-lg">
              반려 ({refusalCnt}건)
            </div>
            <div className="py-3 h-52 overflow-y-auto space-y-2">
              {refusalReports && refusalReports?.length > 0 ? (
                refusalReports?.map((report, index) => (
                  <TaskBox
                    onClick={(event) =>
                      handleClickInnerBox(
                        event,
                        report?.taskId || 0,
                        "report",
                        report?.reportId,
                        report?.reportType,
                      )
                    }
                    type={"report"}
                    content={{
                      title: report.name || "로딩중",
                      location: report.location || "로딩중",
                      writer: report.authorName || "로딩중",
                      regDate:
                        format(
                          report.createdAt || new Date(),
                          "yyyy년 MM월 dd일",
                        ) || "로딩중",
                    }}
                    boxClassName={
                      taskStateBoxBg(report.state || REPORT_STATE.PENDING) || ""
                    }
                  />
                ))
              ) : (
                <p className="text-center">제출된 보고서가 없습니다</p>
              )}
              {/*<TaskBox*/}
              {/*  // onClick={(event) => handleClickInnerBox(event, 1, "report")}*/}
              {/*  type={"report"}*/}
              {/*  content={{*/}
              {/*    title: "갱폼설치 안전망설치",*/}
              {/*    location: "B21구역 14F",*/}
              {/*    writer: "정은호",*/}
              {/*    regDate: "2024년 01월 30일",*/}
              {/*  }}*/}
              {/*  boxClassName={*/}
              {/*    "bg-yellow-200/35 hover:bg-yellow-200/25 active:bg-yellow-200/30"*/}
              {/*  }*/}
              {/*/>*/}
              {/*<TaskBox*/}
              {/*  // onClick={(event) => handleClickInnerBox(event, 1, "report")}*/}
              {/*  type={"report"}*/}
              {/*  content={{*/}
              {/*    title: "갱폼설치 안전망설치",*/}
              {/*    location: "B21구역 14F",*/}
              {/*    writer: "정은호",*/}
              {/*    regDate: "2024년 01월 30일",*/}
              {/*  }}*/}
              {/*  boxClassName={*/}
              {/*    "bg-green-200/35 hover:bg-green-200/25 active:bg-green-200/30"*/}
              {/*  }*/}
              {/*/>*/}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
