import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import axios from "axios";
import { CookiesProvider } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { getCookie, removeCookie, setCookie } from "./libs/cookies";
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use((config: any) => {
  const excludeUrls = ["/user/login", "/user/signup", "/user/duplicate/id"];

  const isExcluded = excludeUrls.some((url) => config.url === url);

  if (!isExcluded) {
    const accessToken = getCookie("accessToken");
    const refreshToken = getCookie("refreshToken");

    if (!accessToken || !refreshToken) {
      const navigate = useNavigate();
      navigate("/", { replace: true });
      return Promise.reject("No tokens available");
    }
    config.headers = config.headers || {};
    config.headers.Authorization = `${accessToken}`;
    config.headers.RefreshToken = `${refreshToken}`;
  }

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const excludeUrls = ["/user/login", "/user/signup", "/user/duplicate/id"];

    const isExcluded = excludeUrls.some((url) => error.config.url === url);
    if (!isExcluded) {
      if (error.response?.status === 401) {
        // 401 Unauthorized 처리 로직
      } else if (error.response?.status === 403) {
        // 403 Forbidden 처리 로직
        const navigate = useNavigate();
        removeCookie("accessToken");
        removeCookie("refreshToken");
        navigate("/", { replace: true });
      }
    }
    return Promise.reject(error);
  },
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={store}>
    <CookiesProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </CookiesProvider>
  </Provider>,
);

reportWebVitals();
