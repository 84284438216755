import React, { useEffect, useState } from "react";

import Map from "./component/Map";
import MapMarker from "./component/MapMarker";

const MainMap = () => {
  const kakao = (window as any).kakao;

  const [map, setMap] = useState<kakao.maps.Map | null>(null);

  return (
    <Map className="w-full h-full" maxLevel={5} onCreate={setMap}>
      <MapMarker position={{ lat: 37.350126, lng: 126.73112 }} />
    </Map>
  );
};

export default MainMap;
