import React, { useContext, useLayoutEffect, useMemo } from "react";
import MapContext from "./MapContext";
import useEvent from "../hook/useEvent";
import { MapMarkerProps } from "../../../interface/map";

const MapMarker = ({
  position,
  onMouseOver,
  onClick,
  children,
}: React.PropsWithChildren<MapMarkerProps>) => {
  const map = useContext(MapContext);
  const kakao = (window as any).kakao;
  const markerPosition = useMemo(() => {
    return new kakao.maps.LatLng(position.lat, position.lng);
  }, [position]);

  const marker = useMemo(() => {
    const CustomMarker = new kakao.maps.Marker({
      position: markerPosition,
    });
    return CustomMarker;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (!map) return;
    marker.setMap(map);
    return () => {
      marker.setMap(null);
    };
  }, [map, marker]);
  return marker.setMap();
};

export default MapMarker;
