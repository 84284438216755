import React from "react";
import Layout from "../../../component/admin/layout";
import MainMap from "../../../component/map/MainMap";
import Map from "../../../component/map/component/Map";
import { useNavigate } from "react-router-dom";

const Detail = () => {
  const navigate = useNavigate();
  const handleClickVideo = (event: React.MouseEvent, id: number) => {
    event.stopPropagation();
    navigate(`/admin/uniscam/video/${id}`);
  };
  return (
    <Layout
      title={"유니슨 캠"}
      hasTopBar={true}
      hasTabBar={true}
      canGoBack={true}
    >
      <div className="space-y-3">
        <div className="adminBox flex-col">
          <iframe
            className="min-w-[300px] w-full aspect-video rounded"
            src="https://www.youtube.com/embed/BOAOs2vDZBI"
            title="사고사례 갱폼 인양 와이어파단"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>

          <p className="text-center font-semibold text-lg lg:text-xl pt-3 pb-5">
            2024.02.07. 양지윤 실시간 스트리밍
          </p>
          <div className="bg-lightGray p-3 rounded-lg text-sm lg:text-lg">
            <p>
              <span className="font-semibold">2024.02.07.09:02 </span> 3시간 전
              시작
            </p>
            <p>인천광역시 남동구 정각로 29 인천검단</p>
            <div className="w-full h-80 bg-green-500 mt-3">
              <MainMap />
            </div>
          </div>
        </div>
        <div
          className="adminBox flex-col"
          onClick={() => navigate("/admin/uniscam/list/양지윤")}
        >
          <div className="adminBox_title !justify-start cursor-pointer">
            양지윤 유니슨캠 영상
          </div>
          <div className="flex w-full overflow-x-auto">
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Detail;
