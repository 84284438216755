import React from "react";
import Layout from "../../component/layout";
import Input from "../../component/input";
import BasicButton from "../../component/basicButton";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { LoginApi } from "../../api/LoginApi";
import { ROLE } from "../../libs/constants";
import { ILoginData, ILoginRes } from "./Login";
import { UserDataApi } from "../../api/UserDataApi";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../libs/cookies";

export interface IProjectCode {
  code: string;
}

const ProjectCode = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<IProjectCode>();
  const watchCode = watch("code");
  const navigate = useNavigate();
  const isButtonDisabled = !watchCode;
  const checkParticipation = useMutation<string, string, string>({
    mutationFn: (code: string) => UserDataApi.participateProject(code),
    onSuccess: (data: any) => {
      if (data.code === "OK") {
        setCookie("projectId", data.data.projectId);
        navigate("/home", { replace: true });
      } else {
        setError("code", { message: "참여 코드가 일치하지 않습니다" });
      }
    },
    onError: (error) => {
      console.log(error);
      setError("code", { message: "참여 코드가 일치하지 않습니다" });
    },
  });
  const onValid = (data: IProjectCode) => {
    console.log(data);
    checkParticipation.mutate(data.code);
  };
  return (
    <Layout hasTabBar={true} hasTopBar={true} title={"프로젝트 참여 코드 입력"}>
      <div className="mx-3 mt-7 h-[500px] bg-white">
        <form
          className="h-full flex flex-col justify-center items-center"
          onSubmit={handleSubmit(onValid)}
        >
          <p className="py-5 font-semibold">
            발급받으신 프로젝트 참여 코드를 입력해주세요
          </p>
          <Input
            type={"text"}
            placeholder={"프로젝트 참여 코드"}
            required={true}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            }
            className="h-16 rounded-lg"
            register={register("code", { required: true })}
          />
          {errors.code?.message && (
            <p className="text-rose-500 text-center mt-5">
              {errors.code.message}
            </p>
          )}
          <BasicButton
            text={"인증"}
            type={"submit"}
            disabled={isButtonDisabled}
            size={"lg"}
          />
        </form>
      </div>
    </Layout>
  );
};

export default ProjectCode;
