import axios from "axios";
import { ILoginData } from "../page/user/Login";
import { setCookie } from "../libs/cookies";
import { jwtDecode } from "jwt-decode";

interface CustomJwtPayload {
  auth: string;
  exp: number;
  id: number;
  sub: string;
}

export const LoginApi = {
  login: async (params: ILoginData) => {
    const { userId, password } = params;
    try {
      const response = await axios.post(
        `/user/login`,
        {
          userId,
          password,
        },
        {
          headers: { "Content-Type": "application/json" },
        },
      );
      console.log("로그인 성공", response.data);
      const { refreshToken, accessToken } = response.data.data.token;
      const { projectId } = response.data.data;

      if (!accessToken || !refreshToken) throw new Error("Token missing");

      const accessToken_decoded = jwtDecode<CustomJwtPayload>(accessToken);
      const refreshToken_decoded = jwtDecode<CustomJwtPayload>(refreshToken);

      const accessToken_expires = new Date(accessToken_decoded.exp * 1000);
      const refreshToken_expires = new Date(refreshToken_decoded.exp * 1000);

      setCookie("accessToken", accessToken, {
        path: "/",
        secure: false,
        expire: accessToken_expires,
      });
      setCookie("refreshToken", refreshToken, {
        path: "/",
        secure: false,
        expire: refreshToken_decoded,
      });
      setCookie("auth", accessToken_decoded.auth, {
        path: "/",
        secure: false,
        expire: refreshToken_expires,
      });

      setCookie("projectId", projectId, {
        path: "/",
        secure: false,
      });

      return { auth: accessToken_decoded.auth, projectId };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("로그인 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
};
