import React from "react";
import Tag from "../../../component/tag";
import { useNavigate } from "react-router-dom";
import { cls } from "../../../libs/utils";
import Layout from "../../../component/admin/layout";
import Input from "../../../component/input";
import { ReactComponent as IcFinder } from "../../../asset/icon/ic_finder.svg";

const List = () => {
  const dummy = [
    { name: "양지윤", status: "출근" },
    { name: "정은호", status: "지각" },
    { name: "방찬우", status: "출근" },
    { name: "장성식", status: "출근" },
    { name: "신상진", status: "출근" },
    { name: "김동훈", status: "연차" },
  ];
  const navigate = useNavigate();
  return (
    <Layout
      canGoBack={true}
      title={"감시단 관리"}
      hasTabBar={true}
      hasTopBar={true}
    >
      <div className="my-4 flex justify-between">
        <div className="lg:w-1/3 w-full">
          <Input
            type="text"
            placeholder="감시단 이름, 감시단 소속"
            icon={
              <span className="text-gray-300">
                <IcFinder />
              </span>
            }
            className="rounded-xl mx-auto border-0 h-12 bg-lightGray"
          />
        </div>
      </div>
      <div className="md:space-y-3 space-y-2 text-center py-2 lg:text-base grid lg:grid-cols-4 lg:gap-2 grid-cols-1 gap-3 *:h-fit">
        {[
          { name: "양지윤", status: "출근" },
          { name: "정은호", status: "지각" },
          { name: "방찬우", status: "출근" },
          { name: "장성식", status: "출근" },
          { name: "신상진", status: "출근" },
          { name: "김동훈", status: "연차" },
        ].map((member, index) => (
          <div
            key={index}
            className="flex flex-col items-center w-full bg-white shadow py-4 px-6 rounded-lg hover:shadow-xl cursor-pointer transition-all mt-3 space-y-3"
          >
            <div className="rounded-full w-32 h-32 bg-slate-200" />
            <div className="w-full flex flex-col justify-center items-center">
              <p className="font-bold text-lg tracking-wider">{member.name}</p>
              <span className="font-semibold">인천 검단</span>
              <span>010-0000-0000</span>
              <div className="flex space-x-1 text-xs">
                {member.status === "출근" ? (
                  <Tag color={"green"} title={"출근"} />
                ) : member.status === "지각" ? (
                  <Tag color={"yellow"} title={"지각"} />
                ) : (
                  <Tag color={"red"} title={"연차"} />
                )}
              </div>
            </div>
          </div>
        ))}

        {/*<div className="flex flex-col items-center w-full bg-white shadow py-4 px-6 rounded-lg hover:shadow-xl cursor-pointer transition-all mt-3 space-y-3">*/}
        {/*  <div className="rounded-full w-32 h-32 bg-slate-200" />*/}
        {/*  <div className="w-full flex flex-col justify-center items-center">*/}
        {/*    <p className="font-bold text-lg tracking-wider">양지윤</p>*/}
        {/*    <span className="font-semibold">인천 검단</span>*/}
        {/*    <span>010-0000-0000</span>*/}
        {/*    <div className="flex space-x-1 text-xs">*/}
        {/*      <Tag color={"green"} title={"출근"} />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </Layout>
  );
};

export default List;
