import React, { useState } from "react";
import { SavedFileInfo } from "./createFile";
import MediaModal, { IMediaModal } from "./mediaModal";
import { setMediaModalOpen } from "../store/features/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";

interface IPreview {
  info: SavedFileInfo;
  index: number;
  onClick?: () => void;
}

const Preview = ({ info, index, onClick }: IPreview) => {
  return (
    <>
      <div
        key={index}
        className="relative h-32 aspect-video flex items-center justify-center cursor-pointer"
        onClick={onClick}
      >
        {info.type.startsWith("video") ? (
          <>
            <video
              src={`${process.env.REACT_APP_IMAGE_URL}${info.path}`}
              className="h-full aspect-video rounded-lg"
              autoPlay={false}
              muted
              loop
            ></video>
            <p className="absolute bottom-0 left-0 text-xs bg-gray-600 text-white p-1 w-full text-center rounded-b-lg">
              {info.path.split("/").pop()}
            </p>
          </>
        ) : (
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}${info.path}`}
            alt="preview"
            className="h-full object-contain rounded-lg"
          />
        )}
      </div>
    </>
  );
};

export default Preview;
