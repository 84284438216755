import { RISK_INDEX, TASK_STATE } from "./constants";

export function cls(...classnames: string[]) {
  return classnames.join(" ");
}

/*
 *   < 업무 색상 >
 *   계획 : gray
 *   대기 : green
 *   반려 :  red
 *   개선중 :  lightBlue
 *   개선 완료 : blue
 *   완료 :  white
 * */

export function tagValue(status: string): {
  title: TASK_STATE;
  color:
    | "yellow"
    | "red"
    | "green"
    | "gray"
    | "blue"
    | "lightBlue"
    | "orange"
    | "white"
    | "darkBlue";
} {
  const title =
    status === "pending"
      ? "대기"
      : status === "fixing"
        ? "개선중"
        : status === "fixed"
          ? "개선완료"
          : status === "approved"
            ? "승인"
            : "반려";
  const color =
    status === "pending" || status === "fixing" || status === "fixed"
      ? "blue"
      : status === "approved"
        ? "green"
        : "red";

  return { title, color };
}
export function getTagColor(status: string) {
  let res = "";

  return res;
}

export function formatBirthDate(birth: string) {
  const year = birth.substring(0, 4);
  const month = birth.substring(4, 6);
  const day = birth.substring(6, 8);

  return `${year}-${month}-${day}`;
}

export function formatDateToISO(dateString: string) {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);

  const date = new Date(`${year}-${month}-${day}`);

  return date.toISOString();
}

export const getSafetyTypeClass = (safetyType: string) => {
  switch (safetyType) {
    case "안전순찰":
      return "green-500";
    case "고위험작업":
      return "red-500";
    default:
      return "yellow-500";
  }
};

export function taskBoxBg(safetyType: string) {
  if (safetyType === "고위험작업") {
    return "bg-red-500/20 hover:bg-red-500/25 active:bg-red-500/30";
  } else if (safetyType === "안전순찰") {
    return "bg-green-500/20 hover:bg-green-500/25 active:bg-green-500/30";
  } else if (safetyType === "장비점검") {
    return "bg-yellow-500/20 hover:bg-yellow-500/25 active:bg-yellow-500/30";
  } else {
    return null;
  }
}
export function taskIconColor(safetyType: string) {
  if (safetyType === "고위험작업") {
    return "text-red-500";
  } else if (safetyType === "안전순찰") {
    return "text-green-500";
  } else if (safetyType === "장비점검") {
    return "text-yellow-500";
  } else {
    return null;
  }
}

export function taskStateColor(state: string) {
  if (state === TASK_STATE.PLANNED) {
    return "gray";
  } else if (state === TASK_STATE.PENDING) {
    return "green";
  } else if (state === TASK_STATE.REJECTED) {
    return "red";
  } else if (state === TASK_STATE.IMPROVING) {
    return "lightBlue";
  } else if (state === TASK_STATE.IMPROVED) {
    return "blue";
  } else if (state === TASK_STATE.COMPLETED) {
    return "darkBlue";
  } else {
    return "gray";
  }
}
export function taskStateBoxBg(state: string) {
  let color = "";
  if (state === TASK_STATE.PLANNED) {
    return `bg-gray-200/60 hover:bg-gray-200/40 active:bg-gray-200/80`;
  } else if (state === TASK_STATE.PENDING) {
    return `bg-green-200/60 hover:bg-green-200/40 active:bg-green-200/80`;
  } else if (state === TASK_STATE.REJECTED) {
    return `bg-red-200/60 hover:bg-red-200/40 active:bg-red-200/80`;
  } else if (state === TASK_STATE.IMPROVING) {
    return `bg-blue-200/60 hover:bg-blue-200/40 active:bg-blue-200/80`;
  } else if (state === TASK_STATE.IMPROVED) {
    return `bg-blue-300/60 hover:bg-blue-300/40 active:bg-blue-300/80`;
  } else if (state === TASK_STATE.COMPLETED) {
    return `bg-indigo-100/60 hover:bg-indigo-100/40 active:bg-indigo-100/80`;
  } else {
    return `bg-blue-200/60 hover:bg-gray-200/40 active:bg-gray-200/80`;
  }
}

export function taskStateCalendarBg(state: string) {
  // console.log("state", state);

  if (state === TASK_STATE.PLANNED) {
    return " bg-gray-400 !border-none";
  } else if (state === TASK_STATE.PENDING) {
    return " bg-green-400 !border-none";
  } else if (state === TASK_STATE.REJECTED) {
    return " bg-red-400 !border-none";
  } else if (state === TASK_STATE.IMPROVING) {
    return "bg-blue-200 !border-none";
  } else if (state === TASK_STATE.IMPROVED) {
    return " bg-blue-400 !border-none";
  } else if (state === TASK_STATE.COMPLETED) {
    return " bg-indigo-400 !border-none";
  } else {
    return " bg-gray-400 !border-none";
  }
}

export function riskIndexColor(risk: RISK_INDEX) {
  if (risk === RISK_INDEX.URGENT) {
    return "red";
  } else if (risk === RISK_INDEX.PRIORITY) {
    return "orange";
  } else if (risk === RISK_INDEX.REVIEW) {
    return "yellow";
  }
}
