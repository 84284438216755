import React, { useEffect } from "react";
import { cls } from "../libs/utils";

import { Link, useLocation, useNavigate } from "react-router-dom";
import TabItem from "./tabItem";
import Transition from "./transition";
import { getCookie } from "../libs/cookies";

interface LayoutProps {
  title?: string;
  hasTopBar?: boolean;
  canGoBack?: boolean;
  hasTabBar?: boolean;
  children: React.ReactNode;
}

export default function Layout({
  title,
  hasTopBar = true,
  canGoBack = true,
  hasTabBar = true,
  children,
}: LayoutProps) {
  useEffect(() => {
    const accessToken = getCookie("accessToken");
    const refreshToken = getCookie("refreshToken");
    const auth = getCookie("auth");
    const projectId = getCookie("projectId");
    if (!accessToken || !refreshToken || !auth) {
      if (location !== "/join") {
        navigate("/", { replace: true });
      }
    }
    if (projectId === 0) {
      navigate("/participation", { replace: true });
    }
  }, []);

  const navigate = useNavigate();
  const location = useLocation().pathname;
  const onClick = () => {
    if (location === "/detail") {
      navigate("/reports");
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="flex flex-col w-full max-w-xl mx-auto">
      {hasTopBar ? (
        <div className="bg-white w-full h-12 max-w-xl justify-center text-lg px-10 font-medium fixed text-gray-800 border-b top-0 flex items-center z-10">
          {canGoBack ? (
            <button onClick={onClick} className="absolute left-4">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
            </button>
          ) : null}
          {title ? (
            <span className={cls(canGoBack ? "mx-auto" : "", "")}>{title}</span>
          ) : null}
        </div>
      ) : null}
      <div className={cls(hasTabBar ? "pb-24" : "", hasTopBar ? "pt-12" : "")}>
        <Transition>{children}</Transition>
      </div>
      {hasTabBar ? (
        <nav className="z-50 bg-white max-w-xl text-gray-700 border-t fixed bottom-0 w-full px-10 pb-2 pt-3 flex justify-between text-xs">
          <TabItem
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                />
              </svg>
            }
            url={"/reports"}
            title={"보고 관리"}
            location={location}
          />
          <TabItem
            icon={
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                ></path>
              </svg>
            }
            url={"/home"}
            title={"홈"}
            location={location}
          />
          <TabItem
            icon={
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                ></path>
              </svg>
            }
            url={"/account"}
            title={"계정 관리"}
            location={location}
          />
        </nav>
      ) : null}
    </div>
  );
}
