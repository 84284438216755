import axios from "axios";
import { IJoinData } from "../page/user/join";
import { IProjectData } from "../page/admin/project/register";
import { ITaskData } from "../page/admin/task/register";
import { IDuration } from "../page/admin/task/list";
import { IProjectCode } from "../page/user/Participation";
import { IReportData } from "../page/user/report/report";
import { IReportListParam } from "../page/user/report/reports";
import { IReportEdit } from "../page/user/report/detail";

export const UserDataApi = {
  participateProject: async (code: string) => {
    console.log("participateProject", code);

    try {
      const response = await axios.post(`/project/participation`, code);
      console.log("⛑️ 감시단 프로젝트 참여 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단  ️프로젝트 참여 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getTaskDay: async (projectId: string, date: string) => {
    try {
      const response = await axios.get(`/tasks/day/${projectId}/${date}`);
      console.log("⛑️ 감시단 업무 일별 리스트", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 업무 일별 리스트 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getTaskWeek: async (projectId: string, params: IDuration) => {
    try {
      const response = await axios.get(`/tasks/week/${projectId}`, {
        params,
      });
      console.log("⛑️ 감시단 업무 주별 리스트", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 업무 주별 리스트 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getTaskMonth: async (projectId: string, date: string) => {
    try {
      const response = await axios.get(`/tasks/month/${projectId}/${date}`);
      console.log("⛑️ 감시단 업무 월별 리스트", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 업무 월별 리스트 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getTaskDetail: async (taskId: number) => {
    try {
      const response = await axios.get(`/task/${taskId}`);
      console.log("⛑️ 감시단 업무 상세", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 업무 상세 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  updateTaskDetail: async (params: ITaskData) => {
    console.log("updateTaskDetail", params);

    try {
      const response = await axios.put(`/task`, params);
      console.log("⛑️ 감시단 업무 수정 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 수정 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
  updateTaskDateTime: async (params: IDuration) => {
    console.log("updateTaskDateTime", params);
    const { id, date, startTime, endTime } = params;
    try {
      const response = await axios.put(`/task/datetime`, {
        id,
        date,
        startTime,
        endTime,
      });
      console.log("⛑️ 감시단 일시 수정 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 일시 수정 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
  reportSafety: async (params: IReportData) => {
    console.log("⛑️ 감시단 안전 보고서", params);
    const { projectId, taskId, content, files, point } = params;
    const formData = new FormData();
    formData.append("projectId", projectId + "");
    formData.append("taskId", taskId + "");
    formData.append("content", content + "");
    formData.append("point", point + "");

    if (files && files.length > 0) {
      Array.from(files).forEach((file, index) => {
        console.log(`file${index}`, file);
        formData.append(`files`, file);
      });
    }
    try {
      const response = await axios.post(`/report/safety`, formData);
      console.log("⛑️ 감시단 안전 보고서 제출 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 안전 보고서 제출 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
  reportRisk: async (params: IReportData) => {
    console.log("⛑️ 감시단 위험 보고서", params);
    const {
      projectId,
      taskId,
      riskIndex,
      disasterType,
      riskFactors,
      files,
      riskFiles,
      point,
    } = params;
    const formData = new FormData();
    formData.append("projectId", projectId + "");
    formData.append("taskId", taskId + "");
    formData.append("riskIndex", riskIndex + "");
    formData.append("disasterType", disasterType + "");
    formData.append("riskFactors", riskFactors + "");
    formData.append("point", point + "");
    if (files && files.length > 0) {
      Array.from(files).forEach((file, index) => {
        // console.log(`file${index}`, file);
        formData.append(`files`, file);
      });
    }

    if (riskFiles && riskFiles.length > 0) {
      Array.from(riskFiles).forEach((file, index) => {
        // console.log(`riskFiles{index}`, file);
        formData.append(`riskFiles`, file);
      });
    }

    if (files && files.length > 0) {
      Array.from(files).forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
    }
    if (riskFiles && riskFiles.length > 0) {
      Array.from(riskFiles).forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });
    }
    try {
      const response = await axios.post(`/report/risk`, formData);
      console.log("⛑️ 감시단 위험 보고서 제출 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 위험 보고서 제출 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getReports: async (params: IReportListParam) => {
    const { projectId, searchWord, state, slice } = params;
    try {
      const response = await axios.get(`/reports`, {
        params: {
          projectId,
          searchWord,
          state,
          slice,
        },
      });
      console.log("⛑️ 감시단 보고서 리스트", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 보고서 리스트 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getReportDetail: async (reportId: number, reportType: string) => {
    try {
      const response = await axios.get(`/admin/report/${reportId}`, {
        params: {
          reportType: reportType,
        },
      });
      console.log("⛑️ 감시단 보고서 상세", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 보고서 상세 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  editReportSafety: async (params: IReportEdit) => {
    // console.log("⛑️ 감시단 안전 보고서 수정", params);
    const {
      projectId,
      reportId,
      content,
      files,
      point,
      deleteFileIds,
      deleteFilePaths,
    } = params;
    const formData = new FormData();
    formData.append("projectId", projectId + "");
    formData.append("reportId", reportId + "");
    formData.append("content", content + "");
    formData.append("point", point + "");

    if (files && files.length > 0) {
      Array.from(files).forEach((file, index) => {
        console.log(`file${index}`, file);
        formData.append(`files`, file);
      });
    }

    if (deleteFileIds && deleteFileIds.length > 0) {
      deleteFileIds.forEach((id) => {
        formData.append("deleteFileIds", id + "");
      });
    }

    if (deleteFilePaths && deleteFilePaths.length > 0) {
      deleteFilePaths.forEach((path) => {
        formData.append("deleteFilePaths", path);
      });
    }

    try {
      const response = await axios.put(`/report/safety`, formData);
      console.log("⛑️ 감시단 안전 보고서 수정", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 안전 보고서 수정", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
  editReportRisk: async (params: IReportEdit) => {
    console.log("⛑️ 감시단 안전 보고서 수정", params);
    const {
      projectId,
      reportId,
      files,
      point,
      deleteFileIds,
      deleteFilePaths,
      riskIndex,
      disasterType,
      riskFactors,
      riskFiles,
      improvedFiles,
      improvedContent,
    } = params;
    const formData = new FormData();
    formData.append("projectId", projectId + "");
    formData.append("reportId", reportId + "");
    formData.append("point", point + "");
    formData.append("riskIndex", riskIndex + "");
    formData.append("disasterType", disasterType + "");
    formData.append("riskFactors", riskFactors + "");
    formData.append("improvedContent", improvedContent + "");

    if (files && files.length > 0) {
      Array.from(files).forEach((file, index) => {
        // console.log(`file${index}`, file);
        formData.append(`files`, file);
      });
    }
    if (riskFiles && riskFiles.length > 0) {
      Array.from(riskFiles).forEach((file, index) => {
        // console.log(`file${index}`, file);
        formData.append(`riskFiles`, file);
      });
    }
    if (improvedFiles && improvedFiles.length > 0) {
      Array.from(improvedFiles).forEach((file, index) => {
        // console.log(`file${index}`, file);
        formData.append(`improvedFiles`, file);
      });
    }

    if (deleteFileIds && deleteFileIds.length > 0) {
      deleteFileIds.forEach((id) => {
        formData.append("deleteFileIds", id + "");
      });
    }

    if (deleteFilePaths && deleteFilePaths.length > 0) {
      deleteFilePaths.forEach((path) => {
        formData.append("deleteFilePaths", path);
      });
    }
    console.log("deleteFileIds", deleteFileIds);
    console.log("deleteFilePaths", deleteFilePaths);
    try {
      const response = await axios.put(`/report/risk`, formData);
      console.log("⛑️ 감시단 위험 보고서 수정", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단 위험 보고서 수정", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  // getProjectList: async () => {
  //   try {
  //     const response = await axios.get(`/admin/projects`);
  //     console.log("프로젝트 리스트 성공", response.data);
  //     return response.data;
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.error("⛑️ 감시단 프로젝트 리스트 실패", error.response?.data);
  //     } else {
  //       console.error("Error", error);
  //     }
  //     return "error";
  //   }
  // },
  // registerProject: async (params: IProjectData) => {
  //   // console.log("registerProject", params);
  //
  //   try {
  //     const response = await axios.post(`/admin/project`, params);
  //     // console.log("프로젝트 등록 성공", response.data);
  //     return response.data.id;
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.error("프로젝트 등록 실패", error.response?.data);
  //     } else {
  //       console.error("Error", error);
  //     }
  //     return "error";
  //   }
  // },
  //
  // getProjectDetail: async (id: string) => {
  //   // console.log("registerProject", id);
  //
  //   try {
  //     const response = await axios.get(`/admin/project/${id}`);
  //     // console.log("프로젝트 상세 성공", response.data);
  //     return response.data;
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.error("프로젝트 상세 실패", error.response?.data);
  //     } else {
  //       console.error("Error", error);
  //     }
  //     return "error";
  //   }
  // },
  // updateProjectDetail: async (params: IProjectData) => {
  //   // console.log("putProjectDetail", params);
  //
  //   try {
  //     const response = await axios.put(`/admin/project`, params);
  //     // console.log("프로젝트 수정 성공", response.data);
  //     return response.data;
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.error("프로젝트 수정 실패", error.response?.data);
  //     } else {
  //       console.error("Error", error);
  //     }
  //     return "error";
  //   }
  // },
  // deleteProject: async (id: string) => {
  //   // console.log("deleteProject", id);
  //
  //   try {
  //     const response = await axios.delete(`/admin/project/${id}`);
  //     // console.log("프로젝트 삭제 성공", response.data);
  //     return response.data;
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.error("프로젝트 삭제 실패", error.response?.data);
  //     } else {
  //       console.error("Error", error);
  //     }
  //     return "error";
  //   }
  // },
  // registerTask: async (params: ITaskData) => {
  //   // console.log("registerTask", params);
  //
  //   try {
  //     const response = await axios.post(`/admin/task`, params);
  //     // console.log("업무 등록 성공", response.data);
  //     return response.data;
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.error("업무 등록 실패", error.response?.data);
  //     } else {
  //       console.error("Error", error);
  //     }
  //     return "error";
  //   }
  // },

  //
  // deleteTaskDetail: async (id: number) => {
  //   console.log("deleteTaskDetail", id);
  //
  //   try {
  //     const response = await axios.delete(`/admin/task/${id}`);
  //     console.log("업무 삭제 성공", response.data);
  //     return response.data;
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.error("업무 삭제 실패", error.response?.data);
  //     } else {
  //       console.error("Error", error);
  //     }
  //     return "error";
  //   }
  // },

  reportExtraRisk: async (params: IReportData) => {
    console.log("⛑️ 감시단 추가 위험 보고서", params);
    const {
      projectId,
      name,
      location,
      riskIndex,
      disasterType,
      riskFactors,

      riskFiles,
    } = params;
    const formData = new FormData();
    formData.append("projectId", projectId + "");
    formData.append("name", name + "");
    formData.append("location", location + "");
    formData.append("riskIndex", riskIndex + "");
    formData.append("disasterType", disasterType + "");
    formData.append("riskFactors", riskFactors + "");

    if (riskFiles && riskFiles.length > 0) {
      Array.from(riskFiles).forEach((file, index) => {
        // console.log(`riskFiles{index}`, file);
        formData.append(`riskFiles`, file);
      });
    }

    try {
      const response = await axios.post(`/report/extra`, formData);
      console.log("⛑️ 감시단  추가 위험 제출 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("⛑️ 감시단  추가 위험 제출 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
};
