import { cls } from "../libs/utils";
import React from "react";

export interface ButtonProps {
  size?: "lg" | "medium";
  isButtonDisabled?: boolean;
  disabled?: boolean;
  text: string | React.ReactElement;
  className?: string;
  [key: string]: any;
  type?: "submit" | "button";
}

export default function BasicButton({
  size,
  onClick,
  text,
  isButtonDisabled,
  className,
  disabled,
  type = "button",
  ...rest
}: ButtonProps) {
  if (size === "lg") {
    return (
      <button
        type={type}
        {...rest}
        onClick={onClick}
        disabled={disabled}
        className={cls(
          "outline-none w-full rounded-lg text-white font-semibold mt-5 py-4 disabled:bg-gray-300 disabled:cursor-default bg-blue-500 hover:bg-blue-400 active:bg-blue-600 cursor-pointer text-lg",
          className ? className : "",
        )}
      >
        {text}
      </button>
    );
  } else if (size === "medium") {
    return (
      <button
        type={type}
        {...rest}
        className={cls(
          "rounded-lg text-xs lg:text-base px-1 md:px-3 py-1 hover:shadow",
          className ? className : "",
        )}
        onClick={onClick}
      >
        {text}
      </button>
    );
  } else {
    return <></>;
  }
}
