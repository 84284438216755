import React, { useEffect, useState } from "react";
import Layout from "../../../component/layout";
import { cls } from "../../../libs/utils";
import StatusTab from "../../../component/statusTab";
import { ReactComponent as IcDnager } from "../../../asset/icon/ic_danger.svg";
import { ReactComponent as IcInspectation } from "../../../asset/icon/ic_inspection.svg";
import { ReactComponent as IcPatrol } from "../../../asset/icon/ic_patrol.svg";
import { ReactComponent as IcFinder } from "../../../asset/icon/ic_finder.svg";
import Input from "../../../component/input";
import TaskBox from "../../../component/taskBox";
import { useNavigate } from "react-router-dom";
import Tag from "../../../component/tag";
import { useQuery } from "@tanstack/react-query";
import { UserDataApi } from "../../../api/UserDataApi";
import { getCookie } from "../../../libs/cookies";
import { IReportData } from "./report";
import { format } from "date-fns";
import { safetyTypeIcon } from "../../../libs/safetyTypeIcon";
import { REPORT_STATE, REPORT_TYPE } from "../../../libs/constants";
import { useForm } from "react-hook-form";

export interface IReportList {
  date: string;
  reports: IReportData[];
}

export interface IReportListParam {
  projectId: number;
  searchWord: string;
  state?: string;
  slice?: number;
  page?: number;
}

const Reports = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<REPORT_STATE>(REPORT_STATE.ALL);
  const [searchWord, setSearchWord] = useState("");

  const projectId = getCookie("projectId");

  const initialReportListParam = {
    projectId: projectId,
    searchWord: "",
    state: "전체",
    slice: 1,
  };

  const [params, setParams] = useState<IReportListParam>(
    initialReportListParam,
  );

  const {
    data: reportsData,
    refetch: getReportsRefetch,
    isSuccess: getReportsSuccess,
  } = useQuery({
    queryKey: ["getReports", params],
    queryFn: () => UserDataApi.getReports(params),
  });

  const handleClickTab = (type: string) => {
    console.log("type", type);
    setStatus(type);
  };
  const handleClickTask = (
    taskId: number,
    reportId: number,
    reportType: REPORT_TYPE,
  ) => {
    navigate("/detail", {
      state: {
        taskId: taskId,
        reportId: reportId,
        reportType: reportType,
      },
    });
  };

  const { data, refetch: userRefetchTaskDetail } = useQuery({
    queryKey: ["getTaskDetail"],
    queryFn: () => UserDataApi.getTaskDetail(projectId),
    enabled: projectId > 0,
  });

  // 확인
  useEffect(() => {
    console.log("reportsData", reportsData);
  }, [reportsData]);

  const today = new Date();
  const isThisYear = (year: string) =>
    today.getFullYear() === new Date(year).getFullYear();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<IReportListParam>();
  const searchWordWatch = watch("searchWord");
  const onValid = (data: IReportListParam) => {
    console.log(data);
    setSearchWord(data.searchWord);
  };

  // useEffect(() => {
  //   setSearchWord(searchWordWatch);
  // }, [searchWordWatch]);

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      projectId: projectId,
      searchWord: searchWord,
      state: status,
    }));
  }, [projectId, searchWord, status]);

  // 확인
  useEffect(() => {
    console.log(params);
  }, [params]);

  return (
    <Layout
      title={"보고 관리"}
      hasTabBar={true}
      canGoBack={true}
      hasTopBar={true}
    >
      <div className="max-w-xl z-10 w-full bg-white fixed h-12 grid grid-cols-5 border-b border-gray-300 place-items-center font-semibold text-lg ">
        <StatusTab
          title={REPORT_STATE.ALL}
          type={REPORT_STATE.ALL}
          status={status}
          onClick={() => handleClickTab(REPORT_STATE.ALL)}
        />
        <StatusTab
          title={REPORT_STATE.IMPROVED}
          type={REPORT_STATE.IMPROVED}
          status={status}
          onClick={() => handleClickTab(REPORT_STATE.IMPROVED)}
        />
        <StatusTab
          title={REPORT_STATE.PENDING}
          type={REPORT_STATE.PENDING}
          status={status}
          onClick={() => handleClickTab(REPORT_STATE.PENDING)}
        />
        <StatusTab
          title={REPORT_STATE.APPROVAL}
          type={REPORT_STATE.APPROVAL}
          status={status}
          onClick={() => handleClickTab(REPORT_STATE.APPROVAL)}
        />
        <StatusTab
          title={REPORT_STATE.REJECTED}
          type={REPORT_STATE.REJECTED}
          status={status}
          onClick={() => handleClickTab(REPORT_STATE.REJECTED)}
        />
      </div>
      <form
        className="bg-white relative px-5 mb-4 mt-16 w-full"
        onSubmit={handleSubmit(onValid)}
      >
        <Input
          type="text"
          placeholder="보고서 제목"
          icon={
            <span className="text-gray-300">
              <IcFinder />
            </span>
          }
          className="rounded-xl mx-auto border-0 h-12 bg-lightGray"
          register={register("searchWord")}
        />
      </form>

      <div className="bg-white h-full">
        <div className="pb-5 px-3">
          {getReportsSuccess &&
            reportsData &&
            reportsData?.data?.items &&
            reportsData?.data?.items.map(
              (reportList: IReportList, index: number) => (
                <>
                  <div
                    key={`${reportList.date} reports_${index}`}
                    className="flex items-center h-9 text-gray-500 font-medium pt-10 pb-4"
                  >
                    {format(
                      reportList.date || new Date(),
                      isThisYear(reportList.date || "")
                        ? "MM월 dd일"
                        : "yyyy년 MM월 dd일",
                    )}
                  </div>
                  <div
                    key={`${reportList.date}_${index}`}
                    className="space-y-3"
                  >
                    {reportList.reports.map((report, index) => (
                      <TaskBox
                        key={`${reportList.date}_${index}`}
                        icon={safetyTypeIcon(report.safetyType || "로딩중")}
                        onClick={() =>
                          handleClickTask(
                            report.taskId || -1,
                            report.reportId || -1,
                            report.reportType || REPORT_TYPE.SAFE,
                          )
                        }
                        type={"report"}
                        content={{
                          title:
                            `${report.name} ${report.reportType} 보고` ||
                            "로딩중",
                          primary: report.majorCategory || "로딩중",
                          sub: report.middleCategory || "로딩중",
                          location: report.location || "로딩중",
                          status: report.state || "로딩중",
                        }}
                        boxClassName={
                          "bg-gray-200/35 hover:bg-gray-200/25 active:bg-gray-200/30"
                        }
                        iconClassName={"text-gray-500"}
                      />
                    ))}
                  </div>
                </>
              ),
            )}
        </div>
      </div>
    </Layout>
  );
};

export default Reports;
