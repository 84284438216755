import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "../../../component/input";
import Layout from "../../../component/admin/layout";

import { useDispatch } from "react-redux";
import { setModalOpen } from "../../../store/features/modalSlice";
import { useQuery } from "@tanstack/react-query";
import { AdminDataApi } from "../../../api/AdminDataApi";
import { format, parse } from "date-fns";
import { ITaskData } from "./register";
import { useMutation } from "@tanstack/react-query";
import { renderButton } from "../../../component/statusButton";
import CalendarContext from "../../../utils/CalendarRefContext";
import { getCookie } from "../../../libs/cookies";
import BasicButton from "../../../component/basicButton";
import { UserDataApi } from "../../../api/UserDataApi";
import { REPORT_TYPE, TASK_STATE } from "../../../libs/constants";
import { isAfter, parseISO } from "date-fns";

interface ButtonProps {
  text: string;
  bgColor: string;
  hoverBgColor: string;
  activeBgColor: string;
  textColor: string;
  onClick: () => void;
  type?: "submit" | "button";
}

const Detail = ({
  id,
  getTaskRefetch,
}: {
  id: number;
  getTaskRefetch?: () => void;
}) => {
  const today = new Date();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calendarRef = useContext(CalendarContext);
  const [reportType, setReportType] = useState("danger");
  const [isEdit, setIsEdit] = useState(false);
  const [isImprove, setIsImprove] = useState(false);
  const mode = isEdit ? "text" : "div";
  const [taskDetail, setTaskDetail] = useState<ITaskData>();
  const isAdmin = getCookie("auth") === "ROLE_ADMIN";

  useEffect(() => {}, []);

  const { data, refetch } = useQuery({
    queryKey: ["getAdminTaskDetail", id],
    queryFn: () => AdminDataApi.getAdminTaskDetail(id),
    enabled: isAdmin && id > 0,
  });

  const { data: userData, refetch: userRefetchTaskDetail } = useQuery({
    queryKey: ["getTaskDetail", id],
    queryFn: () => UserDataApi.getTaskDetail(id),
    enabled: !isAdmin && id > 0,
  });

  useEffect(() => {
    if (data && data !== "error") {
      console.log("detail", data);
      setTaskDetail(data.data);
      const formattedDate = format(
        new Date(data.data.date),
        "yyyy년 MM월 dd일",
      );
      setValue("date", formattedDate);
      setValue("name", data.data.name);
      setValue("coworkerName", data.data.coworkerName);
      setValue("managerName", data.data.managerName);
      setValue("startTime", data.data.startTime.slice(0, 5));
      setValue("endTime", data.data.endTime.slice(0, 5));
      setValue("location", data.data.location);
      setValue("safetyType", data.data.safetyType);
      setValue("majorCategory", data.data.majorCategory);
      setValue("middleCategory", data.data.middleCategory);
      setValue("subcontractorName", data.data.subcontractorName);
      setValue("workerNum", data.data.workerNum);
    }
  }, [data]);

  useEffect(() => {
    if (userData && userData !== "error") {
      console.log("detail", userData);
      setTaskDetail(userData?.data);
      if (userData?.data?.state === TASK_STATE.IMPROVED) {
        setIsImprove(true);
      } else {
        setIsImprove(false);
      }
      setValue(
        "date",
        format(new Date(userData?.data?.date), "yyyy년 MM월 dd일"),
      );
      setValue("name", userData?.data?.name);
      setValue("coworkerName", userData?.data?.coworkerName);
      setValue("managerName", userData?.data?.managerName);
      setValue("startTime", userData?.data?.startTime.slice(0, 5));
      setValue("endTime", userData?.data?.endTime.slice(0, 5));
      setValue("location", userData?.data?.location);
      setValue("safetyType", userData?.data?.safetyType);
      setValue("majorCategory", userData?.data?.majorCategory);
      setValue("middleCategory", userData?.data?.middleCategory);
      setValue("subcontractorName", userData?.data?.subcontractorName);
      setValue("workerNum", userData?.data?.workerNum);
    }
  }, [userData]);

  const updateTaskDetail = useMutation<string, string, ITaskData>({
    mutationFn: (param: ITaskData) => AdminDataApi.updateTaskDetail(param),
    onSuccess: (data) => {
      if (data && data !== "error") {
        console.log(data);
        setIsEdit(false);
        refetch();
        getTaskRefetch && getTaskRefetch();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const userUpdateTaskDetail = useMutation<string, string, ITaskData>({
    mutationFn: (param: ITaskData) => UserDataApi.updateTaskDetail(param),
    onSuccess: (data) => {
      if (data && data !== "error") {
        console.log(data);
        setIsEdit(false);
        refetch();
        getTaskRefetch && getTaskRefetch();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const deleteTask = useMutation<string, string, number>({
    mutationFn: () => AdminDataApi.deleteTaskDetail(id),
    onSuccess: (data) => {
      if (data && data !== "error") {
        console.log(data);

        getTaskRefetch && getTaskRefetch();
        setTimeout(() => {
          dispatch(setModalOpen(false));
        }, 200);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue,
  } = useForm<ITaskData>();
  const onValid = (data: ITaskData) => {
    const tmpStartDate = parseISO(`2022-01-01T${data.startTime}:00`);
    const tmpEndDate = parseISO(`2022-01-01T${data.endTime}:00`);

    if (!isAfter(tmpEndDate, tmpStartDate)) {
      setError("endTime", { message: "종료시간을 올바르게 작성해주세요" });
    }
    console.log(data);
    let parseDate;
    if (data.date) {
      parseDate = parse(data.date, "yyyy년 MM월 dd일", new Date());
    }
    const formattedDate = format(parseDate || "", "yyyy-MM-dd");
    data.date = formattedDate;
    data.startTime += ":00";
    data.endTime += ":00";

    data.id = id;
    if (isAdmin) {
      updateTaskDetail.mutate(data);
    } else {
      userUpdateTaskDetail.mutate(data);
    }
  };
  //
  // // 조건에 따른 버튼 렌더링을 위한 함수
  // const renderButton = ({
  //   text,
  //   bgColor,
  //   hoverBgColor,
  //   activeBgColor,
  //   textColor,
  //   onClick,
  //   type = "button",
  // }: ButtonProps) => (
  //   <button
  //     className={`rounded-lg px-3 lg:px-5 py-2 lg:py-3 text-sm lg:text-base ${bgColor} ${hoverBgColor} ${activeBgColor} ${textColor} transition-colors font-semibold`}
  //     onClick={onClick}
  //     type={type}
  //   >
  //     {text}
  //   </button>
  // );
  const handleDeleteBtn = () => {
    const event = calendarRef.calendarRef.current.current
      .getApi()
      .getEventById(id.toString());
    if (event) {
      event.remove();
    }
    deleteTask.mutate(id);
  };

  const handleReportClick = () => {
    console.log(" taskDetail.reportType ", taskDetail && taskDetail.reportType);
    if (taskDetail && taskDetail.reportType && taskDetail.reportId) {
      navigate(`/detail`, {
        state: {
          taskId: id,
          reportId: taskDetail && taskDetail.reportId,
          reportType: taskDetail && taskDetail.reportType,
          improved: true,
        },
      });
    } else {
      navigate(`/report`, {
        state: {
          taskId: id,
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <div>
        <Input
          type={mode}
          label={"업무명"}
          value={(taskDetail && taskDetail.name) || "로딩중"}
          placeholder={(taskDetail && taskDetail.name) || "로딩중"}
          className=" h-16 lg:text-lg border-b-0 rounded-t-lg"
          register={register("name", { required: true })}
        />
        {!isEdit && (
          <Input
            type={"div"}
            label={"상태"}
            value={(taskDetail && taskDetail.state) || "로딩중"}
            placeholder={(taskDetail && taskDetail.state) || "로딩중"}
            className=" h-16 lg:text-lg border-b-0"
            register={register("date")}
          />
        )}
        <Input
          type={mode}
          label={"날짜"}
          value={
            (taskDetail &&
              format(new Date(taskDetail.date || ""), "yyyy년 MM월 dd일")) ||
            "로딩중"
          }
          placeholder={
            (taskDetail &&
              format(new Date(taskDetail.date || ""), "yyyy년 MM월 dd일")) ||
            "로딩중"
          }
          className=" h-16 lg:text-lg border-b-0"
          register={register("date", {
            required: true,
            pattern: {
              value:
                /(19|20)\d{2}년 (0[1-9]|1[0-2])월 (0[1-9]|[12][0-9]|3[01])/,
              message: "날짜를 올바르게 입력해주세요",
            },
            maxLength: 13,
          })}
          maxLength={13}
          errorMessage={errors?.date?.message}
        />
        <Input
          type={mode}
          label={"시작시간"}
          value={(taskDetail && taskDetail.startTime.slice(0, 5)) || "로딩중"}
          placeholder={
            (taskDetail && taskDetail.startTime.slice(0, 5)) || "로딩중"
          }
          className=" h-16 lg:text-lg border-b-0"
          register={register("startTime", {
            required: true,
            pattern: {
              value: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
              message: "시작시간을 올바르게 입력해주세요",
            },
            maxLength: 5,
          })}
          maxLength={5}
          errorMessage={errors?.startTime?.message}
        />
        <Input
          type={mode}
          label={"종료시간"}
          value={(taskDetail && taskDetail.endTime.slice(0, 5)) || "로딩중"}
          placeholder={
            (taskDetail && taskDetail.endTime.slice(0, 5)) || "로딩중"
          }
          className=" h-16 lg:text-lg border-b-0"
          register={register("endTime", {
            required: true,
            pattern: {
              value: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
              message: "종료시간을 올바르게 입력해주세요",
            },
            maxLength: 5,
          })}
          errorMessage={errors?.endTime?.message}
          maxLength={5}
        />
        <Input
          type={mode}
          label={"위치"}
          value={(taskDetail && taskDetail.location) || "로딩중"}
          placeholder={(taskDetail && taskDetail.location) || "로딩중"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("location")}
        />
        <Input
          type={mode}
          label={"동행자"}
          value={(taskDetail && taskDetail.coworkerName) || "로딩중"}
          placeholder={(taskDetail && taskDetail.coworkerName) || "로딩중"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("coworkerName")}
        />
        {isEdit ? (
          <Input
            type={"select"}
            label={"안전감시유형"}
            options={["선택", "고위험작업", "안전순찰", "장비점검"]}
            className=" h-16 lg:text-lg border-b-0"
            register={register("safetyType")}
          />
        ) : (
          <Input
            type={"div"}
            label={"안전감시유형"}
            value={(taskDetail && taskDetail.safetyType) || "로딩중"}
            placeholder={(taskDetail && taskDetail.safetyType) || "로딩중"}
            className=" h-16 lg:text-lg border-b-0"
          />
        )}
        <Input
          type={mode}
          label={"작업명"}
          value={(taskDetail && taskDetail.majorCategory) || "로딩중"}
          placeholder={(taskDetail && taskDetail.majorCategory) || "로딩중"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("majorCategory")}
        />
        <Input
          type={mode}
          label={"단위작업명"}
          value={(taskDetail && taskDetail.middleCategory) || "로딩중"}
          placeholder={(taskDetail && taskDetail.middleCategory) || "로딩중"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("middleCategory")}
        />
        <Input
          type={mode}
          label={"협력업체명"}
          value={(taskDetail && taskDetail.subcontractorName) || "로딩중"}
          placeholder={(taskDetail && taskDetail.subcontractorName) || "로딩중"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("subcontractorName")}
        />
        <Input
          type={mode}
          label={"협력직원수"}
          value={(taskDetail && taskDetail.workerNum) || "로딩중"}
          placeholder={(taskDetail && taskDetail.workerNum) || "로딩중"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("workerNum", {
            required: true,
            pattern: {
              value: /^\d+$/,
              message: "협력직원수를 올바르게 입력해주세요",
            },
            maxLength: 3,
          })}
          maxLength={3}
          errorMessage={errors.workerNum?.message}
        />
        <Input
          type={mode}
          label={"담당관리자"}
          value={(taskDetail && taskDetail.managerName) || "로딩중"}
          placeholder={(taskDetail && taskDetail.managerName) || "로딩중"}
          className=" h-16 lg:text-lg border-b-1"
          register={register("managerName")}
        />
        {isAdmin ? (
          <div className="border-t-0 rounded-b-lg flex space-x-10 justify-between items-center w-full border border-gray-300 bg-white py-4 px-3 lg:px-10">
            {renderButton({
              text: isEdit ? "취소" : "삭제",
              bgColor: "bg-lightGray",
              type: "button",
              hoverBgColor: "hover:bg-lightGray/50",
              activeBgColor: "active:bg-gray-100",
              textColor: "text-gray-600",
              onClick: () => (isEdit ? setIsEdit(false) : handleDeleteBtn()),
            })}

            <div className="flex space-x-4">
              {!isEdit &&
                taskDetail?.state !== TASK_STATE.COMPLETED &&
                taskDetail?.state !== TASK_STATE.IMPROVING &&
                taskDetail?.state !== TASK_STATE.IMPROVED &&
                renderButton({
                  text: "수정",
                  type: "button",
                  bgColor: "bg-blue-500/30",
                  hoverBgColor: "hover:bg-blue-500/20",
                  activeBgColor: "active:bg-blue-500/50",
                  textColor: "text-blue-700",
                  onClick: () => {
                    setIsEdit(true);
                  },
                })}
              {isEdit &&
                renderButton({
                  text: "완료",
                  type: "submit",
                  bgColor: "bg-green-500/30",
                  hoverBgColor: "hover:bg-green-500/20",
                  activeBgColor: "active:bg-green-500/50",
                  textColor: "text-green-700",
                })}
            </div>
          </div>
        ) : (
          <div className="border-t-0 rounded-b-lg flex space-x-10 justify-between items-center w-full border border-gray-300 bg-white py-4 px-3 lg:px-10">
            {isEdit &&
              renderButton({
                text: "취소",
                bgColor: "bg-lightGray",
                type: "button",
                hoverBgColor: "hover:bg-lightGray/50",
                activeBgColor: "active:bg-gray-100",
                textColor: "text-gray-600",
                onClick: () => setIsEdit(false),
              })}
            {!isEdit &&
              (taskDetail?.state === TASK_STATE.IMPROVED ||
                taskDetail?.state === TASK_STATE.PLANNED) &&
              renderButton({
                text: isImprove ? "개선 보고서 작성" : "보고서 작성",
                bgColor: "bg-blue-500",
                type: "button",
                hoverBgColor: "hover:bg-blue-400",
                activeBgColor: "bg-blue-600",
                textColor: "text-white",
                onClick: () => handleReportClick(),
              })}
            {/*<BasicButton*/}
            {/*  text="보고서 작성"*/}
            {/*  size={"lg"}*/}
            {/*  onClick={() => handleReportClick()}*/}
            {/*/>*/}
            <div className="flex space-x-4">
              {!isEdit &&
                renderButton({
                  text: "수정",
                  type: "button",
                  bgColor: "bg-blue-500/30",
                  hoverBgColor: "hover:bg-blue-500/20",
                  activeBgColor: "active:bg-blue-500/50",
                  textColor: "text-blue-700",
                  onClick: () => {
                    setIsEdit(true);
                  },
                })}
              {isEdit &&
                renderButton({
                  text: "완료",
                  type: "submit",
                  bgColor: "bg-green-500/30",
                  hoverBgColor: "hover:bg-green-500/20",
                  activeBgColor: "active:bg-green-500/50",
                  textColor: "text-green-700",
                })}
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default Detail;
