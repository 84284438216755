import React, { useContext, useEffect, useState } from "react";
import Layout from "../../component/layout";
import Calendar from "../../utils/Calendar";
import { calendarFns } from "../../utils/calendarFns";
import { ReactComponent as IcDnager } from "../../asset/icon/ic_danger.svg";
import { ReactComponent as IcInspectation } from "../../asset/icon/ic_inspection.svg";
import { ReactComponent as IcPatrol } from "../../asset/icon/ic_patrol.svg";
import { ReactComponent as IcFinder } from "../../asset/icon/ic_finder.svg";
import { useNavigate } from "react-router-dom";
import TaskBox from "../../component/taskBox";
import FloatButton from "../../component/floatButton";
import UnisCalendar from "../../utils/unisCalendar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ITaskData, RegisterEventProps } from "../admin/task/register";
import Detail from "../admin/task/detail";
import Modal from "../../component/modal";
import CalendarRefContext from "../../utils/CalendarRefContext";
import { getCookie } from "../../libs/cookies";
import ProjectCode from "./Participation";
import { setModalOpen } from "../../store/features/modalSlice";
import { useQuery } from "@tanstack/react-query";
import { AdminDataApi } from "../../api/AdminDataApi";
import { format } from "date-fns";
import { safetyTypeIcon } from "../../libs/safetyTypeIcon";
import {
  taskBoxBg,
  taskIconColor,
  taskStateBoxBg,
  taskStateColor,
} from "../../libs/utils";
import { UserDataApi } from "../../api/UserDataApi";
import Tag from "../../component/tag";
import { TASK_STATE } from "../../libs/constants";

const Home = () => {
  const { setCalendarRef } = useContext(CalendarRefContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(today);
  const [viewType, setViewType] = useState("dayGridMonth");
  const modalOpen = useSelector((state: RootState) => state.modal.modalOpen);
  const taskId = useSelector((state: RootState) => state.task.selectedTaskId);
  const [selectedId, setSelectedId] = useState(taskId);
  const [registerEvent, setRegisterEvent] = useState<RegisterEventProps>({
    start: "",
    end: "",
  });
  const [modalTitle, setModalTitle] = useState("업무 상세");
  const [addBtns, setAddBtns] = useState(false);
  const [modalContent, setModalContent] = useState<null | React.ReactElement>(
    null,
  );
  const projectId = getCookie("projectId");
  const [taskDay, setTaskDay] = useState<ITaskData[]>();
  useEffect(() => {
    setModalTitle("업무 상세");
    setModalContent(
      <Detail id={selectedId} getTaskRefetch={getUserTaskRefetch} />,
    );
  }, [selectedId]);

  const handleClickTask = (taskId: number) => {
    console.log("taskId", taskId);
    setSelectedId(taskId);
    dispatch(setModalOpen(true));
  };
  const {
    data,
    isSuccess,
    isLoading,
    refetch: getUserTaskRefetch,
  } = useQuery({
    queryKey: ["getTaskDay", selectedDate],
    queryFn: () =>
      UserDataApi.getTaskDay(projectId, format(selectedDate, "yyyy-MM-dd")),
  });

  useEffect(() => {
    if (data && data !== "error") {
      setTaskDay(data.data.items);
    }
  }, [data]);

  // useEffect(() => {
  //   setModalOpen(false);
  // }, []);

  return (
    <Layout hasTopBar={false} hasTabBar={true}>
      {modalOpen && <Modal title={modalTitle} content={modalContent} />}
      <div className="pt-9 bg-white">
        <div className="max-h-[600px] overflow-y-auto">
          <UnisCalendar
            setCalendarRef={setCalendarRef}
            setSelectedDate={setSelectedDate}
            setViewType={setViewType}
            setSelectedId={setSelectedId}
            setRegisterEvent={setRegisterEvent}
            getTaskRefetch={getUserTaskRefetch}
          />
        </div>
        {viewType === "dayGridMonth" && (
          <div className="border-t border-${task.state}-300">
            <div className="flex items-center justify-between text-base font-semibold text-${task.state}-600 px-3 py-3">
              <span>
                {`${calendarFns.formatToday(selectedDate)} 오늘의 업무`}
              </span>
              <span className="flex items-center space-x-1">
                <span className="flex items-center font-light text-base">
                  -10°C
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-7 h-7"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                  />
                </svg>
              </span>
            </div>
            <div className="px-3 space-y-2 h-56 overflow-y-auto">
              {taskDay && taskDay.length > 0 ? (
                taskDay.map((task, index) => (
                  <TaskBox
                    key={index}
                    icon={
                      <div>
                        <Tag
                          color={taskStateColor(task.state)}
                          title={task.state}
                          className="!text-xs"
                        />
                      </div>
                    }
                    onClick={() => handleClickTask(task.taskId)}
                    type={"task"}
                    content={{
                      title: task.taskName || "로딩중",
                      writer: task.coworkerName || "로딩중",
                      location: task.location || "로딩중",
                      startTime: task.startTime.slice(0, -3) || "로딩중",
                      endTime: task.endTime.slice(0, -3) || "로딩중",
                      status: "fixed",
                    }}
                    // boxClassName={`bg-${taskStateColor(task.state)}-200/60 hover:bg-${taskStateColor(task.state)}-200/40 active:bg-${taskStateColor(task.state)}-200/80`}
                    boxClassName={taskStateBoxBg(task.state)}
                    // iconClassName={
                    //   taskIconColor(task.safetyType) || "text-${task.state}}-500"
                    // }
                  />
                ))
              ) : (
                <p>등록된 업무가 없습니다</p>
              )}
            </div>
          </div>
        )}
      </div>

      <FloatButton onClick={() => navigate("/extraReport")} />
    </Layout>
  );
};

export default Home;
