import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Menu = ({
  onClose,
  menuOpen,
}: {
  onClose: () => void;
  menuOpen: boolean;
}) => {
  const handleClickBackdrop = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  const navigate = useNavigate();
  const handleMenuClick = (url: string) => {
    onClose();
    setTimeout(() => {
      navigate(url);
    }, 200);
  };
  return (
    <>
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            key={"menuBackdrop"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.2 }}
            className="fixed inset-0 bg-black/30 z-30"
            onClick={(event) => handleClickBackdrop(event)}
          >
            <motion.div
              key={"menu"}
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 20, opacity: 0 }}
              transition={{ ease: "easeInOut", duration: 0.2 }}
              className="fixed w-1/2 h-full top-0 right-0 bg-white z-50"
            >
              <div className="h-12 flex items-center justify-end px-4">
                <button onClick={() => onClose()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <nav>
                <ul className="px-8 *:h-16 *:text-gray-950 *:font-semibold *:text-lg *:items-center *:cursor-pointer *:hover:text-gray-800">
                  <li onClick={() => handleMenuClick("/admin/home")}>홈</li>
                  <li onClick={() => handleMenuClick("/admin/task")}>
                    업무 관리
                  </li>
                  <li onClick={() => handleMenuClick("/admin/report")}>
                    안전 보고
                  </li>
                  <li onClick={() => handleMenuClick("/admin/video")}>
                    영상 보고
                  </li>
                  <li onClick={() => handleMenuClick("/admin/uniscam")}>
                    유니슨 캠
                  </li>
                  <li onClick={() => handleMenuClick("/admin/member")}>
                    감시단 관리
                  </li>
                  <li onClick={() => handleMenuClick("/admin/project")}>
                    프로젝트 관리
                  </li>
                  <li onClick={() => handleMenuClick("/admin/account")}>
                    계정 관리
                  </li>
                </ul>
              </nav>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Menu;
