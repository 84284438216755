export const ROLE = {
  USER: "ROLE_USER",
  ADMIN: "ROLE_ADMIN",
};
export const PROJECT_STATE = {
  PRESTART: "시작전",
  PROCESSING: "진행중",
  STOP: "중단",
  COMPLETED: "완료",
};

export const USER_STATUS = {
  ATTENDANCE: "출근",
  LATE: "지각",
  LEAVE: "연차",
};

export const TASK_STATE = {
  PLANNED: "계획",
  PENDING: "대기",
  REJECTED: "반려",
  IMPROVING: "개선중",
  IMPROVED: "개선 완료",
  COMPLETED: "완료",
};
export type TASK_STATE = (typeof TASK_STATE)[keyof typeof TASK_STATE];

export const REPORT_TYPE = {
  DANGER: "위험",
  SAFE: "안전",
  IMPROVED: "개선",
} as const;

export type REPORT_TYPE = (typeof REPORT_TYPE)[keyof typeof REPORT_TYPE];

export const REPORT_STATE = {
  ALL: "전체",
  APPROVAL: "승인",
  REJECTED: "반려",
  IMPROVING: "개선 시작",
  IMPROVED: "개선 완료",
  PENDING: "대기",
};
export type REPORT_STATE = (typeof REPORT_STATE)[keyof typeof REPORT_STATE];

export const RISK_INDEX = {
  URGENT: "상",
  PRIORITY: "중",
  REVIEW: "하",
};

export type RISK_INDEX = (typeof RISK_INDEX)[keyof typeof RISK_INDEX];
