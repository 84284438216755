import React from "react";
import Input from "../../../component/input";
import BasicButton from "../../../component/basicButton";
import { useForm } from "react-hook-form";
import { IReportData } from "../../user/report/report";

interface IRefusalReason {
  reason: string;
}
const RefusalReason = ({
  setRefusalReason,
}: {
  setRefusalReason: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<IRefusalReason>();
  const reason = watch("reason");
  const isButtonDisabled = !reason;
  const onValid = (data: IRefusalReason) => {
    console.log(data.reason);
    setRefusalReason(data?.reason);
  };
  return (
    <div className="mx-3 mb-5 h-fit bg-white">
      <form
        onSubmit={handleSubmit(onValid)}
        className="h-full flex flex-col 0justify-center items-center"
      >
        <Input
          type={"text"}
          placeholder={"반려 사유"}
          required={true}
          className="h-16 rounded-lg"
          register={register("reason", { required: true })}
        />
        <BasicButton
          text={"등록"}
          type={"submit"}
          disabled={isButtonDisabled}
          size={"lg"}
        />
      </form>
    </div>
  );
};

export default RefusalReason;
