import React, { useState, useEffect } from "react";
import Layout from "../../../component/layout";
import Input from "../../../component/input";
import { cls } from "../../../libs/utils";
import BasicButton from "../../../component/basicButton";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { calendarFns } from "../../../utils/calendarFns";
import CreateFile, { SavedFileInfo } from "../../../component/createFile";
import { useDispatch } from "react-redux";
import { setSelectedTaskId } from "../../../store/features/task/taskSlice";
import { useQuery } from "@tanstack/react-query";
import { UserDataApi } from "../../../api/UserDataApi";
import { getCookie } from "../../../libs/cookies";
import { ITaskData } from "../../admin/task/register";
import { format } from "date-fns";
import { REPORT_TYPE, RISK_INDEX, TASK_STATE } from "../../../libs/constants";
import { useMutation } from "@tanstack/react-query";
import { CommonDataApi } from "../../../api/CommonDataApi";
import { IJoinData } from "../join";
import { Simulate } from "react-dom/test-utils";
import error = Simulate.error;
import { setModalOpen } from "../../../store/features/modalSlice";

export interface IReportData {
  reportId?: number;
  name?: string;
  reportType?: REPORT_TYPE;
  taskName?: string;
  coworkerName?: string;
  safetyType?: string;
  majorCategory?: string;
  middleCategory?: string;
  subcontractorName?: string;
  workerNum?: string;
  date?: string;
  projectId?: number;
  taskId?: number;
  content?: string;
  location?: string;
  files?: File[];
  riskIndex?: string;
  disasterType?: string;
  riskFactors?: string;
  point?: string;
  state?: string;
  riskFiles?: File[];
  improvedFiles?: File[];
  improvedContent?: string;
  createdAt?: string;
  authorName?: string;
}
const Report = () => {
  // const taskId = useParams().taskId;
  const today = new Date();
  const [reportType, setReportType] = useState<REPORT_TYPE>(REPORT_TYPE.DANGER);
  const [reportBasicData, setReportBasicData] = useState<IReportData>({
    taskName: "",
    location: "",
    date: "",
    coworkerName: "",
    safetyType: "",
    majorCategory: "",
    middleCategory: "",
    subcontractorName: "",
    workerNum: "",
    state: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  // const { reportedId } = location.state || {};
  const { taskId } = location.state || {};
  // const { reportedType } = location.state || {};
  const dispatch = useDispatch();
  const projectId = getCookie("projectId");
  // const [files, setFiles] = useState<SavedFileInfo[]>();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    reset,
    setValue,
    resetField,
  } = useForm<IReportData>();

  // useEffect(() => {
  //   if (reportedId && reportedType) {
  //     setReportType(REPORT_TYPE.IMPROVED);
  //   }
  // }, [reportedType, reportedId]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedRiskFiles, setSelectedRiskFiles] = useState<File[]>([]);
  useEffect(() => {
    console.log("selectedFiles", selectedFiles);
  }, [selectedFiles]);

  const { data: userData, refetch: userRefetchTaskDetail } = useQuery({
    queryKey: ["getTaskDetail"],
    queryFn: () => UserDataApi.getTaskDetail(taskId),
  });

  const { mutate: reportSafety, isPending: reportSafetyLoading } = useMutation<
    string,
    string,
    IReportData
  >({
    mutationFn: (param: IReportData) => UserDataApi.reportSafety(param),
    onSuccess: (data: any) => {
      console.log(data);
      if (data && data.code === "OK") {
        navigate(`/detail`, {
          state: {
            taskId: data.data.taskId,
            reportId: data.data.id,
            reportType: reportType,
          },
        });
        dispatch(setModalOpen(false));
      } else {
        return;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { mutate: reportRisk, isPending: reportriskLoading } = useMutation<
    string,
    string,
    IReportData
  >({
    mutationFn: (param: IReportData) => UserDataApi.reportRisk(param),
    onSuccess: (data: any) => {
      console.log(data);
      if (data && data.code === "OK") {
        navigate(`/detail`, {
          state: {
            taskId: data.data.taskId,
            reportId: data.data.id,
            reportType: reportType,
          },
        });
        dispatch(setModalOpen(false));
      } else {
        return;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
  // const {
  //   data: useReportDetailData,
  //   refetch: userRefetchReportDetail,
  //   isSuccess: userReportDetailSuccess,
  // } = useQuery({
  //   queryKey: ["getReportDetail", reportedId],
  //   queryFn: () => UserDataApi.getReportDetail(reportedId, reportedType),
  //   enabled: reportType === REPORT_TYPE.IMPROVED,
  // });

  useEffect(() => {
    dispatch(setSelectedTaskId(taskId));
  }, []);

  // useEffect(() => {
  //   reset();
  // }, [reportType]);

  useEffect(() => {
    if (userData?.data) {
      setReportBasicData((prev) => ({
        ...prev,
        name: userData.data.name,
        location: userData.data.location,
        date: userData.data.date,
        coworkerName: userData.data.coworkerName,
        safetyType: userData.data.safetyType,
        majorCategory: userData.data.majorCategory,
        middleCategory: userData.data.middleCategory,
        subcontractorName: userData.data.subcontractorName,
        workerNum: userData.data.workerNum,
        state: userData.data.state,
        files: userData.data.files,
      }));
      setValue("coworkerName", userData.data.coworkerName);
      setValue("location", userData.data.location);
      setValue("safetyType", userData.data.safetyType);
      setValue("majorCategory", userData.data.majorCategory);
      setValue("middleCategory", userData.data.middleCategory);
      setValue("subcontractorName", userData.data.subcontractorName);
      setValue("workerNum", userData.data.workerNum);
    }
  }, [userData]);

  // useEffect(() => {
  //   console.log("useReportDetailData", useReportDetailData);
  //   if (useReportDetailData) {
  //     // console.log("위험 보고서");
  //     setValue("point", useReportDetailData.data.point);
  //     setValue("riskIndex", useReportDetailData.data.riskIndex);
  //     setValue("disasterType", useReportDetailData.data.disasterType);
  //     setValue("riskFactors", useReportDetailData.data.riskFactors);
  //     setFiles(useReportDetailData.data.files);
  //   }
  // }, [useReportDetailData]);

  const watchForm = watch();

  // useEffect(() => {
  //   console.log(files);
  // }, [files]);

  const onValid = (data: IReportData) => {
    if (selectedFiles.length === 0) {
      setError("files", {
        message: "작업현장 사진을 한 장 이상 첨부해주세요",
      });
    }
    data.projectId = projectId;
    data.taskId = taskId;
    data.files = selectedFiles;
    console.log(data);
    if (reportType === REPORT_TYPE.DANGER) {
      if (selectedRiskFiles.length === 0) {
        setError("riskFiles", {
          message: "위험요소 사진을 한 장 이상 첨부해주세요",
        });
      }
      data.riskFiles = selectedRiskFiles;
      reportRisk(data);
    } else {
      reportSafety(data);
    }
  };
  const handleChangeType = (event: any) => setReportType(event.target.value);

  return (
    <div className="px-3 my-3 *:text-gray-600">
      <Layout
        title={`${reportType} 보고서 작성`}
        canGoBack={true}
        hasTabBar={false}
        hasTopBar={true}
      >
        <form onSubmit={handleSubmit(onValid)}>
          <>
            <div className="w-full py-2 border border-gray-300 rounded-t-lg bg-white">
              <div className="w-full py-2 flex justify-center items-center font-bold text-xl">
                <span>{`${reportBasicData.name} ${reportType} 보고`}</span>
              </div>
              <p className="flex justify-between font-medium text-xs text-right px-4">
                <span>위치 :{reportBasicData.location}</span>
                <span>
                  일자 :
                  {format(
                    reportBasicData.date || new Date(),
                    "yyyy년 MM월 dd일",
                  )}
                </span>
              </p>
            </div>
            <Input
              type={"div"}
              label={"동행자"}
              value={reportBasicData.coworkerName}
              className="h-14 lg:text-lg border-t-0 border-b-1"
            />
            <Input
              type={"div"}
              label={"감시유형"}
              value={reportBasicData.safetyType}
              className="h-14 lg:text-lg border-t-0 border-b-0"
            />
            <Input
              type={"div"}
              label={"작업명"}
              value={reportBasicData.majorCategory}
              className="h-14 lg:text-lg border-b-0"
            />
            <Input
              type={"div"}
              label={"단위작업명"}
              value={reportBasicData.middleCategory}
              className="h-14 lg:text-lg border-b-0"
            />
            <Input
              type={"div"}
              label={"협력업체명"}
              value={reportBasicData.subcontractorName}
              className="h-14 lg:text-lg border-b-0"
            />
            <Input
              type={"div"}
              label={"직업인원수"}
              value={reportBasicData.workerNum}
              className="h-14 lg:text-lg border-b-1"
            />
            {/*{reportType === REPORT_TYPE.IMPROVED ? (*/}
            {/*  <>*/}
            {/*    <div className="bg-lightGray h-14 lg:text-lg border-x border-b border-gray-300 font-bold flex justify-center items-center">*/}
            {/*      {userReportDetailSuccess && useReportDetailData.data.name}{" "}*/}
            {/*      위험 보고서*/}
            {/*    </div>*/}
            {/*    <div className="bg-lightGray h-full border border-gray-300 border-t-0 border-b-1 p-4">*/}
            {/*      <p className="font-semibold pb-3">작업 현장</p>*/}

            {/*      <div className=" w-full flex items-center justify-start h-48 rounded-md overflow-x-auto overflow-y-hidden space-x-2">*/}
            {/*        {files &&*/}
            {/*          files.map((info: SavedFileInfo, index: number) => (*/}
            {/*            <div*/}
            {/*              key={index}*/}
            {/*              className="relative h-32 aspect-video flex items-center justify-center"*/}
            {/*            >*/}
            {/*              {info.type.startsWith("video") ? (*/}
            {/*                <>*/}
            {/*                  <video*/}
            {/*                    src={`${process.env.REACT_APP_IMAGE_URL}${info.path}`}*/}
            {/*                    className="h-full aspect-video rounded-lg"*/}
            {/*                    autoPlay={false}*/}
            {/*                    muted*/}
            {/*                    loop*/}
            {/*                  ></video>*/}
            {/*                  <p className="absolute bottom-0 left-0 text-xs bg-gray-600 text-white p-1 w-full text-center rounded-b-lg">*/}
            {/*                    {info.path.split("/").pop()}*/}
            {/*                  </p>*/}
            {/*                </>*/}
            {/*              ) : (*/}
            {/*                <img*/}
            {/*                  src={`${process.env.REACT_APP_IMAGE_URL}${info.path}`}*/}
            {/*                  alt="preview"*/}
            {/*                  className="h-full aspect-video rounded-lg"*/}
            {/*                />*/}
            {/*              )}*/}
            {/*            </div>*/}
            {/*          ))}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <Input*/}
            {/*      type={"div"}*/}
            {/*      label={"세부위치"}*/}
            {/*      value={*/}
            {/*        userReportDetailSuccess && useReportDetailData.data.point*/}
            {/*      }*/}
            {/*      className={"!bg-lightGray h-14 border-t-0 border-b-1"}*/}
            {/*    />*/}
            {/*    <div className="!bg-lightGray h-full border border-gray-300 border-t-0 border-b-1 p-4 lg:px-10">*/}
            {/*      <p className="font-semibold h-14 lg:text-lg text-gray-600">*/}
            {/*        위험 요인*/}
            {/*      </p>*/}

            {/*      <div className="w-full flex items-center justify-start h-48 rounded-md overflow-x-auto overflow-y-hidden space-x-2">*/}
            {/*        {useReportDetailData &&*/}
            {/*          useReportDetailData.data &&*/}
            {/*          useReportDetailData.data.riskFiles.map(*/}
            {/*            (info: SavedFileInfo, index: number) => (*/}
            {/*              <div*/}
            {/*                key={index}*/}
            {/*                className="relative h-32 aspect-video flex items-center justify-center"*/}
            {/*              >*/}
            {/*                {info.type.startsWith("video") ? (*/}
            {/*                  <>*/}
            {/*                    <video*/}
            {/*                      src={`${process.env.REACT_APP_IMAGE_URL}${info.path}`}*/}
            {/*                      className="h-full aspect-video rounded-lg"*/}
            {/*                      autoPlay={false}*/}
            {/*                      muted*/}
            {/*                      loop*/}
            {/*                    ></video>*/}
            {/*                    <p className="absolute bottom-0 left-0 text-xs bg-gray-600 text-white p-1 w-full text-center rounded-b-lg">*/}
            {/*                      {info.path.split("/").pop()}*/}
            {/*                    </p>*/}
            {/*                  </>*/}
            {/*                ) : (*/}
            {/*                  <img*/}
            {/*                    src={`${process.env.REACT_APP_IMAGE_URL}${info.path}`}*/}
            {/*                    alt="preview"*/}
            {/*                    className="h-full aspect-video rounded-lg"*/}
            {/*                  />*/}
            {/*                )}*/}
            {/*              </div>*/}
            {/*            ),*/}
            {/*          )}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <Input*/}
            {/*      type={"div"}*/}
            {/*      label={"위험지수"}*/}
            {/*      value={*/}
            {/*        useReportDetailData && useReportDetailData.data.riskIndex*/}
            {/*      }*/}
            {/*      className={"!bg-lightGray h-14 border-t-0 border-b-1"}*/}
            {/*    />*/}
            {/*    <Input*/}
            {/*      type={"div"}*/}
            {/*      label={"재해유형"}*/}
            {/*      value={*/}
            {/*        useReportDetailData && useReportDetailData.data.disasterType*/}
            {/*      }*/}
            {/*      className={"!bg-lightGray h-14 border-t-0 border-b-1"}*/}
            {/*    />*/}
            {/*    <Input*/}
            {/*      type={"div"}*/}
            {/*      label={"위험요인"}*/}
            {/*      value={*/}
            {/*        useReportDetailData && useReportDetailData.data.riskFactors*/}
            {/*      }*/}
            {/*      className={"!bg-lightGray h-14 border-t-0 border-b-1"}*/}
            {/*    />*/}
            {/*  </>*/}
            {/*) : (*/}
            <>
              <div className=" h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">
                <p className="font-semibold pb-3">작업현장</p>
                <CreateFile
                  name={"files"}
                  register={register("files")}
                  watch={watch}
                  errorMessage={errors.files?.message}
                  resetField={resetField}
                  setValue={setValue}
                  setSelectedFiles={setSelectedFiles}
                />
              </div>
              <Input
                type={"text"}
                label={"세부위치"}
                placeholder={"예) B21-CORE 2 개구부"}
                required={true}
                className={"h-14 border-t-0 border-b-1"}
                register={register("point", {
                  required: true,
                })}
              />
              <div className="flex items-center h-14 border border-gray-300 border-t-0 border-b-1 px-4 bg-white">
                <span className="font-semibold">위험여부</span>
                <div className="ml-14 space-x-3">
                  <label htmlFor="danger" className="font-medium">
                    위험
                  </label>
                  <input
                    id="danger"
                    type="radio"
                    value={REPORT_TYPE.DANGER}
                    checked={reportType === REPORT_TYPE.DANGER}
                    onChange={(event) => handleChangeType(event)}
                  />
                  <label htmlFor="safe" className="font-medium">
                    안전
                  </label>
                  <input
                    id="safe"
                    type="radio"
                    value={REPORT_TYPE.SAFE}
                    checked={reportType === REPORT_TYPE.SAFE}
                    onChange={(event) => handleChangeType(event)}
                  />
                </div>
              </div>
            </>
          </>
          {reportType === REPORT_TYPE.DANGER && (
            <>
              <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">
                <p className="font-semibold pb-3">위험요인</p>
                <CreateFile
                  name={"riskFiles"}
                  register={register("riskFiles")}
                  watch={watch}
                  errorMessage={errors.riskFiles?.message}
                  setValue={setValue}
                  setSelectedFiles={setSelectedRiskFiles}
                />
              </div>
              <Input
                type={"select"}
                label={"위험지수"}
                options={[
                  RISK_INDEX.URGENT,
                  RISK_INDEX.PRIORITY,
                  RISK_INDEX.REVIEW,
                ]}
                required={true}
                className={"h-14 border-t-0 border-b-1 lg:text-lg"}
                register={register("riskIndex", { required: true })}
              />
              <Input
                type={"text"}
                label={"재해유형"}
                placeholder={"예) 추락,낙하,감전 등"}
                required={true}
                className={"h-14 border-t-0 border-b-1"}
                maxLength={5}
                register={register("disasterType", {
                  required: true,
                  maxLength: 5,
                })}
              />

              <Input
                type={"text"}
                label={"위험요인"}
                placeholder={"15자 이내로 작성해주세요"}
                required={true}
                maxLength={15}
                className={cls("h-14 border-t-0 border-b-1")}
                register={register("riskFactors", {
                  required: true,
                  maxLength: 15,
                })}
              />
            </>
          )}
          {reportType === REPORT_TYPE.SAFE && (
            <div className="flex flex-col py-4 px-4 h-36 border border-gray-300 border-t-0 border-b-1 rounded-b-lg">
              <p className="pb-2 font-semibold w-full flex-nowrap">점검내용</p>
              <textarea
                className="grow font-medium outline-none bg-gray-100 rounded-lg resize-none p-4"
                cols={14}
                {...register("content", { required: true })}
              ></textarea>
            </div>
          )}
          {/*{reportType === REPORT_TYPE.IMPROVED && (*/}
          {/*  <>*/}
          {/*    <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">*/}
          {/*      <p className="font-semibold pb-3">개선 사항</p>*/}
          {/*      <CreateFile*/}
          {/*        name={"improvedFiles"}*/}
          {/*        register={register("improvedFiles")}*/}
          {/*        watch={watch}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <Input*/}
          {/*      type={"text"}*/}
          {/*      label={"개선사항"}*/}
          {/*      placeholder={"15자 이내로 작성해주세요"}*/}
          {/*      required={true}*/}
          {/*      maxLength={15}*/}
          {/*      className={"h-14 border-t-0 border-b-1 rounded-b-lg"}*/}
          {/*      register={register("improvedContent", {*/}
          {/*        required: true,*/}
          {/*      })}*/}
          {/*    />*/}
          {/*  </>*/}
          {/*)}*/}
          <BasicButton
            text={reportriskLoading || reportSafetyLoading ? "등록중" : "등록"}
            size={"lg"}
            type={"submit"}
            disabled={reportriskLoading || reportSafetyLoading}
          />
        </form>
      </Layout>
    </div>
  );
};

export default Report;
