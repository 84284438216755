import React, { useState, useEffect } from "react";
import Layout from "../../../component/layout";
import Input from "../../../component/input";
import { cls } from "../../../libs/utils";
import BasicButton from "../../../component/basicButton";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { calendarFns } from "../../../utils/calendarFns";
import CreateFile, { SavedFileInfo } from "../../../component/createFile";
import { setSelectedTaskId } from "../../../store/features/task/taskSlice";
import { useQuery } from "@tanstack/react-query";
import { UserDataApi } from "../../../api/UserDataApi";
import { getCookie } from "../../../libs/cookies";
import { ITaskData } from "../../admin/task/register";
import { format } from "date-fns";
import { REPORT_TYPE, RISK_INDEX, TASK_STATE } from "../../../libs/constants";
import { useMutation } from "@tanstack/react-query";
import { CommonDataApi } from "../../../api/CommonDataApi";
import { IJoinData } from "../join";
import { Simulate } from "react-dom/test-utils";
import error = Simulate.error;
import {
  setMediaModalOpen,
  setModalOpen,
} from "../../../store/features/modalSlice";
import { IReportData } from "./report";
import Preview from "../../../component/preview";
import { RootState } from "../../../store/store";
import MediaModal, { IMediaModal } from "../../../component/mediaModal";
import { useDispatch, useSelector } from "react-redux";
interface IRes {
  projectId: string;
  name: string;
  location: string;
  riskIndex: string;
  disasterType: string;
  riskFactors: string;
  riskFiles: SavedFileInfo[];
}

const Report = () => {
  const location = useLocation();

  const { taskId } = location.state || {};
  const dispatch = useDispatch();
  const projectId = getCookie("projectId");
  const [selectedRiskFiles, setSelectedRiskFiles] = useState<File[]>([]);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm<IReportData>();
  const [params, setParams] = useState<IReportData>({});
  const [isDetail, setIsDetail] = useState(false);
  const [res, setRes] = useState<IRes>({
    projectId: "",
    name: "",
    location: "",
    riskIndex: "",
    disasterType: "",
    riskFactors: "",
    riskFiles: [],
  });
  const { mutate: reportExtraRisk, isPending: reportExtraRiskPending } =
    useMutation<string, string, IReportData>({
      mutationFn: (params: IReportData) => UserDataApi.reportExtraRisk(params),
      onSuccess: (data: any) => {
        console.log(data);
        if (data && data.code === "OK") {
          setIsDetail(true);
          setRes({
            projectId: data.data.projectId,
            name: data.data.name,
            location: data.data.location,
            riskIndex: data.data.riskIndex,
            disasterType: data.data.disasterType,
            riskFactors: data.data.riskFactors,
            riskFiles: data.data.riskFiles,
          });
        } else {
          return;
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  // useEffect(() => {
  //   reportExtraRisk(params);
  // }, [params]);

  const onValid = (data: IReportData) => {
    data.projectId = projectId;

    console.log(data);
    if (selectedRiskFiles.length === 0) {
      setError("riskFiles", {
        message: "위험요소 사진을 한 장 이상 첨부해주세요",
      });
    }
    data.riskFiles = selectedRiskFiles;
    console.log(data);
    reportExtraRisk(data);
    // setParams({
    //   ...data,
    // });
  };

  const initialMediaModal = {
    path: "",
    title: "",
    type: "image" as "image",
  };
  const mediaModalOpen = useSelector(
    (state: RootState) => state.modal.mediaModalOpen,
  );
  const [media, setMedia] = useState<IMediaModal>(initialMediaModal);
  const handleClickMedia = (
    path: string,
    title: string,
    type: "video" | "image",
  ) => {
    setMedia((prev) => ({
      ...prev,
      path,
      title,
      type,
    }));
    dispatch(setMediaModalOpen(true));
  };

  return (
    <div className="px-3 my-3 *:text-gray-600">
      <Layout
        title={"추가 위험 보고서"}
        canGoBack={true}
        hasTabBar={false}
        hasTopBar={true}
      >
        {mediaModalOpen && <MediaModal props={media} />}
        <form onSubmit={handleSubmit(onValid)}>
          <Input
            type={isDetail ? "div" : "text"}
            label={"제목"}
            placeholder={"예) 갱폼설치 안전망 설치"}
            required={true}
            className={"h-14 border-t-1 rounded-t-lg"}
            maxLength={15}
            value={res.name}
            register={register("name", {
              required: true,
              maxLength: 15,
            })}
          />
          <Input
            type={isDetail ? "div" : "text"}
            label={"위치"}
            placeholder={"예) B21-CORE 2"}
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            maxLength={15}
            value={res.location}
            register={register("location", {
              required: true,
              maxLength: 15,
            })}
          />

          {isDetail ? (
            <>
              <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white lg:px-10">
                <p className="font-semibold h-14 lg:text-lg text-gray-600">
                  위험 요인
                </p>

                <div className="w-full flex items-center justify-start h-48 rounded-md overflow-x-auto overflow-y-hidden space-x-2">
                  {res &&
                    res.riskFiles &&
                    res?.riskFiles.map((info, index) => (
                      // <img
                      //   src={`${process.env.REACT_APP_IMAGE_URL}${info.path}`}
                      //   alt="preview"
                      //   className="h-full aspect-video rounded-lg"
                      // />
                      <video
                        src={`${process.env.REACT_APP_IMAGE_URL}${info.path}`}
                        className="h-full aspect-video rounded-lg"
                        autoPlay={false}
                        muted
                        loop
                      ></video>
                      // <Preview
                      //   key={`preview_${index}`}
                      //   info={info}
                      //   index={index}
                      //   onClick={() =>
                      //     handleClickMedia(
                      //       info.path,
                      //       `${res.name}_위험요인`,
                      //       info.type,
                      //     )
                      //   }
                      // />
                    ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">
                <p className="font-semibold pb-3">위험 요인</p>
                <CreateFile
                  name={"riskFiles"}
                  register={register("riskFiles")}
                  watch={watch}
                  errorMessage={errors.riskFiles?.message}
                  setValue={setValue}
                  setSelectedFiles={setSelectedRiskFiles}
                  onlyVideo={true}
                />
              </div>
            </>
          )}
          <Input
            type={isDetail ? "div" : "select"}
            label={"위험지수"}
            value={res.riskIndex}
            options={[
              RISK_INDEX.URGENT,
              RISK_INDEX.PRIORITY,
              RISK_INDEX.REVIEW,
            ]}
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            maxLength={5}
            register={register("riskIndex", { required: true })}
          />
          <Input
            type={isDetail ? "div" : "text"}
            label={"재해유형"}
            value={res.disasterType}
            placeholder={"예) 추락,낙하,감전 등"}
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            maxLength={5}
            register={register("disasterType", {
              required: true,
              maxLength: 5,
            })}
          />

          <Input
            type={isDetail ? "div" : "text"}
            label={"위험요인"}
            placeholder={"15자 이내로 작성해주세요"}
            required={true}
            maxLength={15}
            value={res.riskFactors}
            className={cls("h-14 border-t-0 border-b-1 rounded-b-lg")}
            register={register("riskFactors", {
              required: true,
              maxLength: 15,
            })}
          />

          <BasicButton
            text={reportExtraRiskPending ? "등록중" : "등록"}
            size={"lg"}
            type={"submit"}
            disabled={reportExtraRiskPending}
          />
        </form>
      </Layout>
    </div>
  );
};

export default Report;
