import React from "react";
import Tag from "../../../component/tag";
import { useNavigate } from "react-router-dom";
import Layout from "../../../component/admin/layout";
import Input from "../../../component/input";
import { ReactComponent as IcFinder } from "../../../asset/icon/ic_finder.svg";
const List = () => {
  const navigate = useNavigate();
  return (
    <Layout
      title={"유니슨 캠"}
      hasTopBar={true}
      hasTabBar={true}
      canGoBack={true}
    >
      <p className="text-2xl lg:text-3xl font-semibold mt-3">
        양지윤 <span className="text-blue-500 font-bold">유니슨캠</span> 영상
      </p>
      <div className="my-4 flex justify-between items-center">
        <div className="lg:w-1/3">
          <Input
            type="text"
            placeholder="등록자명, 협력업체명, 공사종류, 재해유형"
            icon={
              <span className="text-gray-300">
                <IcFinder />
              </span>
            }
            className="rounded-xl mx-auto border-0 h-12 bg-lightGray"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-2 mt-6">
        <div
          className="w-full bg-white shadow py-4 px-6 rounded hover:shadow-xl cursor-pointer transition-all"
          onClick={() => navigate("/admin/uniscam/video/1")}
        >
          <iframe
            className="w-full aspect-video rounded"
            src="https://www.youtube.com/embed/BOAOs2vDZBI"
            title="사고사례 갱폼 인양 와이어파단"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p className="font-semibold text-lg pt-2">B21 철근가공 절단작업</p>
          <div className="flex flex-col text-left">
            <span className="text-sm">2024.01.23.09:00 - 2024.01.23.17:00</span>
          </div>
        </div>
        <div
          className="w-full bg-white shadow py-4 px-6 rounded hover:shadow-xl cursor-pointer transition-all"
          onClick={() => navigate("/admin/uniscam/video/1")}
        >
          <iframe
            className="w-full aspect-video rounded"
            src="https://www.youtube.com/embed/BOAOs2vDZBI"
            title="사고사례 갱폼 인양 와이어파단"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p className="font-semibold text-lg pt-2">B21 철근가공 절단작업</p>
          <div className="flex flex-col text-left">
            <span className="text-sm">2024.01.23.09:00 - 2024.01.23.17:00</span>
          </div>
        </div>
        <div
          className="w-full bg-white shadow py-4 px-6 rounded hover:shadow-xl cursor-pointer transition-all"
          onClick={() => navigate("/admin/uniscam/video/1")}
        >
          <iframe
            className="w-full aspect-video rounded"
            src="https://www.youtube.com/embed/BOAOs2vDZBI"
            title="사고사례 갱폼 인양 와이어파단"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p className="font-semibold text-lg pt-2">B21 철근가공 절단작업</p>
          <div className="flex flex-col text-left">
            <span className="text-sm">2024.01.23.09:00 - 2024.01.23.17:00</span>
          </div>
        </div>
        <div
          className="w-full bg-white shadow py-4 px-6 rounded hover:shadow-xl cursor-pointer transition-all"
          onClick={() => navigate("/admin/uniscam/video/1")}
        >
          <iframe
            className="w-full aspect-video rounded"
            src="https://www.youtube.com/embed/BOAOs2vDZBI"
            title="사고사례 갱폼 인양 와이어파단"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p className="font-semibold text-lg pt-2">B21 철근가공 절단작업</p>
          <div className="flex flex-col text-left">
            <span className="text-sm">2024.01.23.09:00 - 2024.01.23.17:00</span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default List;
