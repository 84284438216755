import React from "react";
import Login from "../page/user/Login";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "../page/user/home";
import Task from "../page/user/task";
import Report from "../page/user/report/report";
import Reports from "../page/user/report/reports";
import Account from "../page/user/account";
import Join from "../page/user/join";
import Detail from "../page/user/report/detail";

import AdminHome from "../page/admin/home";
import ProjectList from "../page/admin/project/list";
import ProjectRegister from "../page/admin/project/register";
import ProjectDetail from "../page/admin/project/detail";
import TaskList from "../page/admin/task/list";
import TaskRegister from "../page/admin/task/register";
import TaskDetail from "../page/admin/task/detail";
import ReportList from "../page/admin/report/list";
import ReportDetail from "../page/admin/report/detail";
import VideoList from "../page/admin/video/list";
import VideoDetail from "../page/admin/video/detail";
import MemberList from "../page/admin/member/list";
import MemberDetail from "../page/admin/member/detail";
import UnisCamMain from "../page/admin/uniscam/main";
import UnisCamDetail from "../page/admin/uniscam/detail";
import UnisCamList from "../page/admin/uniscam/list";
import AdminAccount from "../page/admin/account";

import { getCookie } from "../libs/cookies";
import Participation from "../page/user/Participation";
import ExtraReport from "../page/user/report/extraReport";

function getUserRole() {
  return getCookie("auth");
}

const AdminRoute = ({ element }: { element: React.ReactElement }) => {
  const userRole = getUserRole();
  // ROLE_USER가 아니라면 해당 element를 렌더링, 그렇지 않다면 홈으로 리다이렉트
  return userRole !== "ROLE_USER" ? element : <Navigate to="/" replace />;
};

const Router = createBrowserRouter([
  // 공통
  { path: "/", element: <Login /> },
  { path: "/join", element: <Join /> },
  // 안전 감시단
  { path: "/participation", element: <Participation /> },
  { path: "/home", element: <Home /> },
  { path: "/task/:id", element: <Task /> },
  { path: "/report", element: <Report /> },
  { path: "/detail", element: <Detail /> },
  { path: "/reports", element: <Reports /> },
  { path: "/account", element: <Account /> },
  { path: "/extraReport", element: <ExtraReport /> },
  // 안전 관리자
  // { path: "/admin/home", element: <AdminRoute element={<AdminHome />} /> },
  // { path: "/admin", element: <AdminRoute element={<AdminHome />} /> },
  // { path: "/admin/project", element: <AdminRoute element={<ProjectList />} /> },
  // { path: "/admin/project/register", element: <ProjectRegister /> },
  // { path: "/admin/project/:id", element: <ProjectDetail /> },
  // { path: "/admin/task", element: <TaskList /> },
  // // { path: "/admin/task/register", element: <TaskRegister /> },
  // // { path: "/admin/task/:id", element: <TaskDetail /> },
  // { path: "/admin/report", element: <ReportList /> },
  // { path: "/admin/report/:id", element: <ReportDetail /> },
  // { path: "/admin/video", element: <VideoList /> },
  // { path: "/admin/video/:id", element: <VideoDetail /> },
  // { path: "/admin/member", element: <MemberList /> },
  // { path: "/admin/member/:id", element: <MemberDetail /> },
  // { path: "/admin/uniscam", element: <UnisCamMain /> },
  // { path: "/admin/uniscam/video/:id", element: <UnisCamDetail /> },
  // { path: "/admin/uniscam/list/:name", element: <UnisCamList /> },
  // { path: "/admin/account", element: <AdminAccount /> },
  { path: "/admin/home", element: <AdminRoute element={<AdminHome />} /> },
  { path: "/admin", element: <AdminRoute element={<AdminHome />} /> },
  { path: "/admin/project", element: <AdminRoute element={<ProjectList />} /> },
  {
    path: "/admin/project/register",
    element: <AdminRoute element={<ProjectRegister />} />,
  },
  {
    path: "/admin/project/:id",
    element: <AdminRoute element={<ProjectDetail />} />,
  },
  { path: "/admin/task", element: <AdminRoute element={<TaskList />} /> },
  {
    path: "/admin/task/register",
    element: <AdminRoute element={<TaskRegister />} />,
  },
  { path: "/admin/report", element: <AdminRoute element={<ReportList />} /> },
  {
    path: "/admin/report/:id",
    element: <AdminRoute element={<ReportDetail />} />,
  },
  { path: "/admin/video", element: <AdminRoute element={<VideoList />} /> },
  {
    path: "/admin/video/:id",
    element: <AdminRoute element={<VideoDetail />} />,
  },
  { path: "/admin/member", element: <AdminRoute element={<MemberList />} /> },
  {
    path: "/admin/member/:id",
    element: <AdminRoute element={<MemberDetail />} />,
  },
  { path: "/admin/uniscam", element: <AdminRoute element={<UnisCamMain />} /> },
  {
    path: "/admin/uniscam/video/:id",
    element: <AdminRoute element={<UnisCamDetail />} />,
  },
  {
    path: "/admin/uniscam/list/:name",
    element: <AdminRoute element={<UnisCamList />} />,
  },
  {
    path: "/admin/account",
    element: <AdminRoute element={<AdminAccount />} />,
  },
]);

export default Router;
