import React, { useEffect, useState } from "react";
import {
  UseFormRegisterReturn,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { cls } from "../libs/utils";

export interface SavedFileInfo {
  id: number;
  path: string;
  type: "video" | "image";
}

export interface FileInfo {
  id?: number;
  url: string;
  name: string;
  type: string;
  isSaved: boolean;
  lastModified?: number;
}

interface FileProps {
  name: string;
  register?: UseFormRegisterReturn;
  className?: string;
  watch?: UseFormWatch<any>;
  errorMessage?: string;
  isJustShow?: boolean;
  savedFiles?: SavedFileInfo[];
  savedRiskFiles?: SavedFileInfo[];
  savedImprovedFiles?: SavedFileInfo[];
  setDeleteFileIds?: React.Dispatch<React.SetStateAction<number[]>>;
  setDeleteFilePaths?: React.Dispatch<React.SetStateAction<string[]>>;
  resetField?: UseFormResetField<any>;
  setValue?: UseFormSetValue<any>;
  setSelectedFiles?: React.Dispatch<React.SetStateAction<File[]>>;
  onlyVideo?: boolean;
}

const CreateFile = ({
  register,
  className,
  watch,
  name,
  errorMessage,
  savedFiles,
  savedRiskFiles,
  savedImprovedFiles,
  setDeleteFileIds,
  setDeleteFilePaths,
  resetField,
  setValue,
  setSelectedFiles,
  onlyVideo = false,
}: FileProps) => {
  const [fileInfos, setFileInfos] = useState<FileInfo[]>([]);
  const files = watch && watch(name);

  useEffect(() => {
    resetField && resetField(name);
    setSelectedFiles && setSelectedFiles([]);
    setDeleteFileIds && setDeleteFileIds([]);
    setDeleteFilePaths && setDeleteFilePaths([]);
  }, []);

  useEffect(() => {
    if (files && files.length > 0) {
      const fileArray: FileList = files;
      const newFileInfos = Array.from(fileArray).map((file) => ({
        url: URL.createObjectURL(file),
        name: file.name,
        type: file.type,
        isSaved: false,
        lastModified: file.lastModified,
      }));

      // const uniqueNewFileInfos = newFileInfos.filter(
      //   (newFileInfo) =>
      //     !fileInfos.some(
      //       (existingFileInfo) =>
      //         existingFileInfo.name === newFileInfo.name &&
      //         existingFileInfo.lastModified === newFileInfo.lastModified,
      //     ),
      // );
      //
      // if (uniqueNewFileInfos.length > 0) {
      setFileInfos((prev) => [...prev, ...newFileInfos]);
      setSelectedFiles && setSelectedFiles((prev) => [...prev, ...files]);
      // }
    }
  }, [files]);

  useEffect(() => {
    // console.log("savedFiles", savedFiles);
    if (savedFiles && savedFiles.length > 0) {
      const savedFileInfos = savedFiles.map((file) => ({
        id: file.id,
        url: file.path,
        name: file.path.split("/").pop() ?? "이름없음",
        type: file.type,
        isSaved: true,
      }));
      setFileInfos(savedFileInfos);
    } else {
      setFileInfos([]);
    }
  }, [savedFiles]);

  const handleRemovePreview = (event: React.MouseEvent, index: number) => {
    // console.log("삭제될 인덱스", index);

    event.preventDefault();
    const fileToRemove = fileInfos[index];

    if (fileToRemove.isSaved && fileToRemove.id) {
      setDeleteFileIds?.((prev) => [...prev, fileToRemove.id!!]);
      setDeleteFilePaths?.((prev) => [...prev, fileToRemove.url]);
    }
    setSelectedFiles &&
      setSelectedFiles((currentFiles) =>
        currentFiles.filter((_, i) => i !== index),
      );
    const newFileInfos = fileInfos.filter((_, i) => i !== index);
    setFileInfos(newFileInfos);
    //
    // if (!fileInfos[index].isSaved) {
    //   if (!(files instanceof FileList)) {
    //     console.error('Expected "files" to be a FileList but got:', files);
    //     return;
    //   }
    //
    //   const fileListArray = Array.from(files);
    // //   console.log("fileListArray", fileListArray);
    //   // 추가
    //   if (savedFiles) {
    //     index = index - savedFiles.length;
    // //     console.log("진짜 삭제될 인덱스", index);
    // //     console.log("진짜 삭제될 인덱스 savedFiles.length", savedFiles.length);
    //   }
    //   const updatedFilesArray = fileListArray.filter((_, i) => i !== index);
    //
    //   const dataTransfer = new DataTransfer();
    //   updatedFilesArray.forEach((file) => {
    //     dataTransfer.items.add(file);
    //   });
    //
    // //   console.log("dataTransfer.files", dataTransfer.files);
    //   setValue && setValue(name, dataTransfer.files);
    // }
  };

  return (
    <div>
      <div className="w-full flex items-center justify-start h-48 rounded-md overflow-x-auto overflow-y-hidden space-x-2">
        <label
          className={cls(
            "transition-all flex justify-center items-center border-2 min-h-32 h-32 min-w-32 w-32 rounded-lg  hover:border-blue-500 hover:text-blue-500 cursor-pointer",
            errorMessage
              ? " border-rose-300 text-rose-400"
              : " border-gray-300 text-gray-400",
          )}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-10 h-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <input
            className="hidden"
            accept={`video/*${onlyVideo ? "" : ", image/*"}`}
            type="file"
            multiple
            {...register}
          />
        </label>
        {fileInfos.map((info, index) => (
          <div
            key={index}
            className="relative h-32 aspect-video flex items-center justify-center"
          >
            {info.type.startsWith("video") ? (
              <>
                <video
                  src={
                    info.isSaved
                      ? `${process.env.REACT_APP_IMAGE_URL}${info.url}`
                      : info.url
                  }
                  className="h-full aspect-video rounded-lg"
                  autoPlay={false}
                  muted
                  loop
                ></video>
                <p className="absolute bottom-0 left-0 text-xs bg-gray-600 text-white p-1 w-full text-center rounded-b-lg">
                  {info.name}
                </p>
              </>
            ) : (
              <img
                src={
                  info.isSaved
                    ? `${process.env.REACT_APP_IMAGE_URL}${info.url}`
                    : info.url
                }
                alt="preview"
                className="h-full object-cover rounded-lg"
              />
            )}

            <button
              type={"button"}
              onClick={(event) => handleRemovePreview(event, index)}
              className="absolute top-0 right-0 text-blue-500 rounded-full shadow"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
      {errorMessage && (
        <p className="text-rose-500 text-center font-semibold">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default CreateFile;
