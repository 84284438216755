import { configureStore } from "@reduxjs/toolkit";
import calendarSlice from "./features/calendar/calendarSlice";
import modalSlice from "./features/modalSlice";
import taskSlice from "./features/task/taskSlice";
import pageSlice from "./features/pageSlice";
const store = configureStore({
  reducer: {
    calendar: calendarSlice,
    modal: modalSlice,
    task: taskSlice,
    page: pageSlice,
  },
});
export default store;
export type RootState = ReturnType<typeof store.getState>;
