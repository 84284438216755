import React, { useEffect, useState } from "react";
import Layout from "../../../component/admin/layout";
import Web from "./list/web";
import Mobile from "./list/moblie";
import { useQuery } from "@tanstack/react-query";
import { AdminDataApi } from "../../../api/AdminDataApi";
import { getCookie } from "../../../libs/cookies";
import { IReportListParam } from "../../user/report/reports";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const List = () => {
  return (
    <Layout
      title={"안전 보고"}
      hasTabBar={true}
      canGoBack={true}
      hasTopBar={true}
    >
      <Web />
      <Mobile />
    </Layout>
  );
};

export default List;
