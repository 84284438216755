import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "../../../component/input";
import Layout from "../../../component/admin/layout";

import { useDispatch } from "react-redux";
import { setModalOpen } from "../../../store/features/modalSlice";
import { format, isAfter, parse, parseISO } from "date-fns";
import { renderButton } from "../../../component/statusButton";
import { useMutation } from "@tanstack/react-query";
import { LoginApi } from "../../../api/LoginApi";
import { ROLE } from "../../../libs/constants";
import { ILoginData } from "../../user/Login";
import { AdminDataApi } from "../../../api/AdminDataApi";
import CalendarContext from "../../../utils/CalendarRefContext";
import { getSafetyTypeClass, taskStateCalendarBg } from "../../../libs/utils";
import { getCookie } from "../../../libs/cookies";

export interface ITaskData {
  id: number;
  taskId: number;
  projectId: string;
  reportId?: number;
  name: string;
  taskName?: string;
  coworkerName: string;
  managerName: string;
  date?: string;
  startTime: string;
  endTime: string;
  location: string;
  safetyType: string;
  majorCategory: string;
  middleCategory: string;
  subcontractorName: string;
  workerNum: string;
  state: string;
  reportType?: string;
}

export interface RegisterEventProps {
  start?: string;
  end?: string;
}
const Register = ({
  registerEvent,
  getTaskRefetch,
}: {
  registerEvent?: RegisterEventProps;
  getTaskRefetch?: () => void;
}) => {
  const today = new Date();
  const location = useLocation();
  const dispatch = useDispatch();

  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const calendarRef = useContext(CalendarContext);
  const registerTask = useMutation<string, string, ITaskData>({
    mutationFn: (param: ITaskData) => AdminDataApi.registerTask(param),
    onSuccess: (data: any) => {
      if (data && data !== "error") {
        // console.log("등록 성공~~! ", data);
        // console.log("calendarRef", calendarRef);
        const eventData = {
          id: data.data.id,
          title: data.data.safetyType,
          start: `${data.data.date}T${data.data.startTime}`,
          end: `${data.data.date}T${data.data.endTime}`,
          className: taskStateCalendarBg(data.data.state),
        };
        calendarRef.calendarRef.current.current.getApi().addEvent(eventData);
        dispatch(setModalOpen(false));
        getTaskRefetch && getTaskRefetch();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue,
  } = useForm<ITaskData>({
    defaultValues: {
      date: date,
    },
  });

  const watchDate = watch("date");
  useEffect(() => {
    console.log("watchDate", watchDate);
  }, [watchDate]);

  const onValid = (data: ITaskData) => {
    const projectId = getCookie("projectId");
    const tmpStartDate = parseISO(`2022-01-01T${data.startTime}:00`);
    const tmpEndDate = parseISO(`2022-01-01T${data.endTime}:00`);

    if (!isAfter(tmpEndDate, tmpStartDate)) {
      setError("endTime", { message: "종료시간을 올바르게 작성해주세요" });
    }

    let parseDate;
    if (data?.date) {
      parseDate = parse(data.date, "yyyy년 MM월 dd일", new Date());
    } else {
      parseDate = parse(date, "yyyy년 MM월 dd일", new Date());
    }
    const formattedDate = format(parseDate, "yyyy-MM-dd");
    data.date = formattedDate;
    data.startTime += ":00";
    data.endTime += ":00";
    data.projectId = projectId;

    if (data.safetyType === "선택")
      setError("safetyType", {
        message: "안전감시유형을 올바르게 선택해주세요.",
      });
    console.log(data);
    registerTask.mutate(data);
  };

  useEffect(() => {
    console.log(registerEvent);
    if (registerEvent && registerEvent.start && registerEvent.end) {
      setDate(format(registerEvent?.start, "yyyy년 MM월 dd일"));
      setStartTime(format(registerEvent?.start, "HH:mm"));
      setEndTime(format(registerEvent?.end, "HH:mm"));
      setValue("startTime", format(registerEvent?.start, "HH:mm"));
      setValue("endTime", format(registerEvent?.end, "HH:mm"));
    }
  }, [registerEvent]);

  useEffect(() => {
    setValue("date", date);
  }, [date]);

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <div>
        <Input
          type={"text"}
          label={"업무명"}
          placeholder={"갱폼설치 안전망설치"}
          className=" h-16 lg:text-lg border-b-0 rounded-t-lg"
          register={register("name", { required: true })}
        />
        <Input
          type={"text"}
          label={"날짜"}
          placeholder={date}
          className=" h-16 lg:text-lg border-b-0"
          register={register("date", {
            required: true,
            pattern: {
              value:
                /(19|20)\d{2}년 (0[1-9]|1[0-2])월 (0[1-9]|[12][0-9]|3[01])/,
              message: "날짜를 올바르게 입력해주세요",
            },
            maxLength: 13,
          })}
          maxLength={13}
          errorMessage={errors?.date?.message}
        />
        <Input
          type={"text"}
          label={"시작시간"}
          value={startTime}
          placeholder={startTime}
          className=" h-16 lg:text-lg border-b-0"
          register={register("startTime", {
            required: true,
            pattern: {
              value: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
              message: "시작시간을 올바르게 입력해주세요",
            },
            maxLength: 5,
          })}
          maxLength={5}
          errorMessage={errors?.startTime?.message}
        />
        <Input
          type={"text"}
          label={"종료시간"}
          value={endTime}
          placeholder={endTime}
          className=" h-16 lg:text-lg border-b-0"
          register={register("endTime", {
            required: true,
            pattern: {
              value: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/,
              message: "종료시간을 올바르게 입력해주세요",
            },
            maxLength: 5,
          })}
          errorMessage={errors?.endTime?.message}
          maxLength={5}
        />
        <Input
          type={"text"}
          label={"위치"}
          placeholder={"B21구역 14F"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("location", { required: true })}
        />
        <Input
          type={"text"}
          label={"동행자"}
          placeholder={"홍 아무개"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("coworkerName", { required: true })}
        />
        <Input
          type={"select"}
          label={"안전감시유형"}
          options={["선택", "고위험작업", "안전순찰", "장비점검"]}
          className=" h-16 lg:text-lg border-b-0"
          register={register("safetyType", { required: true })}
        />
        <Input
          type={"text"}
          label={"작업명"}
          placeholder={"철근 가공"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("majorCategory", { required: true })}
        />
        <Input
          type={"text"}
          label={"단위작업명"}
          placeholder={"절단 작업"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("middleCategory", { required: true })}
        />
        <Input
          type={"text"}
          label={"협력업체명"}
          placeholder={"이룸건설"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("subcontractorName", { required: true })}
        />
        <Input
          type={"text"}
          label={"협력직원수"}
          placeholder={"5"}
          className=" h-16 lg:text-lg border-b-0"
          register={register("workerNum", {
            required: true,
            pattern: {
              value: /^\d+$/,
              message: "협력직원수를 올바르게 입력해주세요",
            },
            maxLength: 3,
          })}
          maxLength={3}
          errorMessage={errors.workerNum?.message}
        />
        <Input
          type={"text"}
          label={"담당관리자"}
          placeholder={"조 아무개"}
          className=" h-16 lg:text-lg border-b-1"
          register={register("managerName", { required: true })}
        />
        <div className="border-t-0 rounded-b-lg flex space-x-10 justify-between items-center w-full border border-gray-300 bg-white py-4 px-3 lg:px-10">
          {renderButton({
            text: "취소",
            bgColor: "bg-lightGray",
            hoverBgColor: "hover:bg-lightGray/50",
            activeBgColor: "active:bg-gray-100",
            textColor: "text-gray-600",
            onClick: () =>
              setTimeout(() => {
                dispatch(setModalOpen(false));
              }, 200),
          })}
          <div className="flex space-x-4">
            {renderButton({
              text: "등록",
              type: "submit",
              bgColor: "bg-blue-500/30",
              hoverBgColor: "hover:bg-blue-500/20",
              activeBgColor: "active:bg-blue-500/50",
              textColor: "text-blue-700",
              // onClick: () => {
              //   setTimeout(() => {
              //     dispatch(setModalOpen(false));
              //   }, 200);
              // },
            })}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Register;
