import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setPageNum } from "../store/features/pageSlice";
import { cls } from "../libs/utils";

const Pagination = ({ totalPage }: { totalPage: number }) => {
  const dispatch = useDispatch();
  const pageNum = useSelector((state: RootState) => state.page.pageNum);
  const currentPage = pageNum;
  const visiblePageCount = 15;
  const pageCount = Math.ceil(totalPage / visiblePageCount);
  const pageNumbers: React.ReactElement[] = [];
  const pageStart =
    Math.floor((currentPage - 1) / visiblePageCount) * visiblePageCount + 1;
  const pageEnd = Math.min(pageStart + visiblePageCount - 1, pageCount);
  const singlePage = totalPage <= 1;
  const handlePageClick = (pageIndex: number) => {
    if (pageIndex < 1) {
      dispatch(setPageNum(1));
    } else if (pageIndex > pageCount) {
      dispatch(setPageNum(pageCount));
    } else {
      dispatch(setPageNum(pageIndex));
    }
  };

  const renderPageNumbers = () => {
    for (let i = pageStart; i <= pageEnd; i++) {
      pageNumbers.push(
        <button
          className={cls(
            "pageNumBtn",
            i === currentPage ? "!bg-white font-bold" : "",
          )}
          key={i}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  return (
    <>
      {singlePage ? (
        <></>
      ) : (
        <div className="flex justify-center items-center pt-2">
          {/*<button className="pageNumBtn" onClick={() => handlePageClick(1)}>*/}
          {/*  <IcPageStart className="icon" />*/}
          {/*</button>*/}
          <button
            className="pageNumBtn hover:!bg-transparent"
            onClick={() => handlePageClick(currentPage - 1)}
          >
            {/*<IcPageP className="icon" />*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </button>
          <div className="flex justify-center">{renderPageNumbers()}</div>
          <button
            className="pageNumBtn hover:!bg-transparent"
            onClick={() => handlePageClick(currentPage + 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
          {/*<button*/}
          {/*  className="pageNumBtn"*/}
          {/*  onClick={() => handlePageClick(pageCount)}*/}
          {/*>*/}
          {/*<IcPageEnd className="icon" />*/}
          {/*</button>*/}
        </div>
      )}
    </>
  );
};

export default Pagination;
