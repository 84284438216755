import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../component/admin/layout";
import UnisCalendar from "../../../utils/unisCalendar";
import { calendarFns } from "../../../utils/calendarFns";
import TaskBox from "../../../component/taskBox";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../../component/basicButton";
import Modal from "../../../component/modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Detail from "./detail";
import { setModalOpen } from "../../../store/features/modalSlice";
import Register, { ITaskData, RegisterEventProps } from "./register";
import Weather from "../../../component/weather/weather";
import { format } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { AdminDataApi } from "../../../api/AdminDataApi";
import { safetyTypeIcon } from "../../../libs/safetyTypeIcon";
import CalendarRefContext from "../../../utils/CalendarRefContext";
import { getCookie } from "../../../libs/cookies";
import {
  taskIconColor,
  taskStateBoxBg,
  taskStateColor,
} from "../../../libs/utils";
import Tag from "../../../component/tag";

export interface IDuration {
  startDate?: string;
  endDate?: string;
  date?: string;
  id?: number;
  startTime?: string;
  endTime?: string;
}

const List = () => {
  const { setCalendarRef } = useContext(CalendarRefContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(today);
  const [viewType, setViewType] = useState("dayGridMonth");
  const modalOpen = useSelector((state: RootState) => state.modal.modalOpen);
  const [selectedId, setSelectedId] = useState(-1);
  const [registerEvent, setRegisterEvent] = useState<RegisterEventProps>({
    start: "",
    end: "",
  });
  const [modalTitle, setModalTitle] = useState("업무 상세");
  const [modalContent, setModalContent] = useState<null | React.ReactElement>(
    null,
  );
  const projectId = getCookie("projectId");
  const [adminTaskDay, setAdminTaskDay] = useState<ITaskData[]>();

  useEffect(() => {
    setModalTitle("업무 상세");
    setModalContent(<Detail id={selectedId} />);
  }, [selectedId]);

  useEffect(() => {
    setModalTitle("업무 등록");
    setModalContent(
      <Register
        registerEvent={registerEvent}
        getTaskRefetch={getTaskRefetch}
      />,
    );
    registerEvent.start && dispatch(setModalOpen(true));
  }, [registerEvent]);
  const {
    data,
    isSuccess,
    isLoading,
    refetch: getTaskRefetch,
  } = useQuery({
    queryKey: ["getAdminTaskDay", selectedDate],
    queryFn: () =>
      AdminDataApi.getAdminTaskDay(
        projectId,
        format(selectedDate, "yyyy-MM-dd"),
      ),
  });

  useEffect(() => {
    if (data && data !== "error") {
      setAdminTaskDay(data?.data?.items);
    }
  }, [data]);
  const handleClickTask = (id: number) => {
    setModalTitle("업무 상세");
    setModalContent(<Detail id={id} getTaskRefetch={getTaskRefetch} />);
    dispatch(setModalOpen(true));
  };
  const handleClickRegisterBtn = () => {
    setModalTitle("업무 등록");
    setModalContent(
      <Register
        registerEvent={{
          start: format(selectedDate, "yyyy-MM-dd"),
          end: format(selectedDate, "yyyy-MM-dd"),
        }}
        getTaskRefetch={getTaskRefetch}
      />,
    );
    dispatch(setModalOpen(true));
  };

  useEffect(() => {
    console.log("adminTaskDay", adminTaskDay);
  }, [adminTaskDay]);

  return (
    <Layout title={"업무"} hasTabBar={true} canGoBack={true} hasTopBar={true}>
      {modalOpen && <Modal title={modalTitle} content={modalContent} />}
      <div className="mt-4">
        <div className="flex flex-col-reverse lg:flex-row justify-center w-full md:px-8 pt-3 md:my-0 lg:space-x-3 font-medium min-h-fit">
          {viewType === "dayGridMonth" && (
            <div className="lg:w-[1000px] lg:space-y-3">
              <div className="lg:border-t-0 py-5 lg:py-4 lg:h-[440px] adminBox flex-col">
                <span className="flex text-base lg:text-lg font-semibold text-gray-600 py-3">{`${calendarFns.formatToday(selectedDate)} 업무`}</span>

                <div className="space-y-2 h-44 lg:h-[400px] overflow-y-auto">
                  {adminTaskDay && adminTaskDay?.length > 0 ? (
                    adminTaskDay.map((task, index) => (
                      <TaskBox
                        key={index}
                        icon={
                          <div>
                            <Tag
                              color={taskStateColor(task.state)}
                              title={task.state}
                              className="!text-xs"
                            />
                          </div>
                        }
                        onClick={() => handleClickTask(task.taskId)}
                        type={"task"}
                        content={{
                          writer: task.coworkerName,
                          title: task.taskName || "로딩중",
                          location: "",
                          startTime: task.startTime.slice(0, 5),
                          endTime: task.endTime.slice(0, 5),
                        }}
                        boxClassName={taskStateBoxBg(task.state)}
                        iconClassName={
                          taskIconColor(task.safetyType) || "text-gray-500"
                        }
                      />
                    ))
                  ) : (
                    <p>등록된 업무가 없습니다</p>
                  )}
                  {/*<TaskBox*/}
                  {/*  icon={<IcInspectation />}*/}
                  {/*  onClick={() => handleClickTask(1)}*/}
                  {/*  type={"task"}*/}
                  {/*  content={{*/}
                  {/*    writer: "김아무개",*/}
                  {/*    title: "갱폼설치 안전망설치",*/}
                  {/*    location: "B21구역 14F",*/}
                  {/*    startTime: "13:00",*/}
                  {/*    endTime: "15:00",*/}
                  {/*  }}*/}
                  {/*  boxClassName={*/}
                  {/*    "bg-gray-200/60 hover:bg-gray-200/40 active:bg-gray-200/80"*/}
                  {/*  }*/}
                  {/*  iconClassName={"text-yellow-500"}*/}
                  {/*/>*/}
                  {/*<TaskBox*/}
                  {/*  icon={<IcDnager />}*/}
                  {/*  onClick={() => handleClickTask(1)}*/}
                  {/*  type={"task"}*/}
                  {/*  content={{*/}
                  {/*    writer: "김아무개",*/}
                  {/*    title: "갱폼설치 안전망설치",*/}
                  {/*    location: "B21구역 14F",*/}
                  {/*    startTime: "13:00",*/}
                  {/*    endTime: "15:00",*/}
                  {/*  }}*/}
                  {/*  boxClassName={*/}
                  {/*    "bg-gray-200/60 hover:bg-gray-200/40 active:bg-gray-200/80"*/}
                  {/*  }*/}
                  {/*  iconClassName={"text-green-500"}*/}
                  {/*/>*/}
                  {/*<TaskBox*/}
                  {/*  icon={<IcDnager />}*/}
                  {/*  onClick={() => handleClickTask(1)}*/}
                  {/*  type={"task"}*/}
                  {/*  content={{*/}
                  {/*    writer: "김아무개",*/}
                  {/*    title: "갱폼설치 안전망설치",*/}
                  {/*    location: "B21구역 14F",*/}
                  {/*    startTime: "13:00",*/}
                  {/*    endTime: "15:00",*/}
                  {/*  }}*/}
                  {/*  boxClassName={*/}
                  {/*    "bg-gray-200/60 hover:bg-gray-200/40 active:bg-gray-200/80"*/}
                  {/*  }*/}
                  {/*  iconClassName={"text-green-500"}*/}
                  {/*/>*/}
                  {/*<TaskBox*/}
                  {/*  icon={<IcDnager />}*/}
                  {/*  onClick={() => handleClickTask(1)}*/}
                  {/*  type={"task"}*/}
                  {/*  content={{*/}
                  {/*    writer: "김아무개",*/}
                  {/*    title: "갱폼설치 안전망설치",*/}
                  {/*    location: "B21구역 14F",*/}
                  {/*    startTime: "13:00",*/}
                  {/*    endTime: "15:00",*/}
                  {/*  }}*/}
                  {/*  boxClassName={*/}
                  {/*    "bg-gray-200/60 hover:bg-gray-200/40 active:bg-gray-200/80"*/}
                  {/*  }*/}
                  {/*  iconClassName={"text-green-500"}*/}
                  {/*/>*/}
                  {/*<TaskBox*/}
                  {/*  icon={<IcDnager />}*/}
                  {/*  onClick={() => handleClickTask(1)}*/}
                  {/*  type={"task"}*/}
                  {/*  content={{*/}
                  {/*    writer: "김아무개",*/}
                  {/*    title: "갱폼설치 안전망설치",*/}
                  {/*    location: "B21구역 14F",*/}
                  {/*    startTime: "13:00",*/}
                  {/*    endTime: "15:00",*/}
                  {/*  }}*/}
                  {/*  boxClassName={*/}
                  {/*    "bg-gray-200/60 hover:bg-gray-200/40 active:bg-gray-200/80"*/}
                  {/*  }*/}
                  {/*  iconClassName={"text-green-500"}*/}
                  {/*/>*/}
                  {/*<TaskBox*/}
                  {/*  icon={<IcDnager />}*/}
                  {/*  onClick={() => handleClickTask(1)}*/}
                  {/*  type={"task"}*/}
                  {/*  content={{*/}
                  {/*    writer: "김아무개",*/}
                  {/*    title: "갱폼설치 안전망설치",*/}
                  {/*    location: "B21구역 14F",*/}
                  {/*    startTime: "13:00",*/}
                  {/*    endTime: "15:00",*/}
                  {/*  }}*/}
                  {/*  boxClassName={*/}
                  {/*    "bg-gray-200/60 hover:bg-gray-200/40 active:bg-gray-200/80"*/}
                  {/*  }*/}
                  {/*  iconClassName={"text-green-500"}*/}
                  {/*/>*/}
                </div>
              </div>
              <div className="lg:border-t-0 py-5 lg:py-4 lg:h-1/3 adminBox flex-col">
                <span className="flex text-base lg:text-lg font-semibold text-gray-600 pt-3">{`${calendarFns.formatToday(selectedDate)} 날씨`}</span>
                <Weather />
              </div>
            </div>
          )}
          <div className="flex md:rounded-xl md:px-12 md:py-5 md:my-0 md:shadow md:space-y-1 font-medium bg-white w-full lg:w-full flex-col  lg:h-full">
            <UnisCalendar
              setSelectedDate={setSelectedDate}
              setViewType={setViewType}
              setSelectedId={setSelectedId}
              setRegisterEvent={setRegisterEvent}
              setCalendarRef={setCalendarRef}
              getTaskRefetch={getTaskRefetch}
            />

            {viewType === "dayGridMonth" && (
              <BasicButton
                text={"업무등록"}
                size={"lg"}
                onClick={handleClickRegisterBtn}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default List;
