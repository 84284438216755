import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../component/layout";
import { ReactComponent as IcDnager } from "../../asset/icon/ic_danger.svg";
import BasicButton from "../../component/basicButton";
const Task = () => {
  const navigate = useNavigate();
  const taskId = useParams().id;
  console.log(taskId);
  const handleReportClick = () => {
    navigate("/report");
  };
  return (
    <Layout
      title={"업무 상세"}
      hasTabBar={true}
      canGoBack={true}
      hasTopBar={true}
    >
      <div className="mx-3 mt-7 h-[500px]">
        <div className="h-full grid grid-cols-[1fr_2fr] border border-gray-300 rounded-lg *:flex *:justify-center *:items-center *:pl-3 *:py-2 bg-white">
          <div className="border-b border-gray-300 border-dotted font-semibold">
            업무명
          </div>
          <div className="border-b border-gray-300 border-dotted">
            갱폼설치 안전망설치
          </div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            날짜
          </div>
          <div className="border-b border-gray-300 border-dotted">
            2024년 1월 16일 화요일
          </div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            시작 시간
          </div>
          <div className="border-b border-gray-300 border-dotted">13 : 00</div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            종료 시간
          </div>
          <div className="border-b border-gray-300 border-dotted">15 : 00</div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            위치
          </div>
          <div className="border-b border-gray-300 border-dotted">
            B21구역 14F
          </div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            동행자
          </div>
          <div className="border-b border-gray-300 border-dotted">
            홍 아무개
          </div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            안전감시유형
          </div>
          <div className="border-b border-gray-300 border-dotted text-red-500 font-semibold">
            고위험작업
          </div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            작업명
          </div>
          <div className="border-b border-gray-300 border-dotted">
            철근 가공
          </div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            단위작업명
          </div>
          <div className="border-b border-gray-300 border-dotted">
            절단 작업
          </div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            협력업체명
          </div>
          <div className="border-b border-gray-300 border-dotted">이룸건설</div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            작업인원수
          </div>
          <div className="border-b border-gray-300 border-dotted">5명</div>
          <div className="font-semibold">담당 관리자</div>
          <div>조 아무개</div>
        </div>
        <BasicButton
          text="보고서 작성"
          size={"lg"}
          onClick={() => handleReportClick()}
        />
      </div>
    </Layout>
  );
};

export default Task;
