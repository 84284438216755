import { createSlice } from "@reduxjs/toolkit";

const initialDate = String(new Date());

const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    selectedDate: initialDate,
  },
  reducers: {
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
  },
});

export default calendarSlice.reducer;
export const { setSelectedDate } = calendarSlice.actions;
