import React, { ReactElement, useRef } from "react";
import "./App.css";
import { RouterProvider } from "react-router";
import Router from "./route/Router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CalendarContext from "./utils/CalendarRefContext";
import CalendarRefContext from "./utils/CalendarRefContext";

function App() {
  const calendarRef = useRef(null);

  const setCalendarRef = (ref: any) => {
    calendarRef.current = ref;
  };
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <CalendarRefContext.Provider value={{ calendarRef, setCalendarRef }}>
        <RouterProvider router={Router} />
      </CalendarRefContext.Provider>
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  );
}

export default App;
