import React, { useState } from "react";
import Layout from "../../../component/admin/layout";
import Input from "../../../component/input";
import { useForm } from "react-hook-form";
import BasicButton from "../../../component/basicButton";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { CommonDataApi } from "../../../api/CommonDataApi";
import { IJoinData } from "../../user/join";
import { AdminDataApi } from "../../../api/AdminDataApi";
import { formatDateToISO } from "../../../libs/utils";
import { setCookie } from "../../../libs/cookies";

export interface IProjectData {
  id?: number;
  name: string;
  constructionType?: string;
  address: string;
  startDt: string;
  endDt: string;
  manMonth?: string;
  picName?: string;
  picPhone?: string;
  leaderName?: string;
  leaderPhone?: string;
  participationCode?: string;
  createdByUserName?: string;
  createdAt?: string;
  state?: string;
}

const Register = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<IProjectData>({});

  const registerProject = useMutation<string, string, IProjectData>({
    mutationFn: (param: IProjectData) => AdminDataApi.registerProject(param),
    onSuccess: (data: any) => {
      console.log("registerProject", data);
      if (data && data !== "error") {
        if (!data.data.active) {
          setCookie("projectId", data.data.id, {
            path: "/",
            secure: false,
          });
        }
        navigate(`/admin/project`);
      } else {
        return;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onValid = (data: IProjectData) => {
    if (data?.constructionType === "공사유형")
      setError("constructionType", {
        message: "공사유형을 올바르게 선택해주세요.",
      });
    data.startDt = formatDateToISO(data?.startDt);
    data.endDt = formatDateToISO(data?.endDt);
    console.log(data);
    registerProject.mutate(data);
  };

  return (
    <Layout
      title={"프로젝트 생성"}
      hasTopBar={true}
      canGoBack={true}
      hasTabBar={false}
    >
      <form onSubmit={handleSubmit(onValid)} className="mt-4 lg:px-32 pb-10">
        <div className="rounded-lg px-3 md:px-8 py-3 md:py-5 md:my-0 my-5 md:space-y-1 font-medium bg-white bg-gradient-to-b from-white to-sky-50/15  mb-2 md:mb-3">
          <div className="flex justify-between font-semibold text-xl md:text-3xl ">
            <span>프로젝트 생성</span>
          </div>
          <div className="flex justify-between">
            <p className="text-xs md:text-base">
              현재 진행 중인 공사 중 안전감시단이 투입될 현장을 프로젝트로
              등록해주세요
            </p>
          </div>
        </div>
        <Input
          type={"input"}
          label={"현장명칭"}
          placeholder={"예) 인천검단2차 (AA1)"}
          required={true}
          className="rounded-t-lg h-16 lg:text-lg border-b-0"
          register={register("name", { required: true })}
        />
        <Input
          type={"select"}
          label={"공사유형"}
          options={["공사유형", "공동주택", "종교시설", "판매시설", "창고시설"]}
          required={true}
          className="h-16 lg:text-lg border-b-0"
          register={register("constructionType", { required: true })}
          errorMessage={errors?.constructionType?.message}
        />
        <Input
          type={"text"}
          label={"현장주소"}
          placeholder={"예) 인천광역시 남동구 정각로 29"}
          required={true}
          className="h-16 lg:text-lg border-b-0"
          register={register("address", { required: true })}
        />
        <Input
          type={"text"}
          label={"공사시작"}
          placeholder={"숫자 여덟자리로 입력해주세요"}
          required={true}
          className="h-16 lg:text-lg border-b-0"
          register={register("startDt", {
            required: true,
            pattern: {
              value: /^\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/,
              message: "공사시작 일자를 올바르게 입력해주세요",
            },
            maxLength: 8,
          })}
          errorMessage={errors?.startDt?.message}
          maxLength={8}
        />
        <Input
          type={"text"}
          label={"공사완료"}
          placeholder={"숫자 여덟자리로 입력해주세요"}
          required={true}
          className="h-16 lg:text-lg border-b-0"
          register={register("endDt", {
            required: true,
            pattern: {
              value: /^\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/,
              message: "공사완료 일자를 올바르게 입력해주세요",
            },

            maxLength: 8,
          })}
          errorMessage={errors?.endDt?.message}
          maxLength={8}
        />
        <Input
          type={"text"}
          label={"안전감시단 투입공수"}
          placeholder={"32M"}
          required={true}
          className="h-16 lg:text-lg border-b-0"
          register={register("manMonth", { required: true })}
        />
        <Input
          type={"text"}
          label={"책임자명"}
          placeholder={"호반건설 김아무개 차장"}
          required={true}
          className="h-16 lg:text-lg border-b-0"
          register={register("picName", { required: true })}
        />
        <Input
          type={"text"}
          label={"책임자 연락처"}
          placeholder={"숫자로만 입력해주세요"}
          required={true}
          className="h-16 lg:text-lg border-b-0"
          maxLength={12}
          register={register("picPhone", {
            required: true,
            maxLength: 12,
            pattern: {
              value: /^\d{11,12}$/,
              message: "연락처를 올바르게 입력해주세요",
            },
          })}
          errorMessage={errors?.picPhone?.message}
        />
        <Input
          type={"text"}
          label={"팀장명"}
          placeholder={"UNISON 조아무개 팀장"}
          required={true}
          className="h-16 lg:text-lg border-b-0"
          register={register("leaderName", { required: true })}
        />
        <Input
          type={"text"}
          label={"팀장 연락처"}
          placeholder={"숫자로만 입력해주세요"}
          required={true}
          className="h-16 lg:text-lg rounded-b-lg"
          maxLength={12}
          register={register("leaderPhone", {
            required: true,
            maxLength: 12,
            pattern: {
              value: /^\d{11,12}$/,
              message: "연락처를 올바르게 입력해주세요",
            },
          })}
          errorMessage={errors?.leaderPhone?.message}
        />

        <BasicButton text={"등록"} size={"lg"} type={"submit"} />
      </form>
    </Layout>
  );
};

export default Register;
