import React from "react";
import Layout from "../../component/layout";

const Account = () => {
  return (
    <Layout hasTabBar={true} hasTopBar={true} title={"계정 관리"}>
      <div className="mx-3 mt-7 h-[500px] bg-white">
        <div className="h-full grid grid-cols-[1fr_2fr] border border-gray-300 rounded-lg *:flex *:justify-center *:items-center *:pl-3 *:py-2">
          <div className="border-b border-gray-300 border-dotted font-semibold">
            이름
          </div>
          <div className="border-b border-gray-300 border-dotted">
            블루시그널
          </div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            아이디
          </div>
          <div className="border-b border-gray-300 border-dotted">
            bluesignal
          </div>
          <div className="border-b border-gray-300 font-semibold border-dotted">
            비밀번호
          </div>
          <div>12345</div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            생년월일
          </div>
          <div className="border-b border-gray-300 border-dotted">
            2024년 1월 16일 화요일
          </div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            주소
          </div>
          <div className="border-b border-gray-300 border-dotted">
            대전 유성구
          </div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            전화번호
          </div>
          <div className="border-b border-gray-300 border-dotted">
            042-863-6136
          </div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            국적
          </div>
          <div className="border-b border-gray-300 border-dotted">한국</div>
          <div className="border-b border-gray-300 border-dotted font-semibold">
            프로젝트
          </div>
          <div className="border-b border-gray-300 border-dotted">인천검단</div>
          <div className="border-gray-300 border-dotted font-semibold">
            혈액형
          </div>
          <div className="border-gray-300 border-dotted">O형</div>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
