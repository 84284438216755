import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IcDanger } from "../../src/asset/icon/ic_danger.svg";
import { ReactComponent as IcInspectation } from "../../src/asset/icon/ic_inspection.svg";
import { ReactComponent as IcPatrol } from "../../src/asset/icon/ic_patrol.svg";

export function safetyTypeIcon(safetyType: string): ReactElement | null {
  if (safetyType === "고위험작업") {
    return <IcDanger />;
  } else if (safetyType === "안전순찰") {
    return <IcPatrol />;
  } else if (safetyType === "장비점검") {
    return <IcInspectation />;
  } else {
    return null;
  }
}
