import axios from "axios";
import { IJoinData } from "../page/user/join";
import { IProjectData } from "../page/admin/project/register";
import { ITaskData } from "../page/admin/task/register";
import { IDuration } from "../page/admin/task/list";
import { IReportState } from "../page/admin/report/detail";
import { IReportListParam } from "../page/user/report/reports";
import { IVideoListParams } from "../page/admin/video/list";

export const AdminDataApi = {
  getProjectList: async () => {
    try {
      const response = await axios.get(`/admin/projects`);
      console.log("🥸 관리자 프로젝트 리스트 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 프로젝트 리스트 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
  registerProject: async (params: IProjectData) => {
    // console.log("registerProject", params);

    try {
      const response = await axios.post(`/admin/project`, params);
      console.log("🥸 관리자 프로젝트 등록 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 프로젝트 등록 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  activeProject: async (projectId: number) => {
    console.log("projectId", projectId);

    try {
      const response = await axios.put(`/admin/project/user/active`, {
        projectId,
      });
      console.log("🥸 관리자 프로젝트 active 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 프로젝트 active 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getProjectDetail: async (id: string) => {
    console.log("getProjectDetail", id);

    try {
      const response = await axios.get(`/admin/project/${id}`);
      console.log("🥸 관리자 프로젝트 상세 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 프로젝트 상세 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
  updateProjectDetail: async (params: IProjectData) => {
    // console.log("putProjectDetail", params);

    try {
      const response = await axios.put(`/admin/project`, params);
      // console.log("🥸 관리자 프로젝트 수정 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 프로젝트 수정 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
  deleteProject: async (id: string) => {
    // console.log("deleteProject", id);

    try {
      const response = await axios.delete(`/admin/project/${id}`);
      // console.log("🥸 관리자 프로젝트 삭제 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 프로젝트 삭제 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
  registerTask: async (params: ITaskData) => {
    // console.log("registerTask", params);

    try {
      const response = await axios.post(`/admin/task`, params);
      // console.log("🥸 관리자 업무 등록 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 업무 등록 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
  getAdminTaskDay: async (projectId: string, date: string) => {
    try {
      const response = await axios.get(`/admin/tasks/day/${projectId}/${date}`);
      console.log("🥸 관리자 업무 일별 리스트", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 업무 일별 리스트 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getAdminTaskWeek: async (projectId: string, params: IDuration) => {
    try {
      const response = await axios.get(`/admin/tasks/week/${projectId}`, {
        params,
      });
      console.log("🥸 관리자 업무 주별 리스트 params", params);
      console.log("🥸 관리자 업무 주별 리스트", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 업무 주별 리스트 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getAdminTaskMonth: async (projectId: string, date: string) => {
    try {
      const response = await axios.get(
        `/admin/tasks/month/${projectId}/${date}`,
      );
      console.log("🥸 관리자 업무 월별 리스트", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 업무 월별 리스트 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
  getAdminTaskDetail: async (taskId: number) => {
    try {
      const response = await axios.get(`/admin/task/${taskId}`);
      console.log("🥸 관리자 업무 상세", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 업무 상세 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  updateTaskDetail: async (params: ITaskData) => {
    console.log("updateTaskDetail", params);

    try {
      const response = await axios.put(`/admin/task`, params);
      console.log("🥸 관리자 업무 수정 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 업무 수정 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
  updateTaskDateTime: async (params: IDuration) => {
    console.log("updateTaskDateTime", params);
    const { id, date, startTime, endTime } = params;
    try {
      const response = await axios.put(`/admin/task/datetime`, {
        id,
        date,
        startTime,
        endTime,
      });
      console.log("🥸 관리자 업무 일시 수정 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 업무 일시 수정 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  deleteTaskDetail: async (id: number) => {
    console.log("deleteTaskDetail", id);

    try {
      const response = await axios.delete(`/admin/task/${id}`);
      console.log("🥸 관리자 업무 삭제 성공", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 업무 삭제 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getReports: async (params: IReportListParam) => {
    const { projectId, searchWord, page } = params;
    try {
      const response = await axios.get(`/admin/reports`, {
        params: {
          projectId,
          searchWord,
          page,
        },
      });

      console.log("🥸 관리자 보고서 리스트", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 보고서 리스트 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getReportDetail: async (reportId: number, reportType: string) => {
    try {
      const response = await axios.get(`/admin/report/${reportId}`, {
        params: {
          reportType: reportType,
        },
      });
      console.log("🥸 관리자 보고서 상세", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 보고서 상세 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  editState: async (param: IReportState) => {
    console.log("editState param", param);

    try {
      const response = await axios.put(`/admin/report/state`, param);
      console.log(
        "🥸 관리자 보고서 개선시작/개선완료/승인/반려 성공",
        response.data,
      );
      return response.data.code;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(
          "🥸 관리자 보고서 개선시작/개선완료/승인/반려 실패",
          error.response?.data,
        );
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getDashboard: async (projectId: number) => {
    try {
      const response = await axios.get(`/admin/dashboard/${projectId}`);
      console.log("🥸 관리자 대시보드", response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 대시보드 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getVideoList: async (params: IVideoListParams) => {
    console.log("params", params);
    const { projectId, searchWord, page } = params;
    try {
      const response = await axios.get(`/admin/reports/video`, {
        params: {
          projectId,
          searchWord,
          page,
        },
      });
      console.log("🥸 관리자 영상 보고 리스트", response.data);
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("🥸 관리자 영상 보고 리스트 실패", error.response?.data);
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },

  getExtraVideoList: async (params: IVideoListParams) => {
    console.log("params", params);
    const { projectId, searchWord, page } = params;
    try {
      const response = await axios.get(`/admin/reports/video/extra`, {
        params: {
          projectId,
          searchWord,
          page,
        },
      });
      console.log("🥸 관리자 추가 영상 보고 리스트", response.data);
      return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(
          "🥸 관리자 추가 영상 보고 리스트 실패",
          error.response?.data,
        );
      } else {
        console.error("Error", error);
      }
      return "error";
    }
  },
};
