import React, { useEffect, useState } from "react";
import Layout from "../../../../component/admin/layout";
import StatusTab from "../../../../component/statusTab";
import Input from "../../../../component/input";
import { ReactComponent as IcFinder } from "../../../../asset/icon/ic_finder.svg";
import Tag from "../../../../component/tag";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../../../component/basicButton";
import { cls, riskIndexColor, taskStateColor } from "../../../../libs/utils";
import { IReportList, IReportListParam } from "../../../user/report/reports";
import { IReportData } from "../../../user/report/report";
import { REPORT_TYPE, RISK_INDEX } from "../../../../libs/constants";
import { format, parse } from "date-fns";
import isExist from "../../../../component/isExist";
import IsExist from "../../../../component/isExist";
import { useForm } from "react-hook-form";
import Pagination from "../../../../component/pagination";
import { getCookie } from "../../../../libs/cookies";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useQuery } from "@tanstack/react-query";
import { AdminDataApi } from "../../../../api/AdminDataApi";

export interface IAdminReportData extends IReportData {
  hasImage: boolean;
  hasVideo: boolean;
  startTime?: string;
  endTime?: string;
}

const Web = (
  {
    // reports,
    // setSearchWord,
    // totalPage,
  }: {
    // reports: IAdminReportData[];
    // setSearchWord: React.Dispatch<React.SetStateAction<string>>;
    // totalPage: number;
  },
) => {
  const projectId = getCookie("projectId");
  const page = useSelector((state: RootState) => state.page.pageNum);

  const [searchWord, setSearchWord] = useState("");
  const initialReportListParam = {
    projectId: projectId,
    searchWord: "",
    page: page,
  };
  const [params, setParams] = useState<IReportListParam>(
    initialReportListParam,
  );

  const [totalPage, setTotalPage] = useState(0);

  const { data, refetch, isSuccess } = useQuery({
    queryKey: ["getAdminReports", params],
    queryFn: () => AdminDataApi.getReports(params),
  });

  // 확인
  useEffect(() => {
    console.log("보고서 리스트 ", data);
  }, [data]);

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      projectId: projectId,
      searchWord: searchWord,
      page: page,
    }));
  }, [projectId, searchWord, page]);

  // 확인
  // useEffect(() => {
  //   console.log(params);
  // }, [params]);

  // console.log("reports", reports);
  // console.log("totalPage", totalPage);
  const navigate = useNavigate();

  const handleClickReport = (
    taskId: number,
    reportId: number,
    reportType: REPORT_TYPE,
  ) => {
    navigate("detail", {
      state: {
        taskId: taskId,
        reportId: reportId,
        reportType: reportType,
      },
    });
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<IReportListParam>();
  const searchWordWatch = watch("searchWord");

  // useEffect(() => {
  //   setSearchWord(searchWordWatch);
  // }, [searchWordWatch]);

  const onValid = (data: IReportListParam) => {
    console.log(data);
    setSearchWord(data.searchWord);
  };

  return (
    <div className="hidden lg:block">
      <div className="my-4 flex justify-between">
        <form className="lg:w-1/3" onSubmit={handleSubmit(onValid)}>
          <Input
            type="text"
            placeholder="등록자명, 협력업체명, 공사종류, 재해유형"
            icon={
              <span className="text-gray-300">
                <IcFinder />
              </span>
            }
            className="rounded-xl mx-auto border-0 h-12 bg-lightGray"
            register={register("searchWord")}
          />
        </form>
      </div>

      <div
        className={cls(
          "mt-4 md:space-y-3 space-y-2 text-center py-2 lg:text-base",
        )}
      >
        <>
          <table className="table-auto w-full ">
            <thead className="bg-lightGray h-12">
              <tr className="font-semibold">
                <td>상태</td>
                <td>감시자명</td>
                <td>제목</td>
                <td>위치</td>
                <td>작업명</td>
                <td>단위작업명</td>
                <td>업체명</td>
                <td>위험상태</td>
                <td>업무시작</td>
                <td>업무종료</td>
                <td>사진</td>
                <td>영상</td>
              </tr>
            </thead>
            <tbody>
              {isSuccess && data?.data && data?.data?.items.length > 0 ? (
                data?.data?.items.map(
                  (report: IAdminReportData, index: number) => (
                    <tr
                      className="*:text-center h-12 cursor-pointer border border-x-0 border-t-none border-b hover:bg-blue-50 transition-colors bg-white "
                      onClick={() =>
                        handleClickReport(
                          report.taskId || -1,
                          report.reportId || -1,
                          report.reportType || REPORT_TYPE.SAFE,
                        )
                      }
                      key={index}
                    >
                      <td className="flex justify-center items-center text-xs h-12">
                        <Tag
                          color={taskStateColor(report.state || "대기")}
                          title={report.state || "대기"}
                        />
                      </td>
                      <td>{report.coworkerName}</td>
                      <td>{report.name}</td>
                      <td>{report.location}</td>
                      <td>{report.majorCategory}</td>
                      <td>{report.middleCategory}</td>
                      <td>{report.subcontractorName}</td>
                      <td className="flex justify-center text-xs items-center">
                        {report.reportType === REPORT_TYPE.DANGER ? (
                          <Tag
                            color={
                              riskIndexColor(
                                report.riskIndex || RISK_INDEX.REVIEW,
                              ) || "yellow"
                            }
                            title={report.riskIndex || ""}
                          />
                        ) : (
                          <span className="flex justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={3}
                              stroke="currentColor"
                              className="w-4 h-4 text-gray-500"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 12h14"
                              />
                            </svg>
                          </span>
                        )}
                      </td>
                      <td>
                        {report.date} {report.startTime?.slice(0, 5)}
                      </td>
                      <td>
                        {report.date} {report.endTime?.slice(0, 5)}
                      </td>
                      <td>
                        <span className="flex justify-center">
                          {IsExist(report.hasImage)}
                        </span>
                      </td>
                      <td>
                        <span className="flex justify-center">
                          {IsExist(report.hasVideo)}
                        </span>
                      </td>
                    </tr>
                  ),
                )
              ) : (
                <p>등록된 보고서가 없습니다</p>
              )}
            </tbody>
          </table>
          <Pagination totalPage={data?.data?.totalNum || 0} />
        </>
      </div>
    </div>
  );
};

export default Web;
