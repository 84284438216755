import React from "react";
import { cls } from "../../libs/utils";

const Weather = ({ mini = false }: { mini?: boolean }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="grid grid-cols-2 self-center gap-3">
        <div className="flex items-center font-bold text-6xl">-5°C</div>

        <div className="flex pl-3">
          <div>
            <div className="text-2xl font-semibold">대체로 맑음</div>
            <div className="flex text-base font-semibold space-x-3">
              <p className="space-x-1">
                <span className="weather_title">최고</span>
                <span>3°C</span>
              </p>
              <p className="space-x-1">
                <span className="weather_title">최저</span>
                <span>-19°C</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 font-semibold *:space-x-3 w-fit mt-3">
        <p className={cls(mini ? "hidden" : "")}>
          <span className="weather_title">풍향</span>
          <span>북서풍</span>
        </p>
        <p>
          <span className="weather_title">풍속</span>
          <span>5m/s</span>
        </p>
        <p className={cls(mini ? "hidden" : "")}>
          <span className="weather_title">돌풍</span>
          <span>8m/s</span>
        </p>
        <p>
          <span className="weather_title">습도</span>
          <span>42%</span>
        </p>
        <p>
          <span className="weather_title">강수량</span>
          <span>0mm</span>
        </p>
        <p className={cls(mini ? "hidden" : "")}>
          <span className="weather_title">가시거리</span>
          <span>23km</span>
        </p>
        <p>
          <span className="weather_title">일출</span>
          <span>07:40</span>
        </p>
        <p>
          <span className="weather_title">일몰</span>
          <span>18:40</span>
        </p>
        <p>
          <span className="weather_title">대기질</span>
          <span>보통</span>
        </p>
      </div>
    </div>
  );
};

export default Weather;
