// CalendarRefContext.js
import React, { RefObject } from "react";
import FullCalendar from "@fullcalendar/react";

const CalendarRefContext = React.createContext<any>({
  calendarRef: { current: null },
  setCalendarRef: () => {},
});

export default CalendarRefContext;
