import React from "react";
import { cls } from "../libs/utils";
export interface TagProps {
  color:
    | "yellow"
    | "red"
    | "green"
    | "gray"
    | "blue"
    | "lightBlue"
    | "orange"
    | "white"
    | "darkBlue";
  noBg?: boolean;
  reason?: string;
  title: string;
  className?: string;
}
/*
 *   < 업무 색상 >
 *   계획 : gray
 *   대기 : green
 *   반려 :  red
 *   개선중 :  lightBlue
 *   개선 완료 : blue
 *   완료 :  darkBlue
 * */
const Tag = ({ color, noBg = false, reason, title, className }: TagProps) => {
  // console.log("reason", reason);
  if (color === "green") {
    return (
      <span
        className={cls(
          "flex items-center transition-colors px-3 py-1 rounded-full font-semibold text-green-600 w-fit",
          className ? className : "",
          noBg ? "ring-1 ring-green-200" : "bg-green-500/20",
        )}
      >
        {title}
      </span>
    );
  } else if (color === "orange") {
    return (
      <span
        className={cls(
          "flex items-center transition-colors px-3 py-1 rounded-full font-semibold  text-orange-600 w-fit",
          className ? className : "",
          noBg ? "ring-1 ring-orange-200" : "bg-orange-500/20",
        )}
      >
        {title}
      </span>
    );
  } else if (color === "yellow") {
    return (
      <span
        className={cls(
          "flex items-center transition-colors px-3 py-1 rounded-full font-semibold  text-yellow-600 w-fit",
          className ? className : "",
          noBg ? "ring-1 ring-yellow-200" : "bg-yellow-500/20",
        )}
      >
        {title}
      </span>
    );
  } else if (color === "gray") {
    return (
      <span
        className={cls(
          "flex items-center transition-colors px-3 py-1 rounded-full font-semibold  text-gray-600 w-fit",
          className ? className : "",
          noBg ? "ring-1 ring-gray-200" : "bg-gray-300",
        )}
      >
        {title}
      </span>
    );
  } else if (color === "blue") {
    return (
      <span
        className={cls(
          "flex items-center transition-colors px-3 py-1 rounded-full font-semibold  text-blue-600 w-fit",
          className ? className : "",
          noBg ? "ring-1 ring-blue-200" : "bg-blue-300",
        )}
      >
        {title}
      </span>
    );
  } else if (color === "lightBlue") {
    return (
      <span
        className={cls(
          "flex items-center transition-colors px-3 py-1 rounded-full font-semibold  text-blue-600 w-fit",
          className ? className : "",
          noBg ? "ring-1 ring-blue-200" : "bg-blue-200",
        )}
      >
        {title}
      </span>
    );
  } else if (color === "white") {
    return (
      <span
        className={cls(
          "bg-white flex items-center transition-colors px-3 py-1 rounded-full font-semibold  text-gray-600 w-fit",
          className ? className : "",
        )}
      >
        {title}
      </span>
    );
  } else if (color === "darkBlue") {
    return (
      <span
        className={cls(
          "flex items-center transition-colors px-3 py-1 rounded-full font-semibold  text-indigo-600 w-fit",
          className ? className : "",
          noBg ? "ring-1 ring-indigo-200" : "bg-indigo-600/20 ",
        )}
      >
        {title}
      </span>
    );
  } else if (color === "red" && reason) {
    return (
      <p
        className={cls(
          "flex items-center transition-colors px-3 py-1 rounded-lg font-semibold bg-red-500/20 text-red-600",
        )}
      >
        {title}
        <span> : {reason}</span>
      </p>
    );
  } else if (color === "red" && !reason) {
    return (
      <span
        className={cls(
          "flex items-center transition-colors px-3 py-1 rounded-full font-semibold  text-red-600 w-fit",
          className ? className : "",
          noBg ? "ring-1 ring-red-200" : "bg-red-500/20 ",
        )}
      >
        {title}
      </span>
    );
  } else {
    return <></>;
  }
};

export default Tag;
