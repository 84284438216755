import React, { useEffect, useMemo, useState } from "react";
import Tag from "../../../component/tag";
import { useNavigate } from "react-router-dom";
import { cls, riskIndexColor, taskStateColor } from "../../../libs/utils";
import Layout from "../../../component/admin/layout";
import Input from "../../../component/input";
import { ReactComponent as IcFinder } from "../../../asset/icon/ic_finder.svg";
import { motion } from "framer-motion";
import { Simulate } from "react-dom/test-utils";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { AdminDataApi } from "../../../api/AdminDataApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useForm } from "react-hook-form";
import { IReportListParam } from "../../user/report/reports";
import { getCookie } from "../../../libs/cookies";
import { IReportData } from "../../user/report/report";
import { ITaskData } from "../task/register";
import { Root } from "react-dom/client";
import MediaModal, { IMediaModal } from "../../../component/mediaModal";
import {
  setMediaModalOpen,
  setModalOpen,
} from "../../../store/features/modalSlice";
import Pagination from "../../../component/pagination";
import useIntersectionObserver from "../../../libs/useIntersectionObserver";

export interface IVideoListParams {
  projectId: number;
  searchWord: string;
  page: number;
}

export interface IVideo extends IReportData {
  startTime?: string;
  endTime?: string;
  path: string;
  createdByUserName?: string;
}
const List = () => {
  const navigate = useNavigate();
  const [type, setType] = useState<"extra" | "report">("report");
  const page = useSelector((state: RootState) => state.page.pageNum);
  const [searchWord, setSearchWord] = useState("");
  const projectId = getCookie("projectId");
  const initialVideoListParam = {
    projectId: projectId,
    searchWord: "",
    page: 1,
  };

  const [params, setParams] = useState<IVideoListParams>(initialVideoListParam);
  const mediaModalOpen = useSelector(
    (state: RootState) => state.modal.mediaModalOpen,
  );
  const initialMediaModal = {
    path: "",
    title: "",
    type: "video" as "video",
  };
  const [media, setMedia] = useState<IMediaModal>(initialMediaModal);
  const toggleSwitch = (type: "extra" | "report") => setType(type);
  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30,
  };

  // const { data } = useQuery({
  //   queryKey: ["getVideoList", params],
  //   queryFn: () => AdminDataApi.getVideoList(params),
  //   enabled: type === "report",
  // });

  const visibleItemCount = 15;
  const {
    // data: adminReportScrollData,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isSuccess,
  } = useInfiniteQuery({
    queryKey: ["getVideoList", params],
    initialPageParam: 1,
    queryFn: ({ pageParam }) =>
      AdminDataApi.getVideoList({ ...params, page: pageParam }),
    getNextPageParam: (lastPage, allPages) => {
      // console.log("lastPage", lastPage);
      return lastPage?.data?.items?.length < visibleItemCount
        ? undefined
        : allPages.length + 1;
    },
    enabled: type === "report",
  });

  // const { data: extraData } = useQuery({
  //   queryKey: ["getExtraVideoList", params],
  //   queryFn: () => AdminDataApi.getExtraVideoList(params),
  //   enabled: type === "extra",
  // });

  const {
    // data: adminReportScrollData,
    data: extraData,

    fetchNextPage: extraFetchNextPage,
    hasNextPage: extraHasNextPage,
  } = useInfiniteQuery({
    queryKey: ["getExtraVideoList", params],
    initialPageParam: 1,
    queryFn: ({ pageParam }) =>
      AdminDataApi.getExtraVideoList({ ...params, page: pageParam }),
    getNextPageParam: (lastPage, allPages) => {
      // console.log("lastPage", lastPage);
      return lastPage?.data?.items?.length < visibleItemCount
        ? undefined
        : allPages.length + 1;
    },
    enabled: type === "extra",
  });

  const reports = useMemo(() => {
    if (type === "report") {
      // const allReports = data?.pages?.flatMap((page) => page?.data?.items);
      const allReports = data?.pages?.flatMap((page) => page?.items);
      console.log("통합된 데이터", allReports);
      return allReports;
    } else if (type === "extra") {
      // const allReports = data?.pages?.flatMap((page) => page?.data?.items);
      const allReports = extraData?.pages?.flatMap((page) => page?.items);
      console.log("통합된 데이터", allReports);
      return allReports;
    }
  }, [data, type]);

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      projectId: projectId,
      searchWord: searchWord,
      page: page,
    }));
  }, [projectId, searchWord, page]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<IReportListParam>();
  const searchWordWatch = watch("searchWord");
  const dispatch = useDispatch();

  const onValid = (data: IReportListParam) => {
    console.log(data);
    setSearchWord(data?.searchWord);
  };

  const handleClickMedia = (path: string, title: string) => {
    setMedia((prev) => ({
      ...prev,
      path,
      title,
      type: "video",
    }));
    dispatch(setMediaModalOpen(true));
  };
  const { setTarget } = useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  return (
    <Layout
      canGoBack={true}
      title={"영상 보고"}
      hasTabBar={true}
      hasTopBar={true}
    >
      {mediaModalOpen && <MediaModal props={media} />}
      <div className="mt-4 flex flex-col">
        <div className="flex space-x-4 mb-2">
          <form className="lg:w-1/3" onSubmit={handleSubmit(onValid)}>
            <Input
              type="text"
              placeholder="등록자명, 협력업체명, 공사종류, 재해유형"
              icon={
                <span className="text-gray-300">
                  <IcFinder />
                </span>
              }
              className="rounded-xl mx-auto border-0 h-12 bg-lightGray"
              register={register("searchWord")}
            />
          </form>
          <div className="flex w-full space-x-4 overflow-x-auto">
            <select className="appearance-none bg-lightGray h-12 rounded-xl px-4 focus:outline-0 text-gray-400 font-semibold cursor-pointer">
              <option className="">위험요소</option>
            </select>
            <select className="appearance-none bg-lightGray h-12 rounded-xl px-4 focus:outline-0 text-gray-400 font-semibold cursor-pointer">
              <option className="">재해유형</option>
            </select>
            <select className="appearance-none bg-lightGray h-12 rounded-xl px-4 focus:outline-0 text-gray-400 font-semibold cursor-pointer">
              <option className="">공사종류</option>
            </select>
            <select className="appearance-none bg-lightGray h-12 rounded-xl px-4 focus:outline-0 text-gray-400 font-semibold cursor-pointer">
              <option className="">중점위험</option>
            </select>
            <select className="appearance-none bg-lightGray h-12 rounded-xl px-4 focus:outline-0 text-gray-400 font-semibold cursor-pointer">
              <option className="">긴급위험</option>
            </select>
          </div>
        </div>
        <div className="flex w-full space-x-4 ml-3 lg:ml-0">
          <div
            className={cls(
              "relative w-52 h-12 flex rounded-xl p-1.5 bg-lightGray",
              type === "extra" ? "justify-end" : "justify-start",
            )}
          >
            <motion.div
              className="w-1/2 h-full bg-white rounded-xl"
              layout
              transition={spring}
            />
            <div className="absolute left-0 top-0 flex justify-between w-full h-full items-center px-7 *:font-semibold">
              <span
                className={cls(
                  "cursor-pointer",
                  type === "report" ? "text-gray-700" : "text-gray-400",
                )}
                onClick={() => toggleSwitch("report")}
              >
                업무보고
              </span>
              <span
                className={cls(
                  "cursor-pointer",
                  type === "extra" ? "text-gray-700" : "text-gray-400",
                )}
                onClick={() => toggleSwitch("extra")}
              >
                추가위험
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="md:space-y-3 space-y-2 text-center py-2 lg:text-base grid lg:grid-cols-3 lg:gap-2 grid-cols-1 gap-3">
        {type === "report" ? (
          // data && data?.items?.length > 0 ? (
          //   data.items.map((video: IVideo, index: number) => (

          reports && reports?.length > 0 ? (
            reports?.map((video: IVideo, index: number) => (
              <div
                key={`video_${index}`}
                className="w-full bg-white shadow py-4 px-6 rounded hover:shadow-xl cursor-pointer transition-all mt-3"
                onClick={() => {
                  handleClickMedia(video.path, video.name || "");
                }}
              >
                <video
                  src={`${process.env.REACT_APP_IMAGE_URL}${video.path}`}
                  className="w-full aspect-video roundedg"
                  autoPlay={false}
                  muted
                  loop
                ></video>

                <p className="font-semibold text-lg pt-2">{video.name}</p>
                <div className="flex flex-col text-left">
                  <span className="font-semibold">{video.authorName}</span>
                  <span>{video.location}</span>
                  <span>
                    {video.middleCategory}/ {video.majorCategory}
                  </span>
                  <span className="text-sm">
                    {video.date} {video.startTime?.slice(0, 5)}-{" "}
                    {video.endTime?.slice(0, 5)}
                  </span>
                  <div className="flex space-x-1 text-xs">
                    <Tag
                      color={taskStateColor(video.state || "")}
                      title={video.state || ""}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="w-full text-center col-span-3">
              등록된 영상이 없습니다
            </p>
          )
        ) : //   extraData && extraData?.items?.length > 0 ? (
        // extraData.items.map((video: IVideo, index: number) => (
        reports && reports?.length > 0 ? (
          reports.map((video: IVideo, index: number) => (
            <div
              key={`video_${index}`}
              className="w-full bg-white shadow py-4 px-6 rounded hover:shadow-xl cursor-pointer transition-all mt-3"
              onClick={() => {
                handleClickMedia(video.path, video.name || "");
              }}
            >
              <video
                src={`${process.env.REACT_APP_IMAGE_URL}${video.path}`}
                className="w-full aspect-video roundedg"
                autoPlay={false}
                muted
                loop
              ></video>

              <p className="flex justify-center font-semibold text-lg pt-2">
                <span className="text-xs mr-2 flex items-center">
                  <Tag
                    color={riskIndexColor(video.riskIndex || "") || "red"}
                    title={video.riskIndex || ""}
                  />
                </span>
                {video.name}
              </p>
              <div className="flex flex-col text-left">
                <div className="font-semibold flex justify-between">
                  {video.createdByUserName}
                </div>
                <span>{video.location}</span>
                <div className="grid grid-cols-4">
                  <span>위험요인 </span>
                  <span className="font-semibold">{video.riskFactors}</span>
                  <span>재해유형 </span>
                  <span className="font-semibold">{video.disasterType}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="w-full text-center col-span-3">
            등록된 영상이 없습니다
          </p>
        )}
      </div>
      <div ref={setTarget} className="h-1" />
    </Layout>
  );
};

export default List;
