import React, { useEffect, useState } from "react";
import Layout from "../../../component/admin/layout";
import Input from "../../../component/input";
import BasicButton from "../../../component/basicButton";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { IProjectData } from "./register";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AdminDataApi } from "../../../api/AdminDataApi";
import { format, parse } from "date-fns";
import { setModalOpen } from "../../../store/features/modalSlice";
import { renderButton } from "../../../component/statusButton";
import { useDispatch } from "react-redux";
import { cls, formatDateToISO } from "../../../libs/utils";
import { UserDataApi } from "../../../api/UserDataApi";
import { IReportData } from "../../user/report/report";
import { setCookie } from "../../../libs/cookies";

const Detail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [projectDetail, setProjectDetail] = useState<IProjectData>();
  const [isEdit, setIsEdit] = useState(false);
  const mode = isEdit ? "text" : "div";
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<IProjectData>();
  console.log("param Id", id);
  const { data, isSuccess, isLoading, refetch } = useQuery({
    queryKey: ["getProjectDetail", id],
    queryFn: () => AdminDataApi.getProjectDetail(id || ""),
    enabled: id?.length !== 0,
  });

  const activeProject = useMutation<string, string, number>({
    mutationFn: (projectId: number) => AdminDataApi.activeProject(projectId),
    onSuccess: (data: any) => {
      console.log(data);
      if (data && data?.code === "OK") {
        setCookie("projectId", data?.data.activeProjectId, {
          path: "/",
          secure: false,
        });
        navigate("/admin/project");
      } else {
        return;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const updateProjectDetail = useMutation<string, string, IProjectData>({
    mutationFn: (param: IProjectData) =>
      AdminDataApi.updateProjectDetail(param),
    onSuccess: (data) => {
      if (data && data !== "error") {
        console.log(data);
        setIsEdit(false);
        refetch();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const deleteProject = useMutation<string, string, string>({
    mutationFn: (id: string) => AdminDataApi.deleteProject(id || ""),
    onSuccess: (data) => {
      if (data && data !== "error") {
        console.log(data);
        navigate("/admin/project");
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (data) {
      console.log(data);
      if (data && data !== "error") {
        setCode(data.data.participationCode);
        setProjectDetail(data.data);
      }
    }
  }, [data]);

  const onValid = (data: IProjectData) => {
    if (data.constructionType === "공사유형")
      setError("constructionType", {
        message: "공사유형을 올바르게 선택해주세요.",
      });
    console.log(data);

    let formattedStartDate;
    if (data.startDt.length > 0) {
      const startParseDate =
        parse(data.startDt, "yyyy년 MM월 dd일", new Date()) + "";
      formattedStartDate = format(startParseDate, "yyyy-MM-dd'T'00:00:00");
    }
    console.log("formattedStartDate", formattedStartDate);
    console.log("data.endDt", data?.endDt);

    let formattedEndDate;
    if (data.endDt.length > 0) {
      const endParseDate =
        parse(data.endDt, "yyyy년 MM월 dd일", new Date()) + "";
      formattedEndDate = format(endParseDate, "yyyy-MM-dd'T'00:00:00");
    }
    console.log("formattedEndDate", formattedEndDate);

    const filledData = {
      ...data,
      id: +id!,
      name: data?.name || projectDetail?.name || "",
      constructionType:
        data?.constructionType || projectDetail?.constructionType || "",
      address: data?.address || projectDetail?.address || "",
      startDt: formattedStartDate || projectDetail?.startDt || "",
      endDt: formattedEndDate || projectDetail?.endDt || "",
      manMonth: data?.manMonth || projectDetail?.manMonth || "",
      picName: data?.picName || projectDetail?.picName || "",
      picPhone: data?.picPhone || projectDetail?.picPhone || "",
      leaderName: data?.leaderName || projectDetail?.leaderName || "",
      leaderPhone: data?.leaderPhone || projectDetail?.leaderPhone || "",
    };

    updateProjectDetail.mutate(filledData);
  };
  const [code, setCode] = useState<string>("");
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const copy = () => {
    if (isCopied) return;
    navigator.clipboard.writeText(code).then(() => {
      setIsCopied(true);
      if (intervalId) {
        clearInterval(intervalId);
      }

      const id = setInterval(() => {
        setIsCopied(false);
        clearInterval(id);
      }, 1000);

      setIntervalId(id);
    });
  };
  const onClick = () => {
    setIsEdit(true);
  };
  const onClickDeleteBtn = () => {
    deleteProject.mutate(id || "");
  };

  const onHandleActive = () => {
    console.log("onHandleActive", typeof projectDetail?.id);
    activeProject.mutate(projectDetail?.id || 0);
  };

  return (
    <Layout
      title={"프로젝트 상세"}
      hasTopBar={true}
      canGoBack={true}
      hasTabBar={true}
    >
      <form
        onSubmit={handleSubmit(onValid)}
        className="mt-4 lg:mt-10 lg:px-32 pb-10"
      >
        <div className="mt-4 lg:px-32 pb-10">
          <div className="w-full py-2 border border-gray-300 rounded-t-lg bg-white border-b-0 lg:px-5">
            <div className="w-full py-3 flex justify-center items-center font-bold text-xl">
              <span>{projectDetail?.name || "로딩중"}</span>
            </div>
            <p className="flex justify-between font-medium text-xs lg:text-base text-right px-4">
              <span>
                등록자 : {projectDetail?.createdByUserName || "로딩중"}
              </span>
              <span>
                등록일자 :
                {(projectDetail?.createdAt &&
                  format(projectDetail?.createdAt, "yyyy년 MM월 dd일")) ||
                  "로딩중"}
              </span>
            </p>
          </div>
          <div className="relative flex items-center w-full border border-gray-300 bg-white h-16 lg:text-lg border-b-0 ring-blue-500 ring-inset ring-[1.5px]">
            <span className="text-gray-600 max-w-24 lg:max-w-full absolute inset-y-0 left-0 flex items-center lg:pl-10 pl-4 whitespace-pre-line font-semibold">
              코드
            </span>
            <div className="inner_input_res ml-32 lg:ml-56">{code}</div>
            <div className="absolute inset-y right-4 ">
              {isCopied ? (
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-4 lg:w-6 h-4 lg:h-6 text-blue-500 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                </span>
              ) : (
                <BasicButton
                  text={"copy"}
                  size={"medium"}
                  onClick={copy}
                  className="bg-blue-500 text-white px-3 py-2 font-medium"
                />
              )}
            </div>
          </div>
          <Input
            type={mode}
            label={"현장명칭"}
            placeholder={projectDetail?.name || "로딩중"}
            value={projectDetail?.name || "로딩중"}
            className=" h-16 lg:text-lg border-b-0"
            register={register("name")}
          />
          {isEdit ? (
            <Input
              type={"select"}
              label={"공사유형"}
              options={[
                "공사유형",
                "공동주택",
                "종교시설",
                "판매시설",
                "창고시설",
              ]}
              className="h-16 lg:text-lg border-b-0"
              register={register("constructionType")}
              errorMessage={errors?.constructionType?.message}
            />
          ) : (
            <Input
              type={"div"}
              label={"공사유형"}
              placeholder={projectDetail?.constructionType || "로딩중"}
              value={projectDetail?.constructionType || "로딩중"}
              className="h-16 lg:text-lg border-b-0"
            />
          )}
          <Input
            type={mode}
            label={"현장주소"}
            placeholder={projectDetail?.address || "로딩중"}
            value={projectDetail?.address || "로딩중"}
            className="h-16 lg:text-lg border-b-0"
            register={register("address")}
          />
          <Input
            type={mode}
            label={"공사시작"}
            placeholder={
              (projectDetail?.startDt &&
                format(projectDetail?.startDt, "yyyy년 MM월 dd일")) ||
              "로딩중"
            }
            value={
              (projectDetail?.startDt &&
                format(projectDetail?.startDt, "yyyy년 MM월 dd일")) ||
              "로딩중"
            }
            // register={register("startDt")}
            register={register("startDt", {
              pattern: {
                value:
                  /(19|20)\d{2}년 (0[1-9]|1[0-2])월 (0[1-9]|[12][0-9]|3[01])/,
                message: "날짜를 올바르게 입력해주세요",
              },
              maxLength: 13,
            })}
            maxLength={13}
            errorMessage={errors?.startDt?.message}
            className="h-16 lg:text-lg border-b-0"
          />
          <Input
            type={mode}
            label={"공사완료"}
            placeholder={
              (projectDetail?.endDt &&
                format(projectDetail?.endDt, "yyyy년 MM월 dd일")) ||
              "로딩중"
            }
            value={
              (projectDetail?.endDt &&
                format(projectDetail?.endDt, "yyyy년 MM월 dd일")) ||
              "로딩중"
            }
            className="h-16 lg:text-lg border-b-0"
            register={register("endDt", {
              pattern: {
                value:
                  /(19|20)\d{2}년 (0[1-9]|1[0-2])월 (0[1-9]|[12][0-9]|3[01])/,
                message: "날짜를 올바르게 입력해주세요",
              },
              maxLength: 13,
            })}
            maxLength={13}
            errorMessage={errors?.endDt?.message}
          />
          <Input
            type={mode}
            label={"안전감시단 투입공수"}
            placeholder={projectDetail?.manMonth || "로딩중"}
            value={projectDetail?.manMonth || "로딩중"}
            className="h-16 lg:text-lg border-b-0"
            register={register("manMonth")}
          />
          <Input
            type={mode}
            label={"책임자명"}
            placeholder={projectDetail?.picName || "로딩중"}
            value={projectDetail?.picName || "로딩중"}
            className="h-16 lg:text-lg border-b-0"
            register={register("picName")}
          />
          <Input
            type={mode}
            label={"책임자 연락처"}
            placeholder={projectDetail?.picPhone || "로딩중"}
            value={projectDetail?.picPhone || "로딩중"}
            className="h-16 lg:text-lg border-b-0"
            maxLength={12}
            register={register("picPhone", {
              maxLength: 12,
              pattern: {
                value: /^\d{11,12}$/,
                message: "연락처를 올바르게 입력해주세요",
              },
            })}
            errorMessage={errors?.picPhone?.message}
          />
          <Input
            type={mode}
            label={"팀장명"}
            placeholder={projectDetail?.leaderName || "로딩중"}
            value={projectDetail?.leaderName || "로딩중"}
            className="h-16 lg:text-lg border-b-0"
            register={register("leaderName")}
          />
          <Input
            type={mode}
            label={"팀장 연락처"}
            placeholder={projectDetail?.leaderPhone || "로딩중"}
            value={projectDetail?.leaderPhone || "로딩중"}
            className="h-16 lg:text-lg rounded-b-lg"
            maxLength={12}
            register={register("picPhone", {
              maxLength: 12,
              pattern: {
                value: /^\d{11,12}$/,
                message: "연락처를 올바르게 입력해주세요",
              },
            })}
            errorMessage={errors?.picPhone?.message}
          />
          <div
            className={cls(
              "flex justify-between space-x-4",
              isEdit ? "mt-5" : "",
            )}
          >
            {isEdit ? (
              <>
                {renderButton({
                  text: "취소",
                  bgColor: "bg-neutral-300",
                  hoverBgColor: "hover:bg-neutral-200",
                  activeBgColor: "active:bg-neutral-400",
                  textColor: "text-gray-600",
                  className: "!py-4",
                  type: "button",
                  onClick: () => (isEdit ? setIsEdit(false) : ""),
                })}
                {renderButton({
                  text: "완료",
                  type: "submit",
                  bgColor: "bg-blue-500",
                  hoverBgColor: "hover:bg-blue-400",
                  activeBgColor: "active:bg-blue-600",
                  textColor: "text-white w-1/4",
                  className: "!py-4",
                  onClick: () => {
                    setTimeout(() => {
                      dispatch(setModalOpen(false));
                    }, 200);
                  },
                })}
              </>
            ) : (
              <>
                {/*<BasicButton*/}
                {/*  text={"삭제"}*/}
                {/*  size={"lg"}*/}
                {/*  onClick={onClickDeleteBtn}*/}
                {/*  className="w-1/6 bg-neutral-300 hover:bg-neutral-200 active:bg-neutral-400"*/}
                {/*/>*/}
                {/*<BasicButton text={"수정"} size={"lg"} onClick={onClick} />*/}
                {renderButton({
                  text: "삭제",
                  bgColor: "bg-neutral-300",
                  hoverBgColor: "hover:bg-neutral-200",
                  activeBgColor: "active:bg-neutral-400",
                  textColor: "text-white",
                  className: "mt-5 !py-4",
                  type: "button",
                  onClick: () => onClickDeleteBtn(),
                })}
                <div className="space-x-3">
                  {renderButton({
                    text: "수정",
                    bgColor: "bg-blue-500",
                    hoverBgColor: "hover:bg-blue-400",
                    activeBgColor: "active:bg-blue-600",
                    textColor: "text-white",
                    className: "mt-5 !py-4",
                    type: "button",
                    onClick: () => onClick(),
                  })}
                  {renderButton({
                    text: "설정",
                    bgColor: "bg-emerald-300",
                    hoverBgColor: "hover:bg-emerald-200",
                    activeBgColor: "active:bg-emerald-300",
                    textColor: "text-emerald-700",
                    className: "mt-5 !py-4",
                    type: "button",
                    onClick: () => onHandleActive(),
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default Detail;
