import React, { useEffect, useState } from "react";
import { InfiniteQueryObserverResult } from "@tanstack/react-query";
import { observe } from "web-vitals/dist/modules/lib/observe";
interface IuseIntersectionObserverProps {
  threshold?: number;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => Promise<InfiniteQueryObserverResult>;
}
const UseIntersectionObserver = ({
  threshold = 0.1,
  hasNextPage,
  fetchNextPage,
}: IuseIntersectionObserverProps) => {
  const [target, setTarget] = useState<HTMLDivElement | null | undefined>(null);

  const observerCallback: IntersectionObserverCallback = (entries) => {
    console.log("👀");
    if (entries[0].isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (!target) return;
    const observer = new IntersectionObserver(observerCallback, {
      threshold,
    });
    observer.observe(target);

    return () => observer.unobserve(target);
  }, [observerCallback, threshold, target]);

  return { setTarget };
};

export default UseIntersectionObserver;
