export const calendarFns = {
  startOfMonth: (date: Date) =>
    new Date(date.getFullYear(), date.getMonth(), 1),

  endOfMonth: (date: Date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  daysOfMonth: (endDate: Date, startDate: Date) =>
    (endDate.valueOf() - startDate.valueOf()) / (1000 * 60 * 60 * 24) + 1,

  formatMonth: (date: Date) => {
    const months = [
      "1월",
      "2월",
      "3월",
      "4월",
      "5월",
      "6월",
      "7월",
      "8월",
      "9월",
      "10월",
      "11월",
      "12월",
    ];
    const year = date.getFullYear();
    const month = months[date.getMonth()];

    return ` ${year}년 ${month}`;
  },

  formatToday: (date: Date) => {
    const months = [
      "1월",
      "2월",
      "3월",
      "4월",
      "5월",
      "6월",
      "7월",
      "8월",
      "9월",
      "10월",
      "11월",
      "12월",
    ];
    const month = months[date.getMonth()];
    const day = date.getDate();

    return ` ${month} ${day}일`;
  },

  sub: (date: Date, months: number) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - months);
    return newDate;
  },

  add: (date: Date, months: number) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  },

  setDate: (date: Date, dayOfMonth: number) => {
    const newDate = new Date(date);
    newDate.setDate(dayOfMonth);
    return newDate;
  },

  formatDate: (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}. ${month}. ${day}`;
  },
};
