import React, { ReactElement } from "react";
import { cls, tagValue, taskStateColor } from "../libs/utils";
import Tag from "./tag";
import { TASK_STATE } from "../libs/constants";
interface TaskContent {
  title: string | ReactElement;
  location: string;
  primary?: string;
  sub?: string;
  startTime?: string;
  endTime?: string;
  status?: TASK_STATE;
  writer?: string;
  regDate?: string;
}
interface TaskBoxProps {
  icon?: React.ReactElement | null;
  content: TaskContent;
  onClick?: (event: React.MouseEvent) => void;
  type: "task" | "report";
  boxClassName?: string;
  iconClassName?: string;
  admin?: boolean;
}

const TaskBox = ({
  icon,
  content,
  onClick,
  type,
  boxClassName,
  iconClassName,
  admin = false,
}: TaskBoxProps) => {
  return (
    <div
      onClick={onClick}
      className={cls(
        "h-20 rounded-lg p-3 hover:scale-95 transition-all cursor-pointer",
        boxClassName ? boxClassName : "",
      )}
    >
      <p className="flex items-center justify-between text-gray-950 font-medium pl-2">
        <span className="flex items-center space-x-1">
          <span className={iconClassName && cls(iconClassName)}>{icon}</span>
          {/*<span>{type === "task" ? content.title : content.location}</span>*/}
          <span>{content.location}</span>
        </span>
        {type === "task" ? (
          <span>
            {content.writer}
            {/*{content.sub ? "/" : ""} {content.primary}*/}
          </span>
        ) : content.regDate ? (
          <span className="text-xs md:text-base">{content.regDate}</span>
        ) : (
          <></>
        )}
      </p>
      <div className="flex items-center justify-between text-gray-950 font-semibold pl-2">
        <span className="flex items-center text-gray-950 font-semibold space-x-3 py-1">
          <span className={cls(type === "task" ? "text-xl" : "text-lg")}>
            {/*{type === "task" ? (*/}
            {/*  content.location*/}
            {/*) :*/}

            {content.status && admin ? (
              // {content.status ? (
              <div className="flex items-center">
                {content.title}
                <span className="text-xs ml-2">
                  <Tag
                    color={taskStateColor(content.status || "대기")}
                    title={content.status || ""}
                  />
                </span>
              </div>
            ) : (
              content.title
            )}
          </span>
        </span>
        {type === "task" ? (
          <span>
            {content.startTime} {content.startTime ? "-" : ""} {content.endTime}
          </span>
        ) : content.writer ? (
          <span>{content.writer}</span>
        ) : (
          <Tag
            color={taskStateColor(content.status || "대기")}
            title={content.status || ""}
          />
        )}
      </div>
    </div>
  );
};

export default TaskBox;
