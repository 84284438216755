import React, { useEffect, useState } from "react";
import { cls } from "../../libs/utils";

import { Link, useLocation, useNavigate } from "react-router-dom";
import TabItem from "../tabItem";
import Menu from "./menu";
import Transition from "../transition";
import { useQuery } from "@tanstack/react-query";
import { AdminDataApi } from "../../api/AdminDataApi";
import { getCookie } from "../../libs/cookies";
import Tag from "../tag";

interface LayoutProps {
  title?: string;
  hasTopBar?: boolean;
  canGoBack?: boolean;
  hasTabBar?: boolean;
  children: React.ReactNode;
}

export default function Layout({
  title,
  hasTopBar = true,
  canGoBack = true,
  hasTabBar = true,
  children,
}: LayoutProps) {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const onClick = () => {
    navigate(-1);
  };
  const [menuOpen, setMenuOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const projectId = getCookie("projectId");
  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ["getProjectDetail"],
    queryFn: () => AdminDataApi.getProjectDetail(projectId || ""),
    enabled: projectId > 0,
  });
  useEffect(() => {
    if (data && data.code === "OK") {
      setProjectName(data.data.name);
    }
  }, [data]);

  useEffect(() => {
    const accessToken = getCookie("accessToken");
    const refreshToken = getCookie("refreshToken");
    const auth = getCookie("auth");
    if (!accessToken || !refreshToken || !auth) {
      if (location !== "/join") {
        navigate("/", { replace: true });
      }
    }
  }, []);

  return (
    <>
      <Menu menuOpen={menuOpen} onClose={() => setMenuOpen(false)} />

      <div className="flex flex-col md:w-full md:max-w-full max-w-xl mx-auto min-h-screen bg-gradient-to-b from-white from-5% to-slate-100">
        <div className="hidden md:flex fixed top-0 z-10 h-20 w-full px-4 py-2 items-center space-x-6 bg-white/90">
          <div
            className="relative flex flex-col text-4xl font-extrabold ml-3 text-blue-600 cursor-pointer"
            onClick={() => navigate("/admin/home")}
          >
            UNIS.
            <span className="absolute top-8 text-black text-sm">
              Bluesignal AI
            </span>
          </div>
          <div className="flex-1 flex justify-center">
            <ul className="flex items-center mx-auto space-x-6 xl:space-x-20 text-lg lg:text-xl font-semibold whitespace-nowrap">
              <li
                onClick={() => navigate("/admin/task")}
                className={cls(
                  "cursor-pointer",
                  location.includes("/admin/task")
                    ? "text-blue-500"
                    : "hover:text-gray-500 transition-colors",
                )}
              >
                업무 관리
              </li>
              <li
                onClick={() => navigate("/admin/report")}
                className={cls(
                  "cursor-pointer",
                  location.includes("/admin/report")
                    ? "text-blue-500"
                    : "hover:text-gray-500 transition-colors",
                )}
              >
                안전 보고
              </li>
              <li
                onClick={() => navigate("/admin/video")}
                className={cls(
                  "cursor-pointer",
                  location.includes("/admin/video")
                    ? "text-blue-500"
                    : "hover:text-gray-500 transition-colors",
                )}
              >
                영상 보고
              </li>
              <li
                onClick={() => navigate("/admin/uniscam")}
                className={cls(
                  "cursor-pointer",
                  location.includes("/admin/uniscam")
                    ? "text-blue-500"
                    : "hover:text-gray-500 transition-colors",
                )}
              >
                유니슨 캠
              </li>
              {/*<li*/}
              {/*  onClick={() => navigate("/admin/member")}*/}
              {/*  className={cls(*/}
              {/*    "cursor-pointer",*/}
              {/*    location.includes("/admin/member")*/}
              {/*      ? "text-blue-500"*/}
              {/*      : "hover:text-gray-500 transition-colors",*/}
              {/*  )}*/}
              {/*>*/}
              {/*  감시단 관리*/}
              {/*</li>*/}
              <li
                onClick={() => navigate("/admin/project")}
                className={cls(
                  "cursor-pointer",
                  location.includes("/admin/project")
                    ? "text-blue-500"
                    : "hover:text-gray-500 transition-colors",
                )}
              >
                프로젝트 관리
              </li>
              {/*<li*/}
              {/*  onClick={() => navigate("/admin/account")}*/}
              {/*  className={cls(*/}
              {/*    "cursor-pointer",*/}
              {/*    location.includes("/admin/account")*/}
              {/*      ? "text-blue-500"*/}
              {/*      : "hover:text-gray-500 transition-colors",*/}
              {/*  )}*/}
              {/*>*/}
              {/*  계정 관리*/}
              {/*</li>*/}
            </ul>
          </div>
          <div className="hidden lg:flex flex-col justify-center items-center *:text-xs md:*:text-base">
            <Tag color={"green"} title={projectName} />
          </div>
        </div>
        {hasTopBar ? (
          <div className="md:hidden fixed bg-white w-full h-12 max-w-xl justify-center text-lg px-10 font-medium text-gray-800 border-b top-0 flex items-center z-10">
            {canGoBack ? (
              <button onClick={onClick} className="absolute left-4">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
              </button>
            ) : null}
            {title ? (
              <span className={cls(canGoBack ? "mx-auto" : "", "")}>
                {title}
              </span>
            ) : null}
            <button
              onClick={() => setMenuOpen(true)}
              className="absolute right-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
            {/*</div>    */}
            {/*<button*/}
            {/*  onClick={() => navigate("/admin/account")}*/}
            {/*  className="absolute right-4 hover:animate-spin"*/}
            {/*>*/}
            {/*  <svg*/}
            {/*    xmlns="http://www.w3.org/2000/svg"*/}
            {/*    fill="none"*/}
            {/*    viewBox="0 0 24 24"*/}
            {/*    strokeWidth={1.5}*/}
            {/*    stroke="currentColor"*/}
            {/*    className="w-6 h-6"*/}
            {/*  >*/}
            {/*    <path*/}
            {/*      strokeLinecap="round"*/}
            {/*      strokeLinejoin="round"*/}
            {/*      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"*/}
            {/*    />*/}
            {/*    <path*/}
            {/*      strokeLinecap="round"*/}
            {/*      strokeLinejoin="round"*/}
            {/*      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"*/}
            {/*    />*/}
            {/*  </svg>*/}
            {/*</button>*/}
          </div>
        ) : null}
        <div
          className={cls(
            "md:pt-20 md:max-w-full md:w-[1400px] md:mx-auto px-3",
            hasTabBar ? "pb-24" : "",
            hasTopBar ? "pt-12" : "",
          )}
        >
          <Transition>{children}</Transition>
        </div>
        {hasTabBar ? (
          <nav className="md:hidden bg-white max-w-xl text-gray-700 border-t fixed bottom-0 w-full px-3 pb-2 pt-3 flex justify-between text-xs z-50">
            <TabItem
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                  />
                </svg>
              }
              url={"/admin/task"}
              title={"업무 관리"}
              location={location}
            />
            {/*<TabItem*/}
            {/*  icon={*/}
            {/*    <svg*/}
            {/*      xmlns="http://www.w3.org/2000/svg"*/}
            {/*      fill="none"*/}
            {/*      viewBox="0 0 24 24"*/}
            {/*      strokeWidth={1.5}*/}
            {/*      stroke="currentColor"*/}
            {/*      className="w-6 h-6"*/}
            {/*    >*/}
            {/*      <path*/}
            {/*        strokeLinecap="round"*/}
            {/*        strokeLinejoin="round"*/}
            {/*        d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"*/}
            {/*      />*/}
            {/*    </svg>*/}
            {/*  }*/}
            {/*  url={"/admin/report"}*/}
            {/*  title={"안전 보고"}*/}
            {/*  location={location}*/}
            {/*/>*/}

            <TabItem
              icon={
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  ></path>
                </svg>
              }
              url={"/admin/home"}
              title={"홈"}
              location={location}
            />
            <TabItem
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
                  />
                </svg>
              }
              url={"/admin/video"}
              title={"영상 보고"}
              location={location}
            />
            {/*<TabItem*/}
            {/*  icon={*/}
            {/*    <svg*/}
            {/*      xmlns="http://www.w3.org/2000/svg"*/}
            {/*      fill="none"*/}
            {/*      viewBox="0 0 24 24"*/}
            {/*      strokeWidth={1.5}*/}
            {/*      stroke="currentColor"*/}
            {/*      className="w-6 h-6"*/}
            {/*    >*/}
            {/*      <path*/}
            {/*        strokeLinecap="round"*/}
            {/*        strokeLinejoin="round"*/}
            {/*        d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"*/}
            {/*      />*/}
            {/*    </svg>*/}
            {/*  }*/}
            {/*  url={"/admin/member"}*/}
            {/*  title={"프로젝트"}*/}
            {/*  location={location}*/}
            {/*/>*/}
            {/*<TabItem*/}
            {/*  icon={*/}
            {/*    <svg*/}
            {/*      xmlns="http://www.w3.org/2000/svg"*/}
            {/*      fill="none"*/}
            {/*      viewBox="0 0 24 24"*/}
            {/*      strokeWidth={1.5}*/}
            {/*      stroke="currentColor"*/}
            {/*      className="w-6 h-6"*/}
            {/*    >*/}
            {/*      <path*/}
            {/*        strokeLinecap="round"*/}
            {/*        strokeLinejoin="round"*/}
            {/*        d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"*/}
            {/*      />*/}
            {/*    </svg>*/}
            {/*  }*/}
            {/*  url={"/admin/member"}*/}
            {/*  title={"감시단"}*/}
            {/*  location={location}*/}
            {/*/>*/}

            <TabItem
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
              }
              url={"/admin/uniscam"}
              title={"유니슨캠"}
              location={location}
            />
          </nav>
        ) : null}
      </div>
    </>
  );
}
