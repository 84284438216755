import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    modalOpen: false,
    mediaModalOpen: false,
  },
  reducers: {
    setModalOpen: (state, action) => {
      state.modalOpen = action.payload;
    },
    setMediaModalOpen: (state, action) => {
      state.mediaModalOpen = action.payload;
    },
  },
});

export default modalSlice.reducer;
export const { setModalOpen, setMediaModalOpen } = modalSlice.actions;
