import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setModalOpen } from "../store/features/modalSlice";
import { cls } from "../libs/utils";

const Modal = ({
  title,
  content,
  className,
}: {
  title?: string;
  content?: React.ReactElement | null;
  className?: string;
}) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setIsVisible(false);
      setTimeout(() => {
        dispatch(setModalOpen(false));
      }, 200);
    }
  };
  const handleCloseBtnClick = () => {
    setIsVisible(false);

    setTimeout(() => {
      dispatch(setModalOpen(false));
    }, 200);
  };

  return (
    <div
      className={cls(
        "h-full w-full fixed left-0 top-0 flex justify-center items-center bg-black/60 z-50 animate-fade-in",
        isVisible ? "animate-fade-in" : "animate-fade-out",
      )}
      onClick={(e) => handleBackgroundClick(e)}
    >
      <div
        className={cls(
          "relative flex flex-col z-60 rounded bg-white w-full h-full lg:w-1/2 lg:h-4/5 pb-24 lg:pb-0",
          className ? className : "",
        )}
      >
        <div className="flex justify-between sticky top-0 z-10 bg-white px-5 py-4 lg:rounded-t">
          <p className="text-xl lg:text-2xl font-semibold">{title}</p>
          <button onClick={() => handleCloseBtnClick()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6 text-blue-500"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="px-5 py-3 overflow-y-auto">{content}</div>
      </div>
    </div>
  );
};

export default Modal;
