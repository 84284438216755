import React, { useEffect, useState, useRef, useMemo } from "react";
import StatusTab from "../../../../component/statusTab";
import Input from "../../../../component/input";
import { ReactComponent as IcFinder } from "../../../../asset/icon/ic_finder.svg";
import TaskBox from "../../../../component/taskBox";
import { ReactComponent as IcDnager } from "../../../../asset/icon/ic_danger.svg";
import { ReactComponent as IcInspectation } from "../../../../asset/icon/ic_inspection.svg";
import { ReactComponent as IcPatrol } from "../../../../asset/icon/ic_patrol.svg";
import { useNavigate } from "react-router-dom";
import Tag from "../../../../component/tag";
import { IAdminReportData } from "./web";
import report from "../../../user/report/report";
import { taskStateBoxBg } from "../../../../libs/utils";
import { REPORT_TYPE, TASK_STATE } from "../../../../libs/constants";
import { useForm } from "react-hook-form";
import { IReportListParam } from "../../../user/report/reports";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { AdminDataApi } from "../../../../api/AdminDataApi";
import { getCookie } from "../../../../libs/cookies";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import useIntersectionObserver from "../../../../libs/useIntersectionObserver";
import { setPageNum } from "../../../../store/features/pageSlice";
const Mobile = (
  {
    // reports,
    // setSearchWord,
    // totalPage,
  }: {
    // reports: IAdminReportData[];
    // setSearchWord: React.Dispatch<React.SetStateAction<string>>;
    // totalPage: number;
  },
) => {
  const dispatch = useDispatch();
  const observer = useRef<IntersectionObserver>();
  const [status, setStatus] = useState("all");
  const [listMode, setListMode] = useState(true);
  // isPending;
  // isApproved;
  // isRejected;
  const handleClickTab = (type: string) => {
    setStatus(type);
  };
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<IReportListParam>();
  const projectId = getCookie("projectId");
  // const page = useSelector((state: RootState) => state.page.pageNum);
  const [searchWord, setSearchWord] = useState("");
  const initialReportListParam = {
    projectId: projectId,
    searchWord: "",
    page: 1,
  };
  const [params, setParams] = useState<IReportListParam>(
    initialReportListParam,
  );

  // const { data, refetch, isSuccess } = useQuery({
  //   queryKey: ["getAdminReports", params],
  //   queryFn: () => AdminDataApi.getReports(params),
  // });
  const visibleItemCount = 15;
  const {
    // data: adminReportScrollData,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isSuccess,
  } = useInfiniteQuery({
    queryKey: ["getAdminReportsScroll", params],
    initialPageParam: 1,
    queryFn: ({ pageParam }) =>
      AdminDataApi.getReports({ ...params, page: pageParam }),
    getNextPageParam: (lastPage, allPages) => {
      console.log("lastPage", lastPage);
      return lastPage?.data?.items?.length < visibleItemCount
        ? undefined
        : allPages.length + 1;
    },
  });

  const { setTarget } = useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  // useEffect(() => {
  //   console.log("adminReportScrollData", adminReportScrollData);
  // }, [adminReportScrollData]);

  // 확인
  useEffect(() => {
    console.log("보고서 리스트 ", data);
  }, [data]);

  const reports = useMemo(() => {
    const allReports = data?.pages?.flatMap((page) => page?.data?.items);
    console.log("통합된 데이터", allReports);
    return allReports;
  }, [data]);

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      projectId: projectId,
      searchWord: searchWord,
    }));
  }, [projectId, searchWord]);

  // 확인
  useEffect(() => {
    console.log(params);
  }, [params]);

  const onValid = (data: IReportListParam) => {
    console.log(data);
    setSearchWord(data.searchWord);
  };

  const handleClickReport = (
    taskId: number,
    reportId: number,
    reportType: REPORT_TYPE,
  ) => {
    navigate("detail", {
      state: {
        taskId: taskId,
        reportId: reportId,
        reportType: reportType,
      },
    });
  };

  return (
    <div className="lg:hidden">
      <div className="lg:hidden w-full mt-5 h-12 grid grid-cols-4 border-b border-gray-300 place-items-center font-semibold text-lg">
        <StatusTab
          title="전체"
          type="all"
          status={status}
          onClick={() => handleClickTab("all")}
        />
        <StatusTab
          title="대기"
          type="isPending"
          status={status}
          onClick={() => handleClickTab("isPending")}
        />
        <StatusTab
          title="승인"
          type="isApproved"
          status={status}
          onClick={() => handleClickTab("isApproved")}
        />
        <StatusTab
          title="반려"
          type="isRejected"
          status={status}
          onClick={() => handleClickTab("isRejected")}
        />
      </div>
      <form className="my-4 lg:w-1/3" onSubmit={handleSubmit(onValid)}>
        <Input
          type="text"
          placeholder="등록자명, 협력업체명, 공사종류, 재해유형"
          icon={
            <span className="text-gray-300">
              <IcFinder />
            </span>
          }
          className="rounded-xl mx-auto border-0 h-12 bg-lightGray"
          register={register("searchWord")}
        />
      </form>
      <>
        <div className="pb-5 px-3 bg-white space-y-2">
          {/*<div className="flex items-center h-9 text-gray-500 font-medium">*/}
          {/*  1월 10일*/}
          {/*</div>*/}
          <div className="space-y-3">
            {isSuccess &&
              reports &&
              // data?.data?.items &&
              // data?.data?.items &&
              // data?.data?.items.map(
              reports?.map((report: IAdminReportData, index: number) => (
                <TaskBox
                  key={`mobile_${report.name}_${index}`}
                  onClick={() =>
                    handleClickReport(
                      report.taskId || -1,
                      report.reportId || -1,
                      report.reportType || REPORT_TYPE.SAFE,
                    )
                  }
                  type={"report"}
                  content={{
                    title: report.name || "",
                    location: report.location || "",
                    writer: report.coworkerName || "",
                    regDate: `${report.date} ${report.startTime?.slice(0, 5)} - ${report.endTime?.slice(0, 5)}`,
                    status: report.state,
                  }}
                  boxClassName={taskStateBoxBg(
                    report.state || TASK_STATE.PLANNED,
                  )}
                  admin={true}
                />
              ))}
            <div ref={setTarget} className="h-1" />
          </div>
        </div>
      </>
    </div>
  );
};

export default Mobile;
