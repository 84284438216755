import React, { useEffect, useState } from "react";
import Layout from "../../../component/admin/layout";
import Web from "./list/web";
import Mobile from "./list/moblie";
import BasicButton from "../../../component/basicButton";
import Input from "../../../component/input";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { IProjectData } from "../project/register";
import { calendarFns } from "../../../utils/calendarFns";
import { IReportData } from "../../user/report/report";
import { renderButton } from "../../../component/statusButton";
import CreateFile, { SavedFileInfo } from "../../../component/createFile";
import { useMutation, useQuery } from "@tanstack/react-query";
import { UserDataApi } from "../../../api/UserDataApi";
import {
  REPORT_STATE,
  REPORT_TYPE,
  RISK_INDEX,
  TASK_STATE,
} from "../../../libs/constants";
import Tag from "../../../component/tag";
import { cls, tagValue, taskStateColor } from "../../../libs/utils";
import { AdminDataApi } from "../../../api/AdminDataApi";
import { format } from "date-fns";
import { getCookie, setCookie } from "../../../libs/cookies";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Modal from "../../../component/modal";
import refusalReason from "./refusalReason";
import RefusalReason from "./refusalReason";
import {
  setMediaModalOpen,
  setModalOpen,
} from "../../../store/features/modalSlice";
import Preview from "../../../component/preview";
import MediaModal, { IMediaModal } from "../../../component/mediaModal";
import { constants } from "http2";
import { IReportEdit } from "../../user/report/detail";

// 보고서 상태 값
// - 진행중 : 승인/반려 전
// - 완료 : 승인/반려 후
// - 수정 : 수정 모드

// 진행중인 보고서의 경우 (목록 수정 승인 반려)
// 목록 -> 목록으로 이동
// 수정 -> 감시단이 작성한 내용 수정 가능 (취소, 확인)
//         단, 위험 여부는 변경 불가
// 승인 -> 승인 완료 알람
// 반려 -> 반려 사유 모달 (취소, 승인)-> 승인 완료 알람

// 완료된 보고서의 경우 (목록 승인취소)
// 목록 -> 목록으로 이동
// 승인 취소 -> 대기 상태로 변경

// 수정 중인 보고서의 경우 (취소 완료)
// 취소 -> 수정 취소
// 완료 -> 수정 완료

export interface IReportState {
  id: number;
  state: string;
  refusalReason?: string;
}

const Detail = () => {
  const projectId = getCookie("projectId");
  const location = useLocation();
  const today = new Date();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalOpen = useSelector((state: RootState) => state.modal.modalOpen);
  const { taskId } = location.state || {};
  const { reportId } = location.state || {};
  const { reportType } = location.state || {};

  const [isEdit, setIsEdit] = useState(false);
  const [state, setState] = useState<REPORT_STATE>("");
  const mode = isEdit ? "text" : "div";
  const [deleteFileIds, setDeleteFileIds] = useState<number[]>([]);
  const [deleteFilePaths, setDeleteFilePaths] = useState<string[]>([]);
  const [savedFiles, setSavedFiles] = useState<SavedFileInfo[]>();
  const [savedRiskFiles, setSavedRiskFiles] = useState<SavedFileInfo[]>();
  const [savedImprovedFiles, setSavedImprovedFiles] =
    useState<SavedFileInfo[]>();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedRiskFiles, setSelectedRiskFiles] = useState<File[]>([]);
  const [selectedImprovedFiles, setSelectedImprovedFiles] = useState<File[]>(
    [],
  );

  const [params, setParams] = useState<IReportEdit>();
  const [riskFiles, setRisFiles] = useState("");
  const [refusalReason, setRefusalReason] = useState("");
  const [taskBasicData, setTaskBasicData] = useState<IReportData>({
    taskName: "",
    location: "",
    date: "",
    coworkerName: "",
    safetyType: "",
    majorCategory: "",
    middleCategory: "",
    subcontractorName: "",
    workerNum: "",
    state: "",
  });
  const mediaModalOpen = useSelector(
    (state: RootState) => state.modal.mediaModalOpen,
  );
  const initialMediaModal = {
    path: "",
    title: "",
    type: "video" as "video",
  };

  const [media, setMedia] = useState<IMediaModal>(initialMediaModal);

  const [hasImproved, setHasImproved] = useState(false);
  console.log("잘 왔어요!", taskId, reportId, reportType);
  const { data: taskData, refetch: userRefetchTaskDetail } = useQuery({
    queryKey: ["getTaskDetail", taskId],
    queryFn: () => AdminDataApi.getAdminTaskDetail(taskId),
    enabled: !!taskId,
  });

  const {
    data: adminReportDetailData,
    refetch: adminRefetchReportDetail,
    isSuccess: adminReportDetailSuccess,
  } = useQuery({
    queryKey: ["getReportDetail", reportId],
    queryFn: () => AdminDataApi.getReportDetail(reportId, reportType),
    enabled: reportId > 0 && !!reportType,
  });

  useEffect(() => {
    if (taskData && taskData?.data) {
      setTaskBasicData((prev) => ({
        ...prev,
        name: taskData?.data?.name,
        location: taskData?.data?.location,
        date: taskData?.data?.date,
        coworkerName: taskData?.data?.coworkerName,
        safetyType: taskData?.data?.safetyType,
        majorCategory: taskData?.data?.majorCategory,
        middleCategory: taskData?.data?.middleCategory,
        subcontractorName: taskData?.data?.subcontractorName,
        workerNum: taskData?.data?.workerNum,
        state: taskData?.data?.state,
      }));
      setValue("coworkerName", taskData?.data?.coworkerName);
      setValue("location", taskData?.data?.location);
      setValue("majorCategory", taskData?.data?.majorCategory);
      setValue("middleCategory", taskData?.data?.middleCategory);
      setValue("subcontractorName", taskData?.data?.subcontractorName);
      setValue("workerNum", taskData?.data?.workerNum);
    }
  }, [taskData]);

  useEffect(() => {
    console.log("adminReportDetailData", adminReportDetailData);
    if (adminReportDetailData) {
      setValue("point", adminReportDetailData?.data?.point);
      if (reportType === REPORT_TYPE.SAFE) {
        setValue("content", adminReportDetailData?.data?.content);
      } else if (reportType === REPORT_TYPE.DANGER) {
        setValue("riskIndex", adminReportDetailData?.data?.riskIndex);
        setValue("disasterType", adminReportDetailData?.data?.disasterType);
        setValue("riskFactors", adminReportDetailData?.data?.riskFactors);
        setValue("riskFiles", adminReportDetailData?.data?.riskFiles);
      }
      setSavedFiles(adminReportDetailData?.data?.files);
      if (
        adminReportDetailData?.data?.improvedContent &&
        adminReportDetailData?.data?.improvedContent.length !== 0 &&
        adminReportDetailData?.data?.improvedFiles &&
        adminReportDetailData?.data?.improvedFiles.length !== 0
      ) {
        setValue(
          "improvedContent",
          adminReportDetailData?.data?.improvedContent,
        );
        setHasImproved(true);
        setSavedRiskFiles(adminReportDetailData?.data?.improvedFiles);
      }
    }
  }, [adminReportDetailData]);

  useEffect(() => {
    console.log(savedFiles);
  }, [savedFiles]);

  const editState = useMutation<string, string, IReportState>({
    mutationFn: (param) => AdminDataApi.editState(param),
    onSuccess: (data: any) => {
      console.log("editState", data);
      if (data === "OK") {
        dispatch(setModalOpen(false));
        userRefetchTaskDetail();
        adminRefetchReportDetail();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    resetField,
    watch,
    setValue,
  } = useForm<IReportData>();

  const onValid = (data: IReportData) => {
    console.log("data", data);
    data.files = selectedFiles;
    data.riskFiles = selectedRiskFiles;
    data.improvedFiles = selectedImprovedFiles;
    const {
      content,
      point,
      files,
      riskIndex,
      disasterType,
      riskFactors,
      riskFiles,
      improvedFiles,
      improvedContent,
    } = data;
    setParams({
      projectId,
      reportId,
      files,
      point,
      deleteFileIds,
      deleteFilePaths,
      riskIndex,
      disasterType,
      riskFactors,
      riskFiles,
      content,
      improvedFiles,
      improvedContent,
    });
  };

  useEffect(() => {
    if (state && state.length > 0) {
      const params = {
        id: reportId,
        state: state,
        refusalReason: state === REPORT_STATE.REJECTED ? refusalReason : "",
      };

      editState.mutate(params);
    }
  }, [state]);

  useEffect(() => {
    if (refusalReason && refusalReason.length > 0) {
      console.log("refusalReason", refusalReason);
      setState(REPORT_STATE.REJECTED);
    }
  }, [refusalReason]);
  const handleClickMedia = (
    path: string,
    title: string,
    type: "video" | "image",
  ) => {
    setMedia((prev) => ({
      ...prev,
      path,
      title,
      type,
    }));
    dispatch(setMediaModalOpen(true));
  };

  return (
    <Layout
      title={"안전 보고"}
      hasTabBar={true}
      canGoBack={true}
      hasTopBar={true}
    >
      {mediaModalOpen && <MediaModal props={media} />}
      {modalOpen && (
        <Modal
          className="!h-fit !w-1/3"
          title={"반려 사유"}
          content={<RefusalReason setRefusalReason={setRefusalReason} />}
        />
      )}
      <form
        onSubmit={handleSubmit(onValid)}
        className="mt-4 lg:mt-10 lg:px-32 pb-10"
      >
        <div className="mt-4 lg:px-32 pb-10">
          <div className="w-full py-2 border border-gray-300 rounded-t-lg bg-white border-b-1 lg:px-5">
            <Tag
              color={taskStateColor(taskBasicData.state || TASK_STATE.PENDING)}
              title={taskBasicData.state || TASK_STATE.PENDING}
              reason={adminReportDetailData?.data?.refusalReason}
            />
            <div className="w-full py-3 flex justify-center items-center font-bold text-xl">
              <span>
                {taskBasicData.name} {reportType}보고
              </span>
            </div>
            <p className="flex justify-between font-semibold text-xs lg:text-base text-right px-4">
              <span>작성자 : {adminReportDetailData?.data?.authorName}</span>
              <span>
                일자 :
                {format(taskBasicData.date || new Date(), "yyyy년 MM월 dd일")}
              </span>
            </p>
          </div>
          <Input
            type={"div"}
            label={"동행자"}
            value={taskBasicData?.coworkerName}
            className="h-14 lg:text-lg border-t-0 border-b-1"
          />
          <Input
            type={"div"}
            label={"감시유형"}
            value={taskBasicData?.safetyType}
            className="h-14 lg:text-lg border-t-0 border-b-0"
          />
          <Input
            type={"div"}
            label={"작업명"}
            value={taskBasicData?.majorCategory}
            className="h-14 lg:text-lg border-b-0"
          />
          <Input
            type={"div"}
            label={"단위작업명"}
            value={taskBasicData.middleCategory}
            className="h-14 lg:text-lg border-b-0"
          />
          <Input
            type={"div"}
            label={"협력업체명"}
            value={taskBasicData?.subcontractorName}
            className="h-14 lg:text-lg border-b-0"
          />
          <Input
            type={"div"}
            label={"직업인원수"}
            value={taskBasicData?.workerNum}
            className="h-14 lg:text-lg border-b-1"
          />
          <div className="h-full border border-gray-300 border-t-0 border-b-0 py-7 px-4 lg:px-10 bg-white lg:text-lg">
            <p className="font-semibold pb-3 lg:text-lg">작업 현장</p>

            <div className="w-full flex items-center justify-start h-48 rounded-md overflow-x-auto overflow-y-hidden space-x-2">
              {adminReportDetailSuccess &&
                adminReportDetailData?.data &&
                adminReportDetailData?.data?.files.map(
                  (info: SavedFileInfo, index: number) => (
                    <Preview
                      key={`work_${index}`}
                      info={info}
                      index={index}
                      onClick={() =>
                        handleClickMedia(
                          info.path,
                          `${adminReportDetailData?.data?.name}_작업현장`,
                          info.type,
                        )
                      }
                    />
                    // <div
                    //   key={index}
                    //   className="relative h-32 aspect-video flex items-center justify-center cursor-pointer"
                    //   onClick={() => {
                    //     handleClickMedia(
                    //       info.path,
                    //       taskBasicData.name || "",
                    //       info.type,
                    //     );
                    //   }}
                    // >
                    //   {info.type.startsWith("video") ? (
                    //     <>
                    //       <video
                    //         src={`${process.env.REACT_APP_IMAGE_URL}${info.path}`}
                    //         className="h-full aspect-video rounded-lg"
                    //         autoPlay={false}
                    //         muted
                    //         loop
                    //       ></video>
                    //       <p className="absolute bottom-0 left-0 text-xs bg-gray-600 text-white p-1 w-full text-center rounded-b-lg">
                    //         {info.path.split("/").pop()}
                    //       </p>
                    //     </>
                    //   ) : (
                    //     <img
                    //       src={`${process.env.REACT_APP_IMAGE_URL}${info.path}`}
                    //       alt="preview"
                    //       className="h-full aspect-video rounded-lg"
                    //     />
                    //   )}
                    // </div>
                  ),
                )}
            </div>
          </div>
          <Input
            type={"div"}
            label={"세부위치"}
            value={adminReportDetailData?.data.point}
            className=" h-16 lg:text-lg border-b-0"
          />
          <Input
            type={"div"}
            label={"위험여부"}
            value={reportType}
            className=" h-16 lg:text-lg border-b-1"
          />
          {reportType === REPORT_TYPE.DANGER && (
            <>
              {isEdit ? (
                <>
                  <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">
                    <p className="font-semibold pb-3">위험 요인</p>
                    <CreateFile
                      name={"riskFiles"}
                      register={register("riskFiles")}
                      watch={watch}
                      savedFiles={savedRiskFiles}
                      setDeleteFileIds={setDeleteFileIds}
                      setDeleteFilePaths={setDeleteFilePaths}
                      errorMessage={errors.riskFiles?.message}
                      resetField={resetField}
                      setValue={setValue}
                      setSelectedFiles={setSelectedRiskFiles}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white lg:px-10">
                    <p className="font-semibold h-14 lg:text-lg text-gray-600">
                      위험 요인
                    </p>

                    <div className="w-full flex items-center justify-start h-48 rounded-md overflow-x-auto overflow-y-hidden space-x-2">
                      {adminReportDetailSuccess &&
                        adminReportDetailData &&
                        adminReportDetailData?.data?.riskFiles.map(
                          (info: SavedFileInfo, index: number) => (
                            <Preview
                              key={`preview_${index}`}
                              info={info}
                              index={index}
                              onClick={() =>
                                handleClickMedia(
                                  info.path,
                                  `${adminReportDetailData?.data?.name}_위험요인`,
                                  info.type,
                                )
                              }
                            />
                          ),
                        )}
                    </div>
                  </div>
                </>
              )}
              <Input
                type={isEdit ? "select" : "div"}
                label={"위험지수"}
                value={adminReportDetailData?.data.riskIndex}
                options={[
                  RISK_INDEX.URGENT,
                  RISK_INDEX.PRIORITY,
                  RISK_INDEX.REVIEW,
                ]}
                required={true}
                className={"h-14 border-t-0 border-b-1 lg:text-lg"}
                register={register("riskIndex", { required: true })}
              />
              <Input
                type={isEdit ? "text" : "div"}
                label={"재해유형"}
                placeholder={adminReportDetailData?.data.disasterType}
                value={adminReportDetailData?.data.disasterType}
                required={true}
                className={"h-14 border-t-0 border-b-1 lg:text-lg"}
                maxLength={5}
                register={register("disasterType", {
                  required: true,
                  maxLength: 5,
                })}
              />
              <Input
                type={isEdit ? "select" : "div"}
                label={"위험요인"}
                placeholder={adminReportDetailData?.data.riskFactors}
                value={adminReportDetailData?.data.riskFactors}
                required={true}
                maxLength={15}
                className={cls("h-14 border-t-0 border-b-1 lg:text-lg")}
                register={register("riskFactors", {
                  required: true,
                  maxLength: 15,
                })}
              />

              {/*{isFixed && (*/}
              {/*  <>*/}
              {/*    {isEdit ? (*/}
              {/*      <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">*/}
              {/*        <p className="font-semibold pb-3">개선 사항</p>*/}
              {/*        <CreateFile*/}
              {/*          name={"improvementFiles"}*/}
              {/*          register={register("improvementFiles", {*/}
              {/*            required: true,*/}
              {/*          })}*/}
              {/*          watch={watch}*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    ) : (*/}
              {/*      <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">*/}
              {/*        <p className="font-semibold pb-3">개선사항</p>*/}

              {/*        <img*/}
              {/*          src="http://www.sosforce.com/data/file/b05/3553003223_XTgNuGiR_092af9b5a9218df0f3f6d01cd3c2ea9d3946ffc5.jpg"*/}
              {/*          alt="danger"*/}
              {/*          className="w-full aspect-video rounded"*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*    <Input*/}
              {/*      type={mode}*/}
              {/*      label={"개선사항"}*/}
              {/*      placeholder={"안전망 설치"}*/}
              {/*      value={"안전망 설치"}*/}
              {/*      required={true}*/}
              {/*      maxLength={15}*/}
              {/*      className={"h-14 border-t-0 border-b-1 rounded-b-lg"}*/}
              {/*      // register={register("fixDescription", {*/}
              {/*      //   required: true,*/}
              {/*      //   maxLength: 15,*/}
              {/*      // })}*/}
              {/*    />*/}
              {/*  </>*/}
              {/*)}*/}
            </>
          )}
          {reportType === REPORT_TYPE.SAFE && (
            <div className="py-4 px-4 h-44 border border-gray-300 border-t-1 border-b-1 bg-white">
              <p className="text-gray-600 lg:pl-6 pb-2 font-semibold w-full lg:text-lg">
                점검내용
              </p>
              <textarea
                readOnly={!isEdit}
                className={cls(
                  "lg:mx-6 w-11/12 font-medium outline-none bg-gray-100 rounded-lg resize-none",
                  !isEdit ? "!bg-white" : "p-4",
                )}
                {...register("content", { required: true })}
              ></textarea>
            </div>
          )}
          {hasImproved && (
            <>
              {isEdit ? (
                <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">
                  <p className="font-semibold pb-3 lg:text-lg">개선 사항</p>
                  <CreateFile
                    name={"improvedFiles"}
                    register={register("improvedFiles")}
                    watch={watch}
                    savedFiles={savedImprovedFiles}
                    setDeleteFileIds={setDeleteFileIds}
                    setDeleteFilePaths={setDeleteFilePaths}
                    errorMessage={errors.improvedFiles?.message}
                    resetField={resetField}
                    setSelectedFiles={setSelectedImprovedFiles}
                  />
                </div>
              ) : (
                <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white lg:px-10">
                  <p className="font-semibold h-14 lg:text-lg text-gray-600">
                    개선 사항
                  </p>

                  <div className="w-full flex items-center justify-start h-48 rounded-md overflow-x-auto overflow-y-hidden space-x-2">
                    {adminReportDetailData &&
                      adminReportDetailData?.data?.improvedFiles.map(
                        (info: SavedFileInfo, index: number) => (
                          <Preview
                            key={`preview_${index}`}
                            info={info}
                            index={index}
                            onClick={() =>
                              handleClickMedia(
                                info.path,
                                `${adminReportDetailData?.data?.name}_개선 사항`,
                                info.type,
                              )
                            }
                          />
                        ),
                      )}
                  </div>
                </div>
              )}
              <Input
                type={isEdit ? "text" : "div"}
                label={"개선사항"}
                // placeholder={useReportDetailData?.data.improvedContent}
                value={adminReportDetailData?.data?.improvedContent}
                required={true}
                maxLength={15}
                className={"h-14 border-t-0 border-b-1 lg:text-lg"}
                register={register("improvedContent", {
                  required: true,
                })}
              />
            </>
          )}
          {/*{status === "pending" && (*/}
          {/*  <div className="flex flex-col py-4 px-4 h-36 border border-gray-300 border-t-0 bg-white lg:px-10">*/}
          {/*    <p className="pb-2 font-semibold w-full flex-nowrap lg:text-lg">*/}
          {/*      검토의견*/}
          {/*    </p>*/}
          {/*    <textarea*/}
          {/*      className="grow font-medium outline-none bg-gray-100 rounded-lg resize-none p-4"*/}
          {/*      cols={14}*/}
          {/*      {...register("opinion", )}*/}
          {/*    ></textarea>*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className="border-t-0 rounded-b-lg flex space-x-10 justify-between items-center w-full border border-gray-300 bg-white py-4 px-3 lg:px-10">
            {renderButton({
              text: isEdit ? "취소" : "목록",
              bgColor: "bg-lightGray",
              hoverBgColor: "hover:bg-lightGray/50",
              activeBgColor: "active:bg-gray-100",
              textColor: "text-gray-600",
              onClick: () => (isEdit ? setIsEdit(false) : navigate(-1)),
            })}

            <div className="flex space-x-4">
              {taskBasicData &&
                reportType === REPORT_TYPE.DANGER &&
                taskBasicData.state === TASK_STATE.PENDING &&
                !isEdit &&
                renderButton({
                  text: "개선 시작",
                  bgColor: "bg-blue-500/30",
                  hoverBgColor: "hover:bg-blue-500/20",
                  activeBgColor: "active:bg-blue-500/50",
                  textColor: "text-blue-700",
                  onClick: () => setState(REPORT_STATE.IMPROVING),
                })}
              {taskBasicData &&
                reportType === REPORT_TYPE.DANGER &&
                taskBasicData.state === TASK_STATE.IMPROVING &&
                !isEdit &&
                renderButton({
                  text: "개선 완료",
                  bgColor: "bg-blue-500/30",
                  hoverBgColor: "hover:bg-blue-500/20",
                  activeBgColor: "active:bg-blue-500/50",
                  textColor: "text-blue-700",
                  onClick: () => setState(REPORT_STATE.IMPROVED),
                })}

              {taskBasicData &&
                taskBasicData.state !== TASK_STATE.COMPLETED &&
                taskBasicData.state !== TASK_STATE.IMPROVING &&
                renderButton({
                  text: isEdit ? "완료" : "승인",
                  bgColor: "bg-green-500/30",
                  hoverBgColor: "hover:bg-green-500/20",
                  activeBgColor: "active:bg-green-500/50",
                  textColor: "text-green-700",
                  onClick: () => {
                    if (isEdit) {
                      adminRefetchReportDetail();
                    } else {
                      setState(REPORT_STATE.APPROVAL);
                    }
                  },
                })}
              {!isEdit &&
                taskBasicData.state !== TASK_STATE.IMPROVING &&
                taskBasicData.state !== TASK_STATE.COMPLETED &&
                taskBasicData.state !== TASK_STATE.REJECTED &&
                renderButton({
                  text: "반려",
                  bgColor: "bg-red-500/30",
                  hoverBgColor: "hover:bg-red-500/20",
                  activeBgColor: "active:bg-red-500/50",
                  textColor: "text-red-700",
                  onClick: () => {
                    dispatch(setModalOpen(true));
                  },
                })}
              {/*{taskBasicData &&*/}
              {/*  taskBasicData.state !== TASK_STATE.PLANNED &&*/}
              {/*  taskBasicData.state !== TASK_STATE.COMPLETED &&*/}
              {/*  taskBasicData.state !== TASK_STATE.IMPROVING &&*/}
              {/*  !isEdit &&*/}
              {/*  renderButton({*/}
              {/*    text: "수정",*/}
              {/*    bgColor: "bg-yellow-500/30",*/}
              {/*    hoverBgColor: "hover:bg-yellow-500/20",*/}
              {/*    activeBgColor: "active:bg-yellow-500/50",*/}
              {/*    textColor: "text-yellow-700",*/}
              {/*    onClick: () => setIsEdit(true),*/}
              {/*  })}*/}
            </div>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default Detail;
