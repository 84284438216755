import React, { useEffect, useState } from "react";
import Layout from "../../../component/layout";
import Tag from "../../../component/tag";
import BasicButton from "../../../component/basicButton";
import { cls, tagValue, taskStateColor } from "../../../libs/utils";
import Input from "../../../component/input";
import { useForm } from "react-hook-form";
import report, { IReportData } from "./report";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { renderButton } from "../../../component/statusButton";
import CreateFile, {
  FileInfo,
  SavedFileInfo,
} from "../../../component/createFile";
import { ITaskData } from "../../admin/task/register";
import { useQuery } from "@tanstack/react-query";
import { AdminDataApi } from "../../../api/AdminDataApi";
import { UserDataApi } from "../../../api/UserDataApi";
import { format } from "date-fns";
import { getCookie } from "../../../libs/cookies";
import {
  REPORT_STATE,
  REPORT_TYPE,
  RISK_INDEX,
  TASK_STATE,
} from "../../../libs/constants";
import { useMutation } from "@tanstack/react-query";
import {
  setMediaModalOpen,
  setModalOpen,
} from "../../../store/features/modalSlice";
import { IReportState } from "../../admin/report/detail";
import Preview from "../../../component/preview";
import MediaModal, { IMediaModal } from "../../../component/mediaModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";

export interface IReportEdit extends IReportData {
  deleteFileIds: number[];
  deleteFilePaths: string[];
}

const Detail = () => {
  const projectId = getCookie("projectId");
  const location = useLocation();

  const { taskId } = location.state || {};
  const { reportId } = location.state || {};
  const { reportType } = location.state || {};
  const { improved } = location.state || {};

  const [state, setState] = useState<REPORT_STATE>("");
  const [isEdit, setIsEdit] = useState(false);
  const [isImprovedEdit, setIsImporvedEdit] = useState(false);
  const navigate = useNavigate();
  const [taskBasicData, setTaskBasicData] = useState<IReportData>({
    taskName: "",
    location: "",
    date: "",
    coworkerName: "",
    safetyType: "",
    majorCategory: "",
    middleCategory: "",
    subcontractorName: "",
    workerNum: "",
    state: "",
  });
  const [savedFiles, setSavedFiles] = useState<SavedFileInfo[]>();
  const [savedRiskFiles, setSavedRiskFiles] = useState<SavedFileInfo[]>();
  const [savedImprovedFiles, setSavedImprovedFiles] =
    useState<SavedFileInfo[]>();
  const [deleteFileIds, setDeleteFileIds] = useState<number[]>([]);
  const [deleteFilePaths, setDeleteFilePaths] = useState<string[]>([]);
  const [hasImproved, setHasImproved] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedRiskFiles, setSelectedRiskFiles] = useState<File[]>([]);
  const [selectedImprovedFiles, setSelectedImprovedFiles] = useState<File[]>(
    [],
  );
  const [params, setParams] = useState<IReportEdit>();
  const mediaModalOpen = useSelector(
    (state: RootState) => state.modal.mediaModalOpen,
  );
  const initialMediaModal = {
    path: "",
    title: "",
    type: "video" as "video",
  };
  const dispatch = useDispatch();
  const [media, setMedia] = useState<IMediaModal>(initialMediaModal);
  // console.log("잘 왔어요! taskId", taskId);
  // console.log("잘 왔어요! reportId", reportId);
  // console.log("잘 왔어요! reportType", reportType);
  // console.log("잘 왔어요! improved", improved);

  const { data: taskData, refetch: userRefetchTaskDetail } = useQuery({
    queryKey: ["getTaskDetail", taskId],
    queryFn: () => UserDataApi.getTaskDetail(taskId),
    enabled: !!taskId,
  });

  useEffect(() => {
    setDeleteFileIds([]);
    setDeleteFilePaths([]);
    setSelectedFiles([]);
    setSelectedRiskFiles([]);
    setSelectedImprovedFiles([]);
  }, []);

  const {
    data: useReportDetailData,
    refetch: userRefetchReportDetail,
    isSuccess: userReportDetailSuccess,
  } = useQuery({
    queryKey: ["getReportDetail", reportId],
    queryFn: () => UserDataApi.getReportDetail(reportId, reportType),
    enabled: reportId > 0 && !!reportType,
  });

  const { mutate: editReportSafety, isPending: editReportSafetyLoading } =
    useMutation<string, string, IReportEdit>({
      mutationFn: (params) => UserDataApi.editReportSafety(params),
      onSuccess: (data: any) => {
        console.log("editReportSafety", data);
        if (data.code === "OK") {
          setIsEdit(false);
          userRefetchReportDetail();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const { mutate: editReportRisk, isPending: editReportRiskLoading } =
    useMutation<string, string, IReportEdit>({
      mutationFn: (params) => UserDataApi.editReportRisk(params),
      onSuccess: (data: any) => {
        console.log("editReportRisk", data);
        if (data.code === "OK") {
          setIsEdit(false);
          setIsImporvedEdit(false);
          userRefetchReportDetail();
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  useEffect(() => {
    if (taskData && taskData?.data) {
      setState(taskData?.data?.state);
      setTaskBasicData((prev) => ({
        ...prev,
        name: taskData.data.name,
        location: taskData.data.location,
        date: taskData.data.date,
        coworkerName: taskData.data.coworkerName,
        safetyType: taskData.data.safetyType,
        majorCategory: taskData.data.majorCategory,
        middleCategory: taskData.data.middleCategory,
        subcontractorName: taskData.data.subcontractorName,
        workerNum: taskData.data.workerNum,
        state: taskData.data.state,
      }));
      setValue("coworkerName", taskData.data.coworkerName);
      setValue("location", taskData.data.location);
      setValue("safetyType", taskData.data.safetyType);
      setValue("majorCategory", taskData.data.majorCategory);
      setValue("middleCategory", taskData.data.middleCategory);
      setValue("subcontractorName", taskData.data.subcontractorName);
      setValue("workerNum", taskData.data.workerNum);
    }
  }, [taskData]);

  useEffect(() => {
    console.log("useReportDetailData", useReportDetailData);
    if (useReportDetailData) {
      setValue("point", useReportDetailData.data.point);
      if (reportType === REPORT_TYPE.SAFE) {
        setValue("content", useReportDetailData.data.content);
      } else if (reportType === REPORT_TYPE.DANGER) {
        setValue("riskIndex", useReportDetailData.data.riskIndex);
        setValue("disasterType", useReportDetailData.data.disasterType);
        setValue("riskFactors", useReportDetailData.data.riskFactors);
        setSavedRiskFiles(useReportDetailData.data.riskFiles);
        if (
          useReportDetailData.data.improvedContent &&
          useReportDetailData.data.improvedContent.length !== 0 &&
          useReportDetailData.data.improvedFiles &&
          useReportDetailData.data.improvedFiles.length !== 0
        ) {
          setValue("improvedContent", useReportDetailData.data.improvedContent);
          setHasImproved(true);
          setSavedImprovedFiles(useReportDetailData.data.improvedFiles);
        }
      }
      setSavedFiles(useReportDetailData.data.files);
    }
  }, [useReportDetailData]);

  // 확인
  useEffect(() => {
    console.log("hasImproved", hasImproved);
  }, [hasImproved]);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    reset,
    setValue,
    resetField,
  } = useForm<IReportData>();

  // useEffect(() => {
  //   reset();
  // }, [reportType]);

  // const watchForm = watch();
  // const allFieldsFilled =
  //   Object.entries(watchForm).length > 0 &&
  //   Object.values(watchForm).every((field) => field);

  const onValid = (data: IReportData) => {
    console.log("data", data);
    // if (savedFiles && savedFiles.length === 0 && selectedFiles.length === 0) {
    //   setError("files", {
    //     message: "작업현장 사진을 한 장 이상 첨부해주세요",
    //   });
    // }
    data.files = selectedFiles;
    // if (
    //   reportType === REPORT_TYPE.DANGER &&
    //   savedRiskFiles &&
    //   savedFiles &&
    //   savedRiskFiles.length + savedFiles?.length === deleteFileIds.length &&
    //   data.files &&
    //   data.files.length === 0
    // ) {
    //   setError("riskFiles", {
    //     message: "위험 요인 사진을 한 장 이상 첨부해주세요",
    //   });
    // }
    data.riskFiles = selectedRiskFiles;
    // if (improved && data.improvedFiles && data.improvedFiles.length === 0) {
    //   setError("improvedFiles", {
    //     message: "개선 사항 사진을 한 장 이상 첨부해주세요",
    //   });
    // }
    data.improvedFiles = selectedImprovedFiles;
    const {
      content,
      point,
      files,
      riskIndex,
      disasterType,
      riskFactors,
      riskFiles,
      improvedFiles,
      improvedContent,
    } = data;
    setParams({
      projectId,
      reportId,
      files,
      point,
      deleteFileIds,
      deleteFilePaths,
      riskIndex,
      disasterType,
      riskFactors,
      riskFiles,
      content,
      improvedFiles,
      improvedContent,
    });
  };
  // 확인
  useEffect(() => {
    console.log("확인 params", params);
    if (reportType === REPORT_TYPE.SAFE) {
      params && editReportSafety(params);
    } else if (reportType === REPORT_TYPE.DANGER) {
      params && editReportRisk(params);
    }
  }, [params]);

  const handleEditBtn = () => {
    if (taskBasicData.state === REPORT_STATE.IMPROVED) {
      setIsImporvedEdit(true);
    } else {
      setIsEdit(true);
    }
  };
  const handleClickMedia = (
    path: string,
    title: string,
    type: "video" | "image",
  ) => {
    setMedia((prev) => ({
      ...prev,
      path,
      title,
      type,
    }));
    dispatch(setMediaModalOpen(true));
  };
  return (
    <div className="px-3 my-3 *:text-gray-600">
      <Layout
        title={`${reportType} 보고서`}
        canGoBack={true}
        hasTabBar={true}
        hasTopBar={true}
      >
        {mediaModalOpen && <MediaModal props={media} />}
        <form onSubmit={handleSubmit(onValid)}>
          <div className="w-full py-2 border border-gray-300 rounded-t-lg bg-white">
            <div className="text-left px-4">
              <Tag
                color={taskStateColor(
                  taskBasicData.state || TASK_STATE.PENDING,
                )}
                title={taskBasicData.state || TASK_STATE.PENDING}
                reason={useReportDetailData?.data.refusalReason}
              />
            </div>
            <div className="w-full py-3 flex justify-center items-center font-bold text-xl">
              <span>
                {taskBasicData.name} {reportType}보고
              </span>
            </div>
            <p className="flex justify-between font-medium text-xs text-right px-4">
              <span>위치 : {taskBasicData.location}</span>
              <span>
                일자 :
                {format(taskBasicData.date || new Date(), "yyyy년 MM월 dd일")}
              </span>
            </p>
          </div>
          <Input
            type={"div"}
            label={"동행자"}
            value={taskBasicData.coworkerName}
            className="h-14 lg:text-lg border-t-0 border-b-1"
          />
          <Input
            type={"div"}
            label={"감시유형"}
            value={taskBasicData.safetyType}
            className="h-14 lg:text-lg border-t-0 border-b-0"
          />
          <Input
            type={"div"}
            label={"작업명"}
            value={taskBasicData.majorCategory}
            className="h-14 lg:text-lg border-b-0"
          />
          <Input
            type={"div"}
            label={"단위작업명"}
            value={taskBasicData.middleCategory}
            className="h-14 lg:text-lg border-b-0"
          />
          <Input
            type={"div"}
            label={"협력업체명"}
            value={taskBasicData.subcontractorName}
            className="h-14 lg:text-lg border-b-0"
          />
          <Input
            type={"div"}
            label={"직업인원수"}
            value={taskBasicData.workerNum}
            className="h-14 lg:text-lg border-b-1"
          />
          {isEdit ? (
            <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">
              <p className="font-semibold pb-3">작업현장</p>
              <CreateFile
                name={"files"}
                register={register("files")}
                watch={watch}
                savedFiles={savedFiles}
                setDeleteFileIds={setDeleteFileIds}
                setDeleteFilePaths={setDeleteFilePaths}
                errorMessage={errors.files?.message}
                resetField={resetField}
                setValue={setValue}
                setSelectedFiles={setSelectedFiles}
              />
            </div>
          ) : (
            <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">
              <p className="font-semibold pb-3">작업 현장</p>

              <div className="w-full flex items-center justify-start h-48 rounded-md overflow-x-auto overflow-y-hidden space-x-2">
                {userReportDetailSuccess &&
                  useReportDetailData &&
                  useReportDetailData?.data &&
                  useReportDetailData?.data?.files.map(
                    (info: SavedFileInfo, index: number) => (
                      <Preview
                        key={`preview_${index}`}
                        info={info}
                        index={index}
                        onClick={() =>
                          handleClickMedia(
                            info.path,
                            `${useReportDetailData.data.name}_작업현장`,
                            info.type,
                          )
                        }
                      />
                    ),
                  )}
              </div>
            </div>
          )}
          <Input
            type={isEdit ? "text" : "div"}
            label={"세부위치"}
            placeholder={
              userReportDetailSuccess
                ? useReportDetailData.data.point
                : "로딩중"
            }
            value={
              userReportDetailSuccess
                ? useReportDetailData.data.point
                : "로딩중"
            }
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            register={register("point", {
              required: true,
            })}
          />
          {reportType === REPORT_TYPE.DANGER && (
            <>
              <div className="flex items-center h-14 border border-gray-300 border-t-0 border-b-1 px-4 bg-white">
                <span className="font-semibold">위험여부</span>
                <div className="ml-14 space-x-3">
                  <label htmlFor="danger" className="font-medium">
                    위험
                  </label>
                </div>
              </div>
              {isEdit ? (
                <>
                  <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">
                    <p className="font-semibold pb-3">위험 요인</p>
                    <CreateFile
                      name={"riskFiles"}
                      register={register("riskFiles")}
                      watch={watch}
                      savedFiles={savedRiskFiles}
                      setDeleteFileIds={setDeleteFileIds}
                      setDeleteFilePaths={setDeleteFilePaths}
                      errorMessage={errors.riskFiles?.message}
                      resetField={resetField}
                      setValue={setValue}
                      setSelectedFiles={setSelectedRiskFiles}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white lg:px-10">
                    <p className="font-semibold h-14 lg:text-lg text-gray-600">
                      위험 요인
                    </p>

                    <div className="w-full flex items-center justify-start h-48 rounded-md overflow-x-auto overflow-y-hidden space-x-2">
                      {useReportDetailData &&
                        useReportDetailData?.data &&
                        useReportDetailData?.data?.riskFiles.map(
                          (info: SavedFileInfo, index: number) => (
                            <Preview
                              key={`preview_${index}`}
                              info={info}
                              index={index}
                              onClick={() =>
                                handleClickMedia(
                                  info.path,
                                  `${useReportDetailData.data.name}_위험요인`,
                                  info.type,
                                )
                              }
                            />
                          ),
                        )}
                    </div>
                  </div>
                </>
              )}
              <Input
                type={isEdit ? "select" : "div"}
                label={"위험지수"}
                value={
                  useReportDetailData && useReportDetailData.data.riskIndex
                }
                options={[
                  RISK_INDEX.URGENT,
                  RISK_INDEX.PRIORITY,
                  RISK_INDEX.REVIEW,
                ]}
                required={true}
                className={"h-14 border-t-0 border-b-1"}
                maxLength={5}
                register={register("riskIndex", { required: true })}
              />
              <Input
                type={isEdit ? "text" : "div"}
                label={"재해유형"}
                placeholder={
                  useReportDetailData && useReportDetailData.data.disasterType
                }
                value={
                  useReportDetailData && useReportDetailData.data.disasterType
                }
                required={true}
                className={"h-14 border-t-0 border-b-1"}
                maxLength={5}
                register={register("disasterType", {
                  required: true,
                  maxLength: 5,
                })}
              />
              <Input
                type={isEdit ? "text" : "div"}
                label={"위험요인"}
                placeholder={
                  useReportDetailData && useReportDetailData.data.riskFactors
                }
                value={
                  useReportDetailData && useReportDetailData.data.riskFactors
                }
                required={true}
                maxLength={15}
                className={cls(
                  "h-14 border-t-0 border-b-1",
                  hasImproved || improved ? "" : "rounded-b-lg",
                )}
                register={register("riskFactors", {
                  required: true,
                  maxLength: 15,
                })}
              />

              {/*{isFixed && (*/}
              {/*  <>*/}
              {/*    {isEdit ? (*/}
              {/*      <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">*/}
              {/*        <p className="font-semibold pb-3">개선 사항</p>*/}
              {/*        <CreateFile*/}
              {/*          name={"improvementFiles"}*/}
              {/*          register={register("improvementFiles", {*/}
              {/*            required: true,*/}
              {/*          })}*/}
              {/*          watch={watch}*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    ) : (*/}
              {/*      <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">*/}
              {/*        <p className="font-semibold pb-3">개선사항</p>*/}

              {/*        <img*/}
              {/*          src="http://www.sosforce.com/data/file/b05/3553003223_XTgNuGiR_092af9b5a9218df0f3f6d01cd3c2ea9d3946ffc5.jpg"*/}
              {/*          alt="danger"*/}
              {/*          className="w-full aspect-video rounded"*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*    <Input*/}
              {/*      type={isEdit ? "text" : "div"}*/}
              {/*      label={"개선사항"}*/}
              {/*      placeholder={"안전망 설치"}*/}
              {/*      value={"안전망 설치"}*/}
              {/*      required={true}*/}
              {/*      maxLength={15}*/}
              {/*      className={"h-14 border-t-0 border-b-1 rounded-b-lg"}*/}
              {/*      // register={register("fixDescription", {*/}
              {/*      //   required: true,*/}
              {/*      //   maxLength: 15,*/}
              {/*      // })}*/}
              {/*    />*/}
              {/*  </>*/}
              {/*)}*/}
            </>
          )}

          {reportType === REPORT_TYPE.SAFE && (
            <>
              <div className="flex items-center h-14 border border-gray-300 border-t-0 border-b-1 px-4">
                <span className="font-semibold">위험여부</span>
                <div className="ml-14 space-x-3">
                  <label htmlFor="danger" className="font-medium">
                    안전
                  </label>
                </div>
              </div>
              <div className="py-4 px-4 h-44 border border-gray-300 border-t-0 border-b-1 rounded-b-lg">
                <p className="pb-2 font-semibold w-full">점검내용</p>
                <textarea
                  readOnly={!isEdit}
                  className="w-full grow font-medium outline-none bg-gray-100 rounded-lg resize-none p-4"
                  {...register("content", { required: true })}
                ></textarea>
              </div>
            </>
          )}

          {improved && !hasImproved && (
            <>
              <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">
                <p className="font-semibold pb-3">개선 사항</p>
                <CreateFile
                  name={"improvedFiles"}
                  register={register("improvedFiles")}
                  watch={watch}
                  setSelectedFiles={setSelectedImprovedFiles}
                />
              </div>
              <Input
                type={"text"}
                label={"개선사항"}
                placeholder={"15자 이내로 작성해주세요"}
                required={true}
                maxLength={15}
                className={"h-14 border-t-0 border-b-1 rounded-b-lg"}
                register={register("improvedContent", {
                  required: true,
                })}
              />
            </>
          )}

          {hasImproved && (
            <>
              {isImprovedEdit || isEdit ? (
                <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white">
                  <p className="font-semibold pb-3">개선 사항</p>
                  <CreateFile
                    name={"improvedFiles"}
                    register={register("improvedFiles")}
                    watch={watch}
                    savedFiles={savedImprovedFiles}
                    setDeleteFileIds={setDeleteFileIds}
                    setDeleteFilePaths={setDeleteFilePaths}
                    errorMessage={errors.improvedFiles?.message}
                    resetField={resetField}
                    setSelectedFiles={setSelectedImprovedFiles}
                  />
                </div>
              ) : (
                <div className="h-full border border-gray-300 border-t-0 border-b-1 p-4 bg-white lg:px-10">
                  <p className="font-semibold h-14 lg:text-lg text-gray-600">
                    개선 사항
                  </p>

                  <div className="w-full flex items-center justify-start h-48 rounded-md overflow-x-auto overflow-y-hidden space-x-2">
                    {useReportDetailData &&
                      useReportDetailData.data &&
                      useReportDetailData.data.improvedFiles.map(
                        (info: SavedFileInfo, index: number) => (
                          <Preview
                            key={`preview_${index}`}
                            info={info}
                            index={index}
                            onClick={() =>
                              handleClickMedia(
                                info.path,
                                `${useReportDetailData.data.name}_개선 사항`,
                                info.type,
                              )
                            }
                          />
                        ),
                      )}
                  </div>
                </div>
              )}
              <Input
                type={isImprovedEdit || isEdit ? "text" : "div"}
                label={"개선사항"}
                // placeholder={useReportDetailData?.data.improvedContent}
                value={useReportDetailData?.data.improvedContent}
                required={true}
                maxLength={15}
                className={"h-14 border-t-0 border-b-1 rounded-b-lg"}
                register={register("improvedContent", {
                  required: true,
                })}
              />
            </>
          )}

          {(state === REPORT_STATE.REJECTED ||
            state === REPORT_STATE.IMPROVED ||
            state === REPORT_STATE.PENDING) &&
          (!improved || hasImproved) ? (
            <>
              {isEdit || isImprovedEdit ? (
                <div className="flex justify-between mt-5 ">
                  {renderButton({
                    text: "삭제",
                    bgColor: "bg-red-500/30",
                    hoverBgColor: "hover:bg-red-500/20",
                    activeBgColor: "active:bg-red-500/50",
                    textColor: "text-red-700",
                    className: "!py-4 px-5 !text-lg",
                    type: "button",
                    onClick: () => setIsEdit(false),
                  })}
                  <div className="space-x-4">
                    {renderButton({
                      text: "취소",
                      bgColor: "bg-lightGray",
                      hoverBgColor: "hover:bg-lightGray/50",
                      activeBgColor: "active:bg-gray-100",
                      textColor: "text-gray-600",
                      className: "!py-4 px-5 !text-lg",
                      type: "button",
                      onClick: () => setIsEdit(false),
                    })}

                    {renderButton({
                      text: "완료",
                      bgColor: "bg-green-500/30",
                      hoverBgColor: "hover:bg-green-500/20",
                      activeBgColor: "active:bg-green-500/50",
                      textColor: "text-green-700",
                      className: "!py-4 px-5 !text-lg",
                      type: "submit",
                    })}
                  </div>
                </div>
              ) : (
                <BasicButton
                  text={
                    editReportRiskLoading || editReportSafetyLoading
                      ? "수정중"
                      : "수정"
                  }
                  size={"lg"}
                  onClick={handleEditBtn}
                  disabled={editReportRiskLoading || editReportSafetyLoading}
                />
              )}
            </>
          ) : (
            improved &&
            !hasImproved && (
              <BasicButton
                text={editReportRiskLoading ? "등록중" : "등록"}
                size={"lg"}
                type={"submit"}
                disabled={editReportRiskLoading}
              />
            )
          )}
        </form>
      </Layout>
    </div>
  );
};

export default Detail;
