import React from "react";
import Layout from "../../../component/admin/layout";
import { cls } from "../../../libs/utils";
import Tag from "../../../component/tag";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IcPower } from "../../../asset/icon/ic_power.svg";
const Main = () => {
  const navigate = useNavigate();

  const handleClickVideo = (event: React.MouseEvent, id: number) => {
    event.stopPropagation();
    navigate(`video/${id}`);
  };
  return (
    <Layout
      title={"유니슨 캠"}
      hasTopBar={true}
      hasTabBar={true}
      canGoBack={true}
    >
      <div className="space-y-3">
        <div className="adminBox flex-col">
          <div className="adminBox_title !justify-start">
            유니슨캠 실시간 스트리밍
            <span className="ml-2">
              <IcPower />
            </span>
          </div>
          <div className="md:space-y-3 space-y-2 text-center py-2 xl:text-base flex xl:grid lg:gap-3 grid-cols-2 xl:grid-cols-3 overflow-x-auto xl:overflow-x-hidden">
            <div
              className="w-full py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all mt-3"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="min-w-[340px] w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="flex justify-center items-center tracking-wider font-semibold text-lg pt-3 text-blue-500">
                양지윤
              </p>
            </div>
            <div
              className="w-full py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="min-w-[340px] w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 tracking-wider text-blue-500">
                정은호
              </p>
            </div>
            <div
              className="w-full py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="min-w-[340px] w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 tracking-wider text-blue-500">
                장성식
              </p>
            </div>
            <div
              className="w-full py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="min-w-[340px] w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 tracking-wider text-blue-500">
                신상진
              </p>
            </div>
            <div
              className="min-w-content py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="min-w-[340px] w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 tracking-wider text-blue-500">
                방찬우
              </p>
            </div>
            <div
              className="w-full py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <div className="min-w-[340px] text-xs md:text-sm lg:text-base aspect-video bg-gray-200 rounded flex justify-center items-center text-gray-600 font-medium">
                현재 스트리밍이 종료된 상태입니다
              </div>

              <p className="font-semibold text-lg pt-3 tracking-wider text-gray-700 ">
                김동훈
              </p>
            </div>
          </div>
        </div>
        <div
          className="adminBox flex-col cursor-pointer"
          onClick={() => navigate("list/양지윤")}
        >
          <div className="adminBox_title !justify-start">
            양지윤 유니슨캠 영상
          </div>
          <div className="flex w-full overflow-x-auto">
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
          </div>
        </div>
        <div
          className="adminBox flex-col cursor-pointer"
          onClick={() => navigate("list/양지윤")}
        >
          <div className="adminBox_title !justify-start">
            정은호 유니슨캠 영상
          </div>
          <div className="flex w-full overflow-x-auto">
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
          </div>
        </div>
        <div
          className="adminBox flex-col cursor-pointer"
          onClick={() => navigate("list/양지윤")}
        >
          <div className="adminBox_title !justify-start">
            장성식 유니슨캠 영상
          </div>
          <div className="flex w-full overflow-x-auto">
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
          </div>
        </div>
        <div
          className="adminBox flex-col cursor-pointer"
          onClick={() => navigate("list/양지윤")}
        >
          <div className="adminBox_title !justify-start">
            신상진 유니슨캠 영상
          </div>
          <div className="flex w-full overflow-x-auto">
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
          </div>
        </div>
        <div
          className="adminBox flex-col"
          onClick={() => navigate("list/방찬우")}
        >
          <div className="adminBox_title !justify-start">
            방찬우 유니슨캠 영상
          </div>
          <div className="flex w-full overflow-x-auto">
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
          </div>
        </div>
        <div
          className="adminBox flex-col cursor-pointer"
          onClick={() => navigate("list/양지윤")}
        >
          <div className="adminBox_title !justify-start">
            김동훈 유니슨캠 영상
          </div>
          <div className="flex w-full overflow-x-auto">
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
            <div
              className="min-w-[340px] py-4 px-6 rounded hover:bg-blue-100 cursor-pointer transition-all"
              onClick={(event) => handleClickVideo(event, 1)}
            >
              <iframe
                className="w-full aspect-video rounded"
                src="https://www.youtube.com/embed/BOAOs2vDZBI"
                title="사고사례 갱폼 인양 와이어파단"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-semibold text-lg pt-3 text-center">
                24.01.07.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Main;
