import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
  name: "task",
  initialState: {
    selectedTaskId: -1,
  },
  reducers: {
    setSelectedTaskId: (state, action) => {
      state.selectedTaskId = action.payload;
    },
  },
});

export default taskSlice.reducer;
export const { setSelectedTaskId } = taskSlice.actions;
