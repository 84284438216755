import React, { useEffect, useState } from "react";
import Layout from "../../../component/admin/layout";
import Tag from "../../../component/tag";
import { useNavigate } from "react-router-dom";
import { cls } from "../../../libs/utils";
import { useQuery } from "@tanstack/react-query";
import { CommonDataApi } from "../../../api/CommonDataApi";
import { AdminDataApi } from "../../../api/AdminDataApi";
import { differenceInDays, format } from "date-fns";
import { IProjectData } from "./register";
import { PROJECT_STATE } from "../../../libs/constants";

const List = () => {
  const navigate = useNavigate();
  const today = format(new Date(), "yyyy년 MM월 dd일");
  const [projectList, setProjectList] = useState<IProjectData[]>([]);
  const [projectCnt, setProjectCnt] = useState(0);
  const handleClickInnerBox = (
    event: React.MouseEvent,
    id: number,
    type: string,
  ) => {
    event.stopPropagation();
    navigate(`/admin/${type}/${id}`);
  };

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ["getProjectList"],
    queryFn: () => AdminDataApi.getProjectList(),
  });

  useEffect(() => {
    if (data?.data?.items) {
      console.log(data);
      setProjectList(data.data.items);
      setProjectCnt(data.data.items.length);
    }
  }, [data]);

  return (
    <Layout
      title={"프로젝트"}
      hasTabBar={true}
      canGoBack={true}
      hasTopBar={true}
    >
      <div className="mt-4 md:space-y-3 space-y-2">
        <div className="rounded-lg px-3 md:px-8 py-3 md:py-5 md:my-0 my-5 md:space-y-1 font-medium bg-white bg-gradient-to-b from-white to-sky-50/15">
          <div className="flex justify-between font-semibold text-xl md:text-3xl">
            <span>조 아무개님</span>
          </div>
          <div className="flex justify-between">
            <p className="text-sm md:text-base">
              참여 프로젝트 : {projectCnt}건 ({today} 기준)
            </p>
            <button
              className={cls(
                `rounded-lg text-xs lg:text-base hover:shadow`,
                projectCnt === 0
                  ? "bg-blue-500 animate-pulse px-3 md:px-3 py-2 text-white font-bold"
                  : "bg-lightGray px-1 md:px-3 py-1",
              )}
              onClick={() => navigate("/admin/project/register")}
            >
              프로젝트 생성
            </button>
          </div>
        </div>
        {projectList &&
          projectList.map((project, index) => {
            const startDt = format(project.startDt, "yy. MM. dd.");
            const endDt = format(project.endDt, "yy. MM. dd.");
            const remain = differenceInDays(
              new Date(project.endDt),
              new Date(project.startDt),
            );
            return (
              <div
                key={index}
                onClick={(event) =>
                  handleClickInnerBox(event, project.id || 0, "project")
                }
                className={cls(
                  "adminBox_clickable hover:shadow-lg transition-all",
                )}
              >
                <div className="text-lg md:text-xl font-semibold col-span-2 lg:border-b pb-2 flex justify-between items-center">
                  {project.name}
                  <div className="text-sm md:text-base flex item-center h-8">
                    {project.state === PROJECT_STATE.PRESTART && (
                      <Tag color={"yellow"} title={"시작전"} />
                    )}
                    {project.state === PROJECT_STATE.PROCESSING && (
                      <Tag color={"green"} title={"진행중"} />
                    )}
                    {project.state === PROJECT_STATE.STOP && (
                      <Tag color={"red"} title={"중단"} />
                    )}
                    {project.state === PROJECT_STATE.COMPLETED && (
                      <Tag color={"gray"} title={"완료"} />
                    )}
                  </div>
                </div>
                <div className="md:hidden flex justify-between text-xs">
                  <span>{project.address}</span>
                  <span>
                    {startDt} ~ {endDt}
                  </span>
                </div>

                <div className="hidden lg:flex  items-center">
                  {project.address}
                </div>
                <div className="hidden lg:flex items-center justify-end text-xs">
                  {startDt} ~ {endDt} ({remain}일 남음)
                </div>
              </div>
            );
          })}
        {/*  <div*/}
        {/*    onClick={(event) => handleClickInnerBox(event, 1, "project")}*/}
        {/*    className={cls("adminBox_clickable hover:shadow-lg transition-all")}*/}
        {/*  >*/}
        {/*    <div className="text-lg md:text-xl font-semibold col-span-2 lg:border-b pb-2 flex justify-between items-center">*/}
        {/*      인천검단2차 (AA1)*/}
        {/*      <div className="text-sm md:text-base flex item-center h-8">*/}
        {/*        <Tag color={"green"} title={"진행중"} />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="md:hidden flex justify-between text-xs">*/}
        {/*      <span>인천광역시 남동구 정각로 29</span>*/}
        {/*      <span>22. 02. 13 ~ 25. 03. 12</span>*/}
        {/*    </div>*/}

        {/*    <div className="hidden lg:flex  items-center">*/}
        {/*      인천광역시 남동구 정각로 29*/}
        {/*    </div>*/}
        {/*    <div className="hidden lg:flex items-center justify-end text-xs">*/}
        {/*      22. 02. 13 ~ 25. 03. 12 (400일 남음)*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    onClick={(event) => handleClickInnerBox(event, 1, "project")}*/}
        {/*    className={cls(*/}
        {/*      "adminBox_clickable hover:shadow-lg transition-all !bg-neutral-50",*/}
        {/*    )}*/}
        {/*  >*/}
        {/*    <div className="text-lg md:text-xl font-semibold col-span-2 lg:border-b pb-2 flex justify-between items-center">*/}
        {/*      인천검단2차 (AA1)*/}
        {/*      <div className="text-sm md:text-base flex item-center h-8">*/}
        {/*        <Tag color={"red"} title={"중단"} />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="md:hidden flex justify-between text-xs">*/}
        {/*      <span>인천광역시 남동구 정각로 29</span>*/}
        {/*      <span>22. 02. 13 ~ 25. 03. 12</span>*/}
        {/*    </div>*/}

        {/*    <div className="hidden lg:flex  items-center">*/}
        {/*      인천광역시 남동구 정각로 29*/}
        {/*    </div>*/}
        {/*    <div className="hidden lg:flex items-center justify-end text-xs">*/}
        {/*      22. 02. 13 ~ 25. 03. 12 (400일 남음)*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    onClick={(event) => handleClickInnerBox(event, 1, "project")}*/}
        {/*    className={cls(*/}
        {/*      "adminBox_clickable hover:shadow-lg transition-all !bg-neutral-50",*/}
        {/*    )}*/}
        {/*  >*/}
        {/*    <div className="text-lg md:text-xl font-semibold col-span-2 lg:border-b pb-2 flex justify-between items-center">*/}
        {/*      인천검단2차 (AA1)*/}
        {/*      <div className="text-sm md:text-base flex item-center h-8">*/}
        {/*        <Tag color={"gray"} title={"완료"} />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="md:hidden flex justify-between text-xs">*/}
        {/*      <span>인천광역시 남동구 정각로 29</span>*/}
        {/*      <span>22. 02. 13 ~ 25. 03. 12</span>*/}
        {/*    </div>*/}

        {/*    <div className="hidden lg:flex  items-center">*/}
        {/*      인천광역시 남동구 정각로 29*/}
        {/*    </div>*/}
        {/*    <div className="hidden lg:flex items-center justify-end text-xs">*/}
        {/*      18. 02. 13 ~ 21. 03. 12*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    onClick={(event) => handleClickInnerBox(event, 1, "project")}*/}
        {/*    className={cls(*/}
        {/*      "adminBox_clickable hover:shadow-lg transition-all !bg-neutral-50",*/}
        {/*    )}*/}
        {/*  >*/}
        {/*    <div className="text-lg md:text-xl font-semibold col-span-2 lg:border-b pb-2 flex justify-between items-center">*/}
        {/*      인천검단2차 (AA1)*/}
        {/*      <div className="text-sm md:text-base flex item-center h-8">*/}
        {/*        <Tag color={"gray"} title={"완료"} />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="md:hidden flex justify-between text-xs">*/}
        {/*      <span>인천광역시 남동구 정각로 29</span>*/}
        {/*      <span>22. 02. 13 ~ 25. 03. 12</span>*/}
        {/*    </div>*/}

        {/*    <div className="hidden lg:flex  items-center">*/}
        {/*      인천광역시 남동구 정각로 29*/}
        {/*    </div>*/}
        {/*    <div className="hidden lg:flex items-center justify-end text-xs">*/}
        {/*      18. 02. 13 ~ 21. 03. 12*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    onClick={(event) => handleClickInnerBox(event, 1, "project")}*/}
        {/*    className={cls(*/}
        {/*      "adminBox_clickable hover:shadow-lg transition-all !bg-neutral-50",*/}
        {/*    )}*/}
        {/*  >*/}
        {/*    <div className="text-lg md:text-xl font-semibold col-span-2 lg:border-b pb-2 flex justify-between items-center">*/}
        {/*      인천검단2차 (AA1)*/}
        {/*      <div className="text-sm md:text-base flex item-center h-8">*/}
        {/*        <Tag color={"gray"} title={"완료"} />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="md:hidden flex justify-between text-xs">*/}
        {/*      <span>인천광역시 남동구 정각로 29</span>*/}
        {/*      <span>22. 02. 13 ~ 25. 03. 12</span>*/}
        {/*    </div>*/}

        {/*    <div className="hidden lg:flex  items-center">*/}
        {/*      인천광역시 남동구 정각로 29*/}
        {/*    </div>*/}
        {/*    <div className="hidden lg:flex items-center justify-end text-xs">*/}
        {/*      18. 02. 13 ~ 21. 03. 12*/}
        {/*    </div>*/}
        {/*  </div>*/}
      </div>
    </Layout>
  );
};

export default List;
