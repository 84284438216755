import React from "react";
import Layout from "../../component/admin/layout";

const Account = () => {
  return (
    <Layout
      title={"계정 관리"}
      hasTopBar={true}
      hasTabBar={true}
      canGoBack={true}
    >
      <div>관리자 계정 관리 페이지</div>
    </Layout>
  );
};

export default Account;
