import React from "react";
import {
  EventApi,
  DateSelectArg,
  EventClickArg,
  EventContentArg,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS } from "./event-utils";
import { setSelectedDate } from "../store/features/calendar/calendarSlice";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { setModalOpen } from "../store/features/modalSlice";
import { AnimatePresence, motion } from "framer-motion";
import CalendarSwipeWrapper from "./calendar/calendarSwipWrapper";
import { AdminDataApi } from "../api/AdminDataApi";
import { differenceInMonths, format } from "date-fns";
import { padStart } from "@fullcalendar/core/internal";
import CalendarContext from "./CalendarRefContext";
import {
  getSafetyTypeClass,
  taskStateBoxBg,
  taskStateCalendarBg,
  taskStateColor,
} from "../libs/utils";
import { getCookie } from "../libs/cookies";
import { UserDataApi } from "../api/UserDataApi";
interface UnisCalendarProps {
  setSelectedDate: (date: Date) => void;
  setSelectedId: (id: number) => void;
  setViewType: (view: string) => void;
  setModalOpen: (isOpen: boolean) => void;
  setRegisterEvent: (registerEvent: { start: string; end: string }) => void;
  setCalendarRef: (ref: React.RefObject<FullCalendar>) => void;
  getTaskRefetch: () => void;
}
class UnisCalendar extends React.Component<UnisCalendarProps> {
  constructor(props: any) {
    super(props);
    this.calendarRef = React.createRef();
  }
  // this.calendarRef = React.createRef<FullCalendar>();

  componentDidMount() {
    this.props.setCalendarRef(this.calendarRef);
  }
  calendarRef = React.createRef<any>();
  navigateToNext = () => {
    if (this.calendarRef.current) {
      let calendarApi = this.calendarRef.current.getApi();
      calendarApi.next();
    }
  };

  navigateToPrev = () => {
    if (this.calendarRef.current) {
      let calendarApi = this.calendarRef.current.getApi();
      calendarApi.prev();
    }
  };

  getEvents = async (info: any, successCallback: any, failureCallback: any) => {
    this.calendarRef.current.getApi().removeAllEvents();
    console.log("info", info);
    const projectId = getCookie("projectId");
    const isAdmin = getCookie("auth") === "ROLE_ADMIN";
    const viewType = await this.calendarRef.current.getApi().view.type;
    const start = info.start;
    const end = info.end;
    const monthDifference =
      end.getMonth() -
      start.getMonth() +
      12 * (end.getFullYear() - start.getFullYear());
    // console.log(end.getMonth());
    // console.log(start.getMonth());
    console.log("monthDifference", monthDifference);
    let yearMonth;
    if (monthDifference >= 2) {
      yearMonth = format(
        new Date(start.getFullYear(), start.getMonth() + 2, 1)
          .toISOString()
          .slice(0, 7),
        "yyyy-MM-dd",
      );
      console.log("yearMonth", yearMonth);
    } else {
      yearMonth = format(start.toISOString().slice(0, 7), "yyyy-MM-dd");
    }
    const params = {
      startDate: info.startStr.slice(0, 10),
      endDate: info.endStr.slice(0, 10),
    };

    let response;
    try {
      if (isAdmin) {
        switch (viewType) {
          case "dayGridMonth":
            response = await AdminDataApi.getAdminTaskMonth(
              projectId,
              yearMonth,
            );
            break;
          // case "timeGridWeek":
          //   response = await AdminDataApi.getAdminTaskWeek(projectId, params);
          //   break;
          // case "timeGridDay":
          //   response = await AdminDataApi.getAdminTaskDay(projectId, yearMonth);
          //   break;
          default:
            throw new Error("Unsupported view type");
        }
      } else {
        switch (viewType) {
          case "dayGridMonth":
            response = await UserDataApi.getTaskMonth(projectId, yearMonth);
            break;
          // case "timeGridWeek":
          //   response = await UserDataApi.getTaskWeek(projectId, params);
          //   break;
          // case "timeGridDay":
          //   response = await UserDataApi.getTaskDay(projectId, yearMonth);
          //   break;
          default:
            throw new Error("Unsupported view type");
        }
      }

      // try {
      //   let response;
      //   if (viewType === "dayGridMonth") {
      //     response = await AdminDataApi.getAdminTaskMonth(projectId, yearMonth);
      //   } else if (viewType === "timeGridWeek") {
      //     console.log("timeGridWeek params", params);
      //     response = await AdminDataApi.getAdminTaskWeek(projectId, params);
      //   } else {
      //     response = await AdminDataApi.getAdminTaskDay(projectId, yearMonth);
      //   }
      //   console.log(response);

      const events = response.data.items.flatMap((item: any) =>
        item.tasks.map((task: any) => ({
          id: task.taskId,
          title: task.safetyType,
          start: item.date,
          end: item.date,
          className: taskStateCalendarBg(task.state),
        })),
      );
      this.calendarRef.current.getApi().removeAllEvents();
      successCallback(events);
    } catch (error) {
      console.error("Error fetching events:", error);
      failureCallback(error);
    }
  };

  handleDatesSet = async (info: any) => {
    // console.log("handleDatesSet info", info);
    const projectId = getCookie("projectId");
    const isAdmin = getCookie("auth") === "ROLE_ADMIN";
    const viewType =
      (await this.calendarRef) &&
      (await this.calendarRef.current) &&
      (await this.calendarRef.current.getApi().view.type);
    const start = info.start;
    const end = info.end;
    const monthDifference =
      end.getMonth() -
      start.getMonth() +
      12 * (end.getFullYear() - start.getFullYear());
    // console.log(end.getMonth());
    // console.log(start.getMonth());
    let yearMonth;
    if (monthDifference >= 2) {
      yearMonth = format(
        new Date(start.getFullYear(), start.getMonth() + 2, 1)
          .toISOString()
          .slice(0, 7),
        "yyyy-MM-dd",
      );
      console.log("yearMonth", yearMonth);
    } else {
      yearMonth = format(start.toISOString().slice(0, 7), "yyyy-MM-dd");
    }

    const params = {
      startDate: info.startStr.slice(0, 10),
      endDate: info.endStr.slice(0, 10),
    };
    try {
      let response;
      // if (viewType === "dayGridMonth") {
      //   response = await AdminDataApi.getAdminTaskMonth(projectId, yearMonth);
      // } else if (viewType === "timeGridWeek") {
      //   response = await AdminDataApi.getAdminTaskWeek(projectId, params);
      // } else {
      //   response = await AdminDataApi.getAdminTaskDay(
      //     projectId,
      //     info.startStr.slice(0, 10),
      //   );
      // }

      if (isAdmin) {
        switch (viewType) {
          case "dayGridMonth":
            response = await AdminDataApi.getAdminTaskMonth(
              projectId,
              yearMonth,
            );
            break;
          case "timeGridWeek":
            console.log("timeGridWeek params", params);
            response = await AdminDataApi.getAdminTaskWeek(projectId, params);
            break;
          case "timeGridDay":
            response = await AdminDataApi.getAdminTaskDay(
              projectId,
              info.startStr.slice(0, 10),
            );
            break;
          default:
            throw new Error("Unsupported view type");
        }
      } else {
        switch (viewType) {
          case "dayGridMonth":
            response = await UserDataApi.getTaskMonth(projectId, yearMonth);
            break;
          case "timeGridWeek":
            response = await UserDataApi.getTaskWeek(projectId, params);
            break;
          case "timeGridDay":
            response = await UserDataApi.getTaskDay(
              projectId,
              info.startStr.slice(0, 10),
            );
            break;
          default:
            throw new Error("Unsupported view type");
        }
      }

      console.log("response", response);

      let events;
      if (viewType === "timeGridDay") {
        events = response.data.items.map((task: any) => ({
          id: task.taskId,
          title: task.taskName,
          start: `${info.startStr.slice(0, 10)}T${task.startTime}`,
          end: `${info.startStr.slice(0, 10)}T${task.endTime}`,
          // className: `bg-${taskStateColor(task.state)}-300 border-none`,
          className: taskStateCalendarBg(task.state),
        }));
      } else {
        events = response.data.items.flatMap((item: any) =>
          item.tasks.map((task: any) => ({
            id: task.taskId,
            title: task.taskName,
            start:
              viewType === "dayGridMonth"
                ? item.date
                : `${item.date}T${task.startTime}`,
            end:
              viewType === "dayGridMonth"
                ? item.date
                : `${item.date}T${task.endTime}`,
            // className: `bg-${taskStateColor(task.state)}-300 border-none`,
            className: taskStateCalendarBg(task.state),
          })),
        );
      }

      this.calendarRef.current.getApi().removeAllEvents();
      this.calendarRef.current.getApi().addEventSource(events);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  handleChangeEvents = async (changeInfo: any) => {
    const { event } = changeInfo;
    const isAdmin = getCookie("auth") === "ROLE_ADMIN";
    console.log(new Date(event.endStr).getMinutes());
    const id = +event.id;
    const date = event.startStr.slice(0, 10);
    const startTime = `${String(new Date(event.startStr).getHours()).padStart(2, "0")}:${String(new Date(event.startStr).getMinutes()).padStart(2, "0")}:00`;
    const endTime = `${String(new Date(event.endStr).getHours()).padStart(2, "0")}:${String(new Date(event.endStr).getMinutes()).padStart(2, "0")}:00`;
    const params = {
      id,
      date,
      startTime,
      endTime,
    };
    console.log(params);
    const response = isAdmin
      ? await AdminDataApi.updateTaskDateTime(params)
      : await UserDataApi.updateTaskDateTime(params);
    console.log("handleChangeEvents", response);
    this.props.getTaskRefetch();
  };

  render() {
    return (
      // <CalendarSwipeWrapper
      //   onSwipeLeft={this.navigateToNext}
      //   onSwipeRight={this.navigateToPrev}
      // >
      <FullCalendar
        ref={this.calendarRef}
        longPressDelay={100}
        eventLongPressDelay={100}
        selectLongPressDelay={100}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        datesSet={this.handleDatesSet}
        buttonText={{
          dayGridMonth: "월",
          timeGridWeek: "주",
          timeGridDay: "일",
        }}
        headerToolbar={{
          left: "prev,next,today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        dayCellContent={(info) => {
          var number = document.createElement("a");
          number.classList.add("fc-daygrid-day-number");
          number.innerHTML = info.dayNumberText.replace("일", "");
          return {
            html: number.outerHTML,
          };
        }}
        locale={"kor"}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        viewDidMount={(veiw) => this.props.setViewType(veiw.view.type)}
        allDaySlot={false}
        titleFormat={{ year: "numeric", month: "short" }}
        views={{
          day: {
            titleFormat: {
              year: "numeric",
              month: "short",
              day: "numeric",
            },
          },
        }}
        contentHeight={"auto"}
        stickyHeaderDates={true}
        // dayMaxEvents={true}
        // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
        // select={this.handleDateSelect}
        events={this.getEvents}
        select={this.handleDateSelect}
        eventContent={renderEventContent} // custom render function
        eventClick={this.handleEventClick}
        eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
        /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
        eventChange={this.handleChangeEvents}
      />
      // </CalendarSwipeWrapper>
    );
  }

  handleDateSelect = (selectInfo: DateSelectArg) => {
    this.props.setSelectedDate(selectInfo.start);
    if (
      selectInfo.view.type === "timeGridWeek" ||
      selectInfo.view.type === "timeGridDay"
    ) {
      this.props.setRegisterEvent({
        start: selectInfo.startStr,
        end: selectInfo.endStr,
      });
    }

    // 일정 등록
    // let title = prompt("Please enter a new title for your event");
    // let calendarApi = selectInfo.view.calendar;
    //
    // calendarApi.unselect(); // clear date selection
    //
    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay,
    //   });
    // }
  };

  handleEventClick = (clickInfo: EventClickArg) => {
    this.props.setSelectedId(+clickInfo.event.id);
    this.props.setModalOpen(true);
  };

  handleEvents = (events: EventApi[]) => {
    this.setState({
      currentEvents: events,
    });
  };
}

function renderEventContent(eventContent: EventContentArg) {
  if (eventContent.view.type === "dayGridMonth") {
    return (
      <div className="h-1 w-1 lg:w-2 lg:h-2 rounded-full !hover:bg-transparent"></div>
    );
  } else {
    return (
      <>
        <p className="hidden lg:block font-semibold text-xs md:text-base">
          {eventContent.timeText}
        </p>
        <p className="font-semibold text-xs md:text-base">
          {eventContent.event.title}
        </p>
      </>
    );
  }
}
const mapDispatchToProps = {
  setModalOpen,
};
export default connect(null, mapDispatchToProps)(UnisCalendar);
