import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { cls } from "../libs/utils";

interface InputFieldProps {
  type: string;
  value?: string;
  placeholder?: string;
  label?: string;
  register?: UseFormRegisterReturn<any>;
  required?: boolean;
  icon?: React.ReactElement;
  className?: string;
  errorMessage?: string;
  maxLength?: number;
  options?: string[];
  isLong?: boolean;
}

const Input: React.FC<InputFieldProps> = ({
  type,
  value,
  placeholder,
  label,
  register,
  required,
  icon,
  className,
  errorMessage,
  maxLength,
  options,
  isLong = true,

  ...rest
}) => {
  return (
    <div
      className={cls(
        "relative flex items-center w-full border border-gray-300 bg-white",
        className ? className : "",
      )}
    >
      {icon && (
        <span className="absolute inset-y-0 left-0 flex items-center pl-4">
          {icon}
        </span>
      )}
      {label && (
        <span className="text-gray-600 max-w-24 lg:max-w-full absolute inset-y-0 left-0 flex items-center lg:pl-10 pl-4 whitespace-pre-line font-semibold">
          {label}
        </span>
      )}
      {type === "div" ? (
        <div
          className={cls(
            "inner_input_res",
            icon ? "pl-14" : "",
            label ? "ml-32 lg:ml-56" : "",
            className ? className : "",
          )}
        >
          {value}
        </div>
      ) : type === "select" ? (
        <select
          {...register}
          className={cls(
            "outline-none bg-white",
            icon ? "ml-14" : "",
            label ? "ml-32 lg:ml-56" : "",
          )}
          defaultValue={value}
        >
          {options &&
            options.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
        </select>
      ) : type === "file" ? (
        <div>
          <input
            className={cls(
              "text-gray-600 file:mr-5 file:py-1 file:px-3 file:rounded-lg file:border-0 file:font-medium file:bg-blue-500 file:text-white hover:file:cursor-pointer hover:file:bg-blue-400",
              errorMessage ? "inner_input_error" : "inner_input",
              icon ? "lg:px-20 px-14" : "",
              label ? "lg:px-56 px-32" : "",
              className ? className : "",
            )}
            type="file"
            required={required}
            {...register}
          />
        </div>
      ) : (
        <input
          className={cls(
            errorMessage ? "inner_input_error" : "inner_input",
            // icon ? "lg:px-20 px-14" : "",
            // label ? "lg:px-56 px-32" : "",

            icon && isLong ? "lg:pl-20 pl-14 pr-2" : "",
            icon && !isLong ? "lg:px-20 px-14" : "",
            label && isLong ? "lg:pl-56 pl-32 pr-2" : "",
            label && !isLong ? "lg:px-56 px-32" : "",
            !label && !icon ? "px-3" : "",
            className ? className : "",
          )}
          type={type}
          maxLength={maxLength}
          placeholder={placeholder}
          required={required}
          {...register}
        />
      )}
      {errorMessage && <span className="error">{errorMessage}</span>}
    </div>
  );
};

export default Input;
