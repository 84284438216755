import React, { useEffect, useState } from "react";
import Layout from "../../component/layout";
import { ReactComponent as IcUser } from "../../asset/icon/ic_user.svg";
import { ReactComponent as IcPassword } from "../../asset/icon/ic_password.svg";
import { useForm } from "react-hook-form";
import BasicButton from "../../component/basicButton";
import { cls, formatBirthDate } from "../../libs/utils";
import Input from "../../component/input";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CommonDataApi } from "../../api/CommonDataApi";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns/format";

export interface IJoinData {
  name: string;
  userId: string;
  birth: string;
  address: string;
  addressDetail: string;
  phone: string;
  nationality: string;
  project: string;
  bloodType: string;
  id: string;
  password: string;
  password_confirm: string;
  code: string;
  certiImage: FileList;
  locationService: string;
}

const Join = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    clearErrors,
  } = useForm<IJoinData>();
  const [checkId, setCheckId] = useState("");
  const mutation = useMutation<string, string, IJoinData>({
    mutationFn: (param: IJoinData) => CommonDataApi.signUp(param),
    onSuccess: (data) => {
      if (data === "ok") {
        navigate("/");
      } else {
        return;
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const watchForm = watch();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const allFieldsFilled =
      Object.entries(errors).length === 0 &&
      Object.entries(watchForm).length > 0 &&
      Object.values(watchForm).every((field) => field) &&
      watchForm.certiImage.length > 0;

    setIsButtonDisabled(!allFieldsFilled);
  }, [watchForm]);

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ["checkDuplicated", checkId],
    queryFn: () => CommonDataApi.checkDuplicated(checkId),
    enabled: checkId.length > 0,
  });

  useEffect(() => {
    if (isLoading) return;

    if (data?.data?.isDuplicated) {
      setError("userId", { message: "이미 존재하는 아이디입니다" });
    } else {
      clearErrors("userId");
    }
  }, [data, setError, clearErrors, isLoading]);

  const handleIdBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      const userId = event.target.value;
      setCheckId(userId);
    }
  };

  const onValid = (data: IJoinData) => {
    setCheckId(data.userId);
    if (data.password !== data.password_confirm) {
      setError("password_confirm", { message: "비밀번호가 일치하지 않습니다" });
      return;
    }
    if (mutation.isPending) setIsButtonDisabled(true);
    if (data.bloodType === "혈액형")
      setError("bloodType", { message: "혈액형을 올바르게 선택해주세요." });
    data.birth = formatBirthDate(data.birth);
    const res = mutation.mutate(data);
  };

  return (
    <Layout title={"회원가입"} hasTabBar={false} canGoBack={true}>
      <form onSubmit={handleSubmit(onValid)} className="pb-10">
        <div className="flex flex-col justify-center items-center mt-5">
          <h3 className="text-5xl font-extrabold ml-3 text-blue-600">UNIS.</h3>
          <h3 className="ml-3 text-black">실시간 위험 정보 공유 플랫폼</h3>
        </div>
        <div className="px-3 mt-8">
          <Input
            isLong={false}
            icon={<IcUser />}
            type={"text"}
            placeholder={"이름"}
            required={true}
            className={"h-14 rounded-t-lg"}
            register={register("name", { required: true })}
          />
          <Input
            isLong={false}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-gray-300"
              >
                <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                <path
                  fillRule="evenodd"
                  d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            }
            type={"text"}
            placeholder={"생년월일 8자리 숫자"}
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            register={register("birth", {
              required: true,
              pattern: {
                value: /^\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/,
                message: "생년월일을 올바르게 입력해주세요",
              },
              maxLength: 8,
            })}
            errorMessage={errors?.birth?.message}
            maxLength={8}
          />
          <Input
            isLong={false}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-gray-300"
              >
                <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
              </svg>
            }
            type={"text"}
            placeholder={"주소"}
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            register={register("address", { required: true })}
          />
          <Input
            isLong={false}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-gray-300"
              >
                <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
              </svg>
            }
            type={"text"}
            placeholder={"상세주소"}
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            register={register("addressDetail", { required: true })}
          />
          <Input
            isLong={false}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-gray-300"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                  clipRule="evenodd"
                />
              </svg>
            }
            type={"text"}
            placeholder={"핸드폰 번호"}
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            register={register("phone", {
              required: true,
              pattern: {
                value: /^01([0|1|6|7|8|9])(\d{3,4})(\d{4})$/,
                message: "핸드폰 번호를 올바르게 입력해주세요",
              },
              maxLength: 11,
            })}
            errorMessage={errors?.phone?.message}
            maxLength={11}
          />
          <Input
            isLong={false}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-gray-300"
              >
                <path d="M15.75 8.25a.75.75 0 0 1 .75.75c0 1.12-.492 2.126-1.27 2.812a.75.75 0 1 1-.992-1.124A2.243 2.243 0 0 0 15 9a.75.75 0 0 1 .75-.75Z" />
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM4.575 15.6a8.25 8.25 0 0 0 9.348 4.425 1.966 1.966 0 0 0-1.84-1.275.983.983 0 0 1-.97-.822l-.073-.437c-.094-.565.25-1.11.8-1.267l.99-.282c.427-.123.783-.418.982-.816l.036-.073a1.453 1.453 0 0 1 2.328-.377L16.5 15h.628a2.25 2.25 0 0 1 1.983 1.186 8.25 8.25 0 0 0-6.345-12.4c.044.262.18.503.389.676l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.575 15.6Z"
                  clipRule="evenodd"
                />
              </svg>
            }
            type={"text"}
            placeholder={"국적"}
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            register={register("nationality", { required: true })}
          />

          <div className="relative flex items-center w-full h-14 border border-gray-300 border-t-0 focus-within:ring-inset focus-within:ring-rose-500">
            <span className="absolute inset-y-0 left-0 flex items-center pl-4 bg-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-8 h-6 text-gray-300"
              >
                <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
              </svg>
            </span>
            <select
              className={cls(
                "ml-12",
                errors.bloodType ? "inner_select_error" : "inner_select",
              )}
              required={true}
              {...register("bloodType", { required: true })}
            >
              <option className="flex justify-start">혈액형</option>
              <option className="flex justify-start" value={"A"}>
                A
              </option>
              <option className="flex justify-start" value={"B"}>
                B
              </option>
              <option className="flex justify-start" value={"AB"}>
                AB
              </option>
              <option className="flex justify-start" value={"O"}>
                O
              </option>
            </select>
            {errors?.bloodType && (
              <span className="flex items-center absolute inset-y-0 right-0 text-rose-500 pr-3">
                {errors?.bloodType?.message}
              </span>
            )}
          </div>
          <Input
            isLong={false}
            icon={<IcUser />}
            type={"text"}
            placeholder={"아이디"}
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            register={register("userId", {
              required: true,
              onBlur: handleIdBlur,
            })}
            errorMessage={errors?.userId?.message}
          />
          <Input
            isLong={false}
            icon={<IcPassword />}
            type={"text"}
            placeholder={"비밀번호"}
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            register={register("password", { required: true })}
          />
          <Input
            isLong={false}
            icon={<IcPassword />}
            type={"text"}
            placeholder={"비밀번호 확인"}
            required={true}
            className={"h-14 border-t-0 border-b-1"}
            register={register("password_confirm", { required: true })}
          />

          <Input
            isLong={false}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-gray-300"
              >
                <path
                  fillRule="evenodd"
                  d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                  clipRule="evenodd"
                />
                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
              </svg>
            }
            type={"file"}
            placeholder={"교육 이수증"}
            required={true}
            className={"h-14 border-t-0 rounded-b-lg"}
            register={register("certiImage", { required: true })}
          />
          <div className="pl-2 py-4 text-gray-500 flex items-center">
            위치정보 서비스 동의
            <input
              {...register("locationService", { required: true })}
              className="peer/locationService text-blue-500 ml-3"
              type="checkbox"
              name="locationService"
              value={"true"}
            />
          </div>
          <BasicButton
            text={"회원가입"}
            disabled={isButtonDisabled}
            size={"lg"}
            type={"submit"}
          />
        </div>
      </form>
    </Layout>
  );
};

export default Join;
